
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';



const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P75-E1',
    audio: '',
    video: '',
    component: T6,
    inputSize: 200,
    // titleImage: "img/FriendsPlus/Page68/E6/1.png",
    exerciseKey: 'img/FriendsPlus/Page75/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Complete the examples from the text on page 74. Then choose the correct words in the rules.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p><span style='font-weight:600'>1.</span> # two new events in Rio in 2016.</p>" +
          "<p><span style='font-weight:600'>2.</span> # an Olympic flag until 1920.</p></div>",

        answer: [
          "there were",
          "there wasn't"
        ],
        type: 'longAnwser'
      },
    ]
  },
  '1-1': {
    unit: 'Unit 6',
    id: 'SB6-U6-P75-E1.1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page75/E1/Key/answerKey.png',
    titleQuestion: [{ num: '', title: "Choose the correct words in the rules." }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page75/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page75/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page75/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page75/E1/5.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page75/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E1/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page75/E1/9.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page75/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page75/E1/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page75/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E1/13.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P75-E2',
    audio: '',
    video: '',
    component: D1,
    fontSize: 17,
    exerciseKey: 'img/FriendsPlus/Page75/E2/Key/answerKey.png',
    //titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    // typeInput: 'center',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page75/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/3.jpg', input: true, answer: "there was" },
        { url: 'img/FriendsPlus/Page75/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/7.jpg', input: true, answer: "there were" },
        { url: 'img/FriendsPlus/Page75/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/11.jpg', input: true, answer: "There was" },
        { url: 'img/FriendsPlus/Page75/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/15.jpg', input: true, answer: "there wasn't" },
        { url: 'img/FriendsPlus/Page75/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/19.jpg', input: true, answer: "there weren't" },
        { url: 'img/FriendsPlus/Page75/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page75/E2/23.jpg', input: true, answer: "There weren't" },
        { url: 'img/FriendsPlus/Page75/E2/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E2/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P75-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page75/E3/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page68/E6/1.png",
    titleQuestion: [{ num: '3', title: 'Complete the sentences with affirmative and negative forms of there <i>was</i> and there <i>were</i>. Use <i>many,any</i> or <i>a/an</i> .' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-family:OneStrokeScriptStd-Bold;font-weight:600'>2,500 years ago ...  </p>" +
          "<p><span style='font-weight:600'>1.</span> # rich footballers.</p>" +
          "<p><span style='font-weight:600'>2.</span> # chariot races.</p>" +
          "<p><span style='font-weight:600'>3.</span> # country called Persia.</p>" +
          "<p><span style='font-weight:600'>4.</span> # sport called skateboarding.</p>" +
          "<p><span style='font-weight:600'>5.</span> # messengers.</p>" +
          "<p><span style='font-weight:600'>6.</span> #  iPads.</p>",

        answer: [
          "There weren't any",
          'There were many',
          'There was a',
          "There wasn't a",
          "There were many",
          "There weren't any"
        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P75-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page75/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P75-E5',
    audio: '',
    video: 'Videos/Animation - Unit 6 grammar 1 trang 75.mp4',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page75/E5/Key/answerKey.png',
    //titleImage: 'http://',
    // fontSize: 100,
    titleQuestion: [{ num: '5', title: 'Complete the sentences about the Olympics timeline text on page 74.<br/> Then complete the rule.' }],
    // typeInput: 'center',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page75/E5/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page75/E5/3.jpg', input: true, answer: "were" },
        { url: 'img/FriendsPlus/Page75/E5/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page75/E5/7.jpg', input: true, answer: "was" },
        { url: 'img/FriendsPlus/Page75/E5/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page75/E5/11.jpg', input: true, answer: "was" },
        { url: 'img/FriendsPlus/Page75/E5/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/14.jpg' },
        { url: 'img/FriendsPlus/Page75/E5/15.jpg', input: true, answer: "wasn't" },
        { url: 'img/FriendsPlus/Page75/E5/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/18.jpg' },
        { url: 'img/FriendsPlus/Page75/E5/19.jpg', input: true, answer: "weren't" },
        { url: 'img/FriendsPlus/Page75/E5/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/22.jpg' },
        { url: 'img/FriendsPlus/Page75/E5/23.jpg', input: true, answer: "is and are" },
        { url: 'img/FriendsPlus/Page75/E5/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/26.jpg' },
        { url: 'img/FriendsPlus/Page75/E5/27.jpg', input: true, answer: "wasn't" },
        { url: 'img/FriendsPlus/Page75/E5/28.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/30.jpg' },
        { url: 'img/FriendsPlus/Page75/E5/31.jpg', input: true, answer: "weren't" },
        { url: 'img/FriendsPlus/Page75/E5/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page75/E5/33.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P75-E6',
    audio: '',
    video: '',
    component: T6,
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page68/E6/1.png",
    titleQuestion: [{ num: '6', title: 'Complete the sentences with <i>was</i> and <i>were</i> and your own ideas.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>I ...... born in ...... <span style='font-family:OneStrokeScriptStd-Bold;font-weight:600'>I was born in Ho Chi Minh City.</span></p>" +
          "<p><span style='font-weight:600'>1.</span> When I # younger, my favourite sport</p>" +
          "<p> # </p>" +
          "<p><span style='font-weight:600'>2.</span> The number one song last week # </p>" +
          "<p><span style='font-weight:600'>3.</span> My first words # </p>" +
          "<p><span style='font-weight:600'>4.</span> My favourite films last year # </p>" +
          "<p><span style='font-weight:600'>5.</span> My favourite day last week # </p>" +
          "<p><span style='font-weight:600'>6.</span> When I #  younger, my hobbies # </p>" +
          "<p><span style='font-weight:600'>7.</span> My last meal # </p>",
        answer: [
          // '', '', '', '', '', '', '', '', '', '', ''
        ],
        type: 'longAnwser'
      },
    ]
  },
  7: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P75-E7',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page75/E7/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;