
import S8 from '../../components/ExcerciseTypes/Select/Select8';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {

  1: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P104-E1',
    audio: '',
    video: '',
    component: S8,
    exerciseKey: 'img/FriendsPlus/Page104/E1/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '1', title: 'Check the meanings of the words in the box. Then look at photos 1–4. What can you see in the photos?', color: '#00AFBE' }],
    image: 'img/FriendsPlus/Page104/E1/1.jpg',
    widthImg: '70%',
    width: 200,

    questions: [
      {
        no: "1",
        text: "cosplay",
      },
      {
        no: "2",
        text: "water ride",
      },
      {
        no: "3",
        text: "rollercoaster",
      },
      {
        no: "4",
        text: "thrill ride",
      },

    ]
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P104-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page104/E2/Key/Key.png',
    titleImage: "img/FriendsPlus/Page104/E2/1.png",
    imgSize: '100%',
    titleQuestion: [{ num: '2', title: 'What places can you find in a theme park? Read the text and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> #. </p>" +
          "<p><span style='font-weight:600'>2</span> #. </p>" +
          "<p><span style='font-weight:600'>3</span> #. </p>" +
          "<p><span style='font-weight:600'>4</span> #. </p>" +
          "<p><span style='font-weight:600'>5</span> #. </p>" +
          "<p><span style='font-weight:600'>6</span> # </p>" +
          "<p><span style='font-weight:600'>7</span> #. </p>",
        answer: [
          'rollercoasters/rides/shops/cafés/cafes/restaurants/shows/concerts',
          'rollercoasters/rides/shops/cafés/cafes/restaurants/shows/concerts',
          'rollercoasters/rides/shops/cafés/cafes/restaurants/shows/concerts',
          'rollercoasters/rides/shops/cafés/cafes/restaurants/shows/concerts',
          'rollercoasters/rides/shops/cafés/cafes/restaurants/shows/concerts',
          'rollercoasters/rides/shops/cafés/cafes/restaurants/shows/concerts',
          'rollercoasters/rides/shops/cafés/cafes/restaurants/shows/concerts',
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P104-E3',
    audio: 'img/FriendsPlus/Page104/E3/Audio/G6 SB Track 2.39.mp3',
    video: '',
    component: T1,
    // titleImage: 'img/FriendsPlus/Page114/E4/1.jpg',
    imgSize: '60%',
    exerciseKey: 'img/FriendsPlus/Page104/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read and listen to the text and answer the questions.' }],
    questionImage: [
    ],
    questions: [
      {
        title:
          '<p><span style="font-weight:600">1</span> How many theme parks are there in Japan? #.  </p>' +
          '<p><span style="font-weight:600">2</span> When did Tokyo Disneyland open? #. </p>' +
          '<p><span style="font-weight:600">3</span> Are theme park rides dangerous? #. </p>' +
          '<p><span style="font-weight:600">4</span> What kind of ride can be scary? #. </p>' +
          '<p><span style="font-weight:600">5</span> What can you do at Edo Wonderland? #. </p>' +
          '<p><span style="font-weight:600">6</span> What other things can you do at theme parks?  #. </p>',
        answer: [
          "More than 100",
          "1983",
          "No, they are very safe",
          'A thrill ride',
          'You can dress up in historical costumes and visit attractions',
          'You can go shopping, have a drink or a meal',
        ],
        type: 'longAnwser'

      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P104-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page104/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P104-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page104/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;