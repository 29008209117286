import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import UI from '../../components/ExcerciseTypes/UseIt';
import VideoType from '../../components/ExcerciseTypes/Video'


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P20-E1',
    audio: 'img/FriendsPlus/Page20/Audio/Friends Plus for Vietnam G6 SB Track 1.18.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P20E1_2.mp4',
    videoSub: 'Videos/P20E1_2.vtt',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page20/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Complete the dialogue with the phrases in the box. Then watch or listen and check. Are Chris and the woman from the same town?', color: '#D91D72' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/4.jpg', input: true, answer: "Are we near" },
        { url: 'img/FriendsPlus/Page20/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/8.jpg', input: true, answer: "the bus station" },
        { url: 'img/FriendsPlus/Page20/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/12.jpg', input: true, answer: "any shops" },
        { url: 'img/FriendsPlus/Page20/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/16.jpg', input: true, answer: "five minutes" },
        { url: 'img/FriendsPlus/Page20/E1/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E1/18.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P20-E2',
    audio: 'img/FriendsPlus/Page20/Audio/Friends Plus for Vietnam G6 SB Track 1.18.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P20E1_2.mp4',
    videoSub: 'Videos/P20E1_2.vtt',
    component: VideoType,
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Watch or listen again. Then practise the dialogue in pairs.', color: '#D91D72' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P20-E3',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/3.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E3/13.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P20-E4',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E4/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E4/3.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E4/4.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P20-E5',
    audio: '',
    video: '',
    component: UI,
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E5/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E5/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page20/E5/3.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;