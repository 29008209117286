import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import U1 from '../../components/ExcerciseTypes/UnderLine/U1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';


const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P102-E1',
    audio: 'img/FriendsPlus/Page102/Audio/Friends Plus for Vietnam G6 SB Track 2.37.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P102E1_2.mp4',
    videoSub: 'Videos/P102E1_2.vtt',
    component: D1,
    fontSize: 24,
    height: 26,
    exerciseKey: 'img/FriendsPlus/Page102/E1/Key/1.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Read the skills strategy. Then watch or listen and complete the dialogue with the words in the box. Who are they going to invite?', color: '#d71b70' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page102/E1/3.jpg', input: true, answer: 'hot and sunny' },
        { url: 'img/FriendsPlus/Page102/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page102/E1/7.jpg', input: true, answer: 'a picnic' },
        { url: 'img/FriendsPlus/Page102/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page102/E1/11.jpg', input: true, answer: 'drinks ' },
        { url: 'img/FriendsPlus/Page102/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page102/E1/15.jpg', input: true, answer: 'my house' },
        { url: 'img/FriendsPlus/Page102/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E1/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P102-E2',
    audio: 'img/FriendsPlus/Page102/E2/Audio/G6 SB Track 2.37.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P102E1_2.mp4',
    videoSub: 'Videos/P102E1_2.vtt',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page102/E2/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '2', title: 'Complete the Key Phrases with words in the description.', color: '#0caa50' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/3.jpg', input: true, answer: 'O' },
        { url: 'img/FriendsPlus/Page102/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/7.jpg', input: true, answer: 'O' },
        { url: 'img/FriendsPlus/Page102/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/11.jpg', input: true, answer: 'P' },
        { url: 'img/FriendsPlus/Page102/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/15.jpg', input: true, answer: 'P' },
        { url: 'img/FriendsPlus/Page102/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/19.jpg', input: true, answer: 'O' },
        { url: 'img/FriendsPlus/Page102/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/23.jpg', input: true, answer: 'P' },
        { url: 'img/FriendsPlus/Page102/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page102/E2/29.jpg', input: true, answer: 'P' },
        { url: 'img/FriendsPlus/Page102/E2/30.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page102/E2/31.jpg' },

      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P102-E3',
    audio: 'img/FriendsPlus/Page102/E3/Audio/G6 SB Track 2.38.mp3',
    video: '',
    component: U1,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page102/E3/Key/1.png',
    titleQuestion: [{ num: '3', title: 'Choose the correct responses in the mini-dialogues. Listen and check.', color: '#BE215B' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1 A</span> It’ll be cold and wet on Saturday. Let’s stay at home.</p>" +
          "<p><span style='font-weight:600;margin-left:20px'>B</span> Good idea! # / #</p>" +
          "<p><span style='font-weight:600'>2 A</span> It’ll be rainy tomorrow. Why don’t you come to my house in the afternoon?</p>" +
          "<p><span style='font-weight:600;margin-left:20px'>B</span> Great!  # / #</p>" +
          "<p><span style='font-weight:600'>3 A</span> It’ll be hot and sunny tomorrow afternoon. Let’s have a barbecue.</p>" +
          "<p><span style='font-weight:600;margin-left:20px'>B</span> Cool! # / #</p>" +
          "<p><span style='font-weight:600'>4 A</span> Let’s meet outside the sports centre at 8 p.m. Don’t be late!</p>" +
          "<p><span style='font-weight:600;margin-left:20px'>B</span> OK. I  # / #  be late ... I promise.</p>",
        answerString: [
          "I'll ask Naomi to come too.",
          "I promise.",
          "I'll be there at three.",
          "I won't.",
          'I can bring some chicken.',
          'I can play football.',
          "'ll", "won't"
        ],
        answer: [
          "I'll ask Naomi to come too.",
          "I'll be there at three.",
          "I can bring some chicken.",
          "won't"
        ],
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P102-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page102/E4/title.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json