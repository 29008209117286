import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import S1 from '../../components/ExcerciseTypes/Select/Select1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';


const json = {
  1: { // Exercise num
    
    unit : 'Option 4',
    id : 'SB6-OPE4-P113-E1',
    audio: '',
    video: '',
    component: S1,
    width: 200,
    exerciseKey: 'img/FriendsPlus/Page113/E1/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page113/E1/title.jpg',
    // titleQuestion: [{ num: '1', title: 'Match the words in the box with pictures 1-15. Then listen and check.' }],
    questions: [
      {
        no: "1",
        text: "whiteboard",
        image: "img/FriendsPlus/Page113/E1/05.jpg",
      },
      {
        no: "2",
        text: "laptop",
        image: "img/FriendsPlus/Page113/E1/06.jpg",
      },
      {
        no: "3",
        text: "scissors",
        image: "img/FriendsPlus/Page113/E1/07.jpg",
      },
      {
        no: "4",
        text: "folder",
        image: "img/FriendsPlus/Page113/E1/09.jpg",
      },
      {
        no: "5",
        text: "uniform",
        image: "img/FriendsPlus/Page113/E1/10.jpg",
      },
      {
        no: "6",
        text: "rucksack",
        image: "img/FriendsPlus/Page113/E1/11.jpg",
      },
    ]
  },
  2: { // Exercise num
    
    unit : 'Option 4',
    id : 'SB6-OPE4-P113-E2',
    audio: 'img/FriendsPlus/Page113/E2/Audio/G6 SB Track 3.10.mp3',
    video: '',
    component: D1,
    fontSize: 24,
    height: 45,
    exerciseKey: 'img/FriendsPlus/Page113/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Complete the Key Phrases with words in the description.', color: '#0caa50' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page113/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page113/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page113/E2/3.jpg', input: true, answer: 'three' },
        { url: 'img/FriendsPlus/Page113/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page113/E2/5.jpg', input: true, answer: 'two' },
        { url: 'img/FriendsPlus/Page113/E2/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page113/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page113/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page113/E2/9.jpg', input: true, answer: 'yes' },
        { url: 'img/FriendsPlus/Page113/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page113/E2/11.jpg', input: true, answer: 'no' },
        { url: 'img/FriendsPlus/Page113/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page113/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page113/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page113/E2/15.jpg', input: true, answer: 'a clock and two posters' },
        { url: 'img/FriendsPlus/Page113/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page113/E2/17.jpg', input: true, answer: 'a poster' },
        { url: 'img/FriendsPlus/Page113/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page113/E2/19.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    
    unit : 'Option 4',
    id : 'SB6-OPE4-P113-E3',
    audio: 'img/FriendsPlus/Page113/E2/Audio/G6 SB Track 3.10.mp3',
    video: '',
    component: T6,
    textCenter: true,
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page113/E3/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page112/E1/1.png",
    // imgSize: '70%',
    titleQuestion: [{ color: '#E26D28', num: '3', title: 'Listen again. Read the sentences and write E (Ellie’s picture) or J (Joe’s picture)' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> There’s a teacher in this person’s picture. # </p>" +
          "<p><span style='font-weight:600'>2</span> A student is listening to music in this</p>" +
          "<p> person’s picture. # </p>" +
          "<p><span style='font-weight:600'>3</span> The students are chatting in this person’s</p>" +
          "<p> picture. # </p>" +
          "<p><span style='font-weight:600'>4</span> The students aren’t wearing uniforms in this</p>"
          + "<p> person’s picture. # </p>" +
          "<p><span style='font-weight:600'>5</span> There’s a poster of a giraffe in this</p>" +
          "<p> person’s picture. # </p>" +
          "<p><span style='font-weight:600'>6</span> There’s one window in this person’s picture. # </p>",
        answer: ['J', 'J', 'E', 'E', 'E', 'J'],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    
    unit : 'Option 4',
    id : 'SB6-OPE4-P113-E4',
    audio: 'img/FriendsPlus/Page113/E4/Audio/G6 SB Track 3.11.mp3',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page113/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    
    unit : 'Option 4',
    id : 'SB6-OPE4-P113-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page113/E5/Key/answerKey.png',
    component: T1,
    imgSize: '50%',
    titleImage: "img/FriendsPlus/Page113/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        // "<p> #. </p>" +
        // "<p> #. </p>" +
        // "<p> #. </p>" +
        // "<p> #. </p>" +
        // "<p> #. </p>" +
        // "<p> #. </p>" +
        // "<p> #. </p>" +
        // "<p> #. </p>" +
        // "<p> #. </p>" +
        // "<p> #. </p>",
        answer: [
          // "The clock says 3.15",//1
          // "There are four posters on the wall and no windows",//2
          // "There are two rucksacks on the gym bench",//3
          // "The rucksacks are blue",//4
          // "There are four students in the gym",//5
          // "Two girls are sitting on the bench",//6
          // "One of the girls on the bench is using a laptop",//7
          // "The girls are all wearing a PE kit",//8
          // "Two boys are playing basketball",//9
          // "The teacher’s folder is black",//10

        ],
        type: 'longAnwser'
      },
    ]
  },

}
export default json