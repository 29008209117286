
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P55-E1',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page55/E1/Key/1.png',
    titleImage: "img/FriendsPlus/Page55/E1/1.png",
    titleQuestion: [{ color: '#009241', num: '1', title: "Read the email. How many students are there in Danny's school? Is Danny’s school very different from your school?" }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. How many students are there in Danny's school?  #. </p>" +
          "<p>2. Is Danny’s school very different from your school? #. </p>",

        answer: ['There are about 500 students', ''],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P55-E2',
    audio: '',
    video: '',
    component: D1,
    fontSize: 25,
    height: 30,
    exerciseKey: 'img/FriendsPlus/Page55/E2/Key/Key.png',
    //titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    // typeInput : 'center',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/1.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/3.jpg', input: true, answer: 'happy' },
        { url: 'img/FriendsPlus/Page55/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/5.jpg', input: true, answer: 'you can study here next month' },
        { url: 'img/FriendsPlus/Page55/E2/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/9.jpg', input: true, answer: 'medium-sized' },
        { url: 'img/FriendsPlus/Page55/E2/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/13.jpg', input: true, answer: 'maths and English' },
        { url: 'img/FriendsPlus/Page55/E2/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/17.jpg', input: true, answer: 'optional' },
        { url: 'img/FriendsPlus/Page55/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/21.jpg', input: true, answer: 'photo' },
        { url: 'img/FriendsPlus/Page55/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/23.jpg', input: true, answer: 'my classmates' },
        { url: 'img/FriendsPlus/Page55/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/27.jpg', input: true, answer: 'a photo of yours' },
        { url: 'img/FriendsPlus/Page55/E2/28.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page55/E2/29.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P55-E3',
    audio: '',
    video: '',
    component: T6,
    titleImage: "",
    // textCenter:'true',
    inputSize: 80,
    exerciseKey: 'img/FriendsPlus/Page55/E3/Key/Key.png',
    titleQuestion: [{ color: '#009241', num: '3', title: 'Study the examples. Then match 1–4 with a–d and write sentences with <i>so</i>' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p style='font-family:OneStrokeScriptStd-Bold;color:gray'> I like languages, so I study French. </p>" +
          "<p><span style='font-weight:600'>1</span> I’m learning some Spanish words # </p>" +
          "<p><span style='font-weight:600'>2</span> I’ve got two bikes # </p>" +
          "<p><span style='font-weight:600'>3</span> There’s a history exam tomorrow # </p>" +
          "<p><span style='font-weight:600'>4</span> There aren’t any classes this afternoon #</p>" +
          "<p><span style='font-weight:600'>a</span> we can go and play football. </p>" +
          "<p><span style='font-weight:600'>b</span> I’m revising now. </p>" +
          "<p><span style='font-weight:600'>c</span> I need a dictionary. </p>" +
          "<p><span style='font-weight:600'>d</span> you can use one of them. </p></div>",
        answer: ['c', 'd', 'b', 'a'],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P55-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page55/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;
