import UI from '../../components/ExcerciseTypes/UseIt';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import U1 from '../../components/ExcerciseTypes/UnderLine/U1'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P29-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page29/E1/Key/1.png',
    // titleImage: "img/FriendsPlus/Page30/E1/1.png",
    titleQuestion: [{ num: '1', title: 'Match questions 1–5 with answers a–e. Then choose the correct words in rules 1–4.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:30px'><p><span style='font-weight:600'>1</span> What presents do you prefer?</span> #</p>" +
          "<p><span style='font-weight:600'>2</span> Where do you eat?</span> #</p>" +
          "<p><span style='font-weight:600'>3</span> Who does she invite?</span> #</p>" +
          "<p><span style='font-weight:600'>4</span> Does the lion stop</span> at all of the restaurants? #</p>" +
          "<p><span style='font-weight:600'>5</span> Do you like </span>fireworks? #</p>" +
          "<p><span style='font-weight:600'>a</span> No, I don’t.</span></p>" +
          "<p><span style='font-weight:600'>b</span> I prefer lucky money.</p>" +
          "<p><span style='font-weight:600'>c</span> At my grandmother’s house.</p>" +
          "<p><span style='font-weight:600'>d</span> Yes, it does.</span></p>" +
          "<p><span style='font-weight:600'>e</span> All the family.</span></p></div>",
        answer: ['b', 'c', 'e', 'd', 'a'],
        type: 'longAnwser'
      },
    ]
  },
  '1-1': {
    unit: 'Unit 2',
    id: 'SB6-U2-P29-E1.1',
    audio: '',
    video: 'Videos/Animation - Unit 2 grammar 2 trang 29.mp4',
    component: DesignUnderLine,
    exerciseKey: 'img/FriendsPlus/Page29/E1/Key/answerKey.png',
    totalInput: 4,
    titleQuestion: [{ num: '1', title: "Choose the correct words in the rules 1-4." }],
    questionImage: [ // Row

      [
        // Column1
        { url: 'img/FriendsPlus/Page29/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/5.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page29/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page29/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page29/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/9.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page29/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page29/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page29/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/17.jpg', input: 3, isCorrect: false },
        { url: 'img/FriendsPlus/Page29/E1/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page29/E1/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page29/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/21.jpg', input: 4, isCorrect: false },
        { url: 'img/FriendsPlus/Page29/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/23.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E1/24.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page29/E1/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P29-E2',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: U1,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page29/E2/Key/1.png',
    titleQuestion: [{ num: '2', title: 'Choose the correct words.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> # /#  you get up at 6 a.m.?</p>" +
          "<p><span style='font-weight:600'>2</span> Who # / # you see at the weekend?</p>" +
          "<p> <span style='font-weight:600'>3</span> # / # your dad watch TV in the evening?</p>" +
          "<p><span style='font-weight:600'>4</span> What do you # / #  for breakfast on weekdays?</p>" +
          "<p><span style='font-weight:600'>5</span> Where  # / # your family go in the holidays?</p>",
        answerString: ['Do ', "Does", 'are', 'do', 'Does', 'Do', 'has', 'have', 'is', 'does'],
        answer: ["Do", 'Do', 'Does', 'have', 'Does'],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P29-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page29/E3/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '3', title: 'Look at exercise 2. Complete the key phrases. Add more words to the list.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page29/E3/3.jpg', input: true, answer: 'in' },
        { url: 'img/FriendsPlus/Page29/E3/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page29/E3/7.jpg', input: true, answer: 'at' },
        { url: 'img/FriendsPlus/Page29/E3/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page29/E3/11.jpg', input: true, answer: 'on' },
        { url: 'img/FriendsPlus/Page29/E3/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page29/E3/15.jpg', input: true, answer: 'at' },
        { url: 'img/FriendsPlus/Page29/E3/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page29/E3/17.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P29-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page29/E4/1.png",
    // titleQuestion: [{ num: '2', title: 'Choose the correct verbs.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: {
    unit: 'Unit 2',
    id: 'SB6-U2-P29-E5',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page29/E5/Key/1.png',
    titleQuestion: [{ color: '#5FD0D3', num: '5', title: "Choose the correct word." }],
    questionImage: [ // Row
      [
        {
          url: 'img/FriendsPlus/Page29/E5/1.jpg'
        }
      ],
      [
        { url: 'img/FriendsPlus/Page29/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page29/E5/3.jpg', input: 1, isCorrect: true },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page29/E5/5.jpg', input: 2, isCorrect: false },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page29/E5/7.jpg', input: 3, isCorrect: false },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page29/E5/9.jpg', input: 4, isCorrect: true },
      ],
      [
        { url: 'img/FriendsPlus/Page29/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page29/E5/11.jpg', input: 5, isCorrect: false },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P29-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: 'img/FriendsPlus/Page29/E6/1.png',
    questionImage: [ // Row
    ],
    questions: [
      {
        title: '',
        answer: ['']
      },
    ]
  },

}
export default json;
