import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Wordsbox from '../../components/ExcerciseTypes/Design/WordBox';

const json = {
  8: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P36-E8',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page36/E8/Key/1.png',
    imgSize: '80%',
    titleImage: "img/FriendsPlus/Page36/E8/1.png",
    titleQuestion: [{ color: '#3B3074', num: '8', title: 'Complete the sentences with the words.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p><span style='font-weight:600'>1</span> I # up at 6.15 every morning. </p>" +
          "<p><span style='font-weight:600'>2</span> I # breakfast at 7.00. </p>" +
          "<p><span style='font-weight:600'>3</span> After breakfast, I always # my teeth and # my face </p>" +
          "<p><span style='font-weight:600'>4</span> My sister and I # to school by bus.</p>" +
          "<p><span style='font-weight:600'>5</span> After school I # with my friends – we sometimes talk for hours!</p>" +
          "<p><span style='font-weight:600'>6</span> I always # my parents with the housework.</p>" +
          "<p><span style='font-weight:600'>7</span> In my bedroom, I # at my desk with all my school books.</p>" +
          "<p><span style='font-weight:600'>8</span> Before bed, I usually # on the sofa and I sometimes # TV.</p></div>",


        answer: [
          "get",
          "have",
          "brush", "wash",
          "go",
          "chat",
          "help",
          "study",
          "relax", "watch",
        ],
        // type: 'longAnwser'
      },
    ]
  },
  9: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P36-E9',
    audio: '',
    video: '',
    // typeInput: 'center',
    component: Wordsbox,
    isAutofocus: true,
    fontSize: 29,
    paddingLeft: 8,
    exerciseKey: 'img/FriendsPlus/Page36/E9/Key/Key.png',
    titleImage: '',
    // titleQuestion: [{ color: '#4F3A96', num: '9', title: 'Complete the words in the text.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E9/3.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E9/4.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page36/E9/5.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page36/E9/6.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page36/E9/7.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E9/8.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E9/9.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page36/E9/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/12.jpg' },
        { url: 'img/FriendsPlus/Page36/E9/13.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page36/E9/14.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page36/E9/15.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page36/E9/16.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E9/17.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E9/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/20.jpg' },
        { url: 'img/FriendsPlus/Page36/E9/21.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page36/E9/22.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page36/E9/23.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page36/E9/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/26.jpg' },
        { url: 'img/FriendsPlus/Page36/E9/27.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page36/E9/28.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page36/E9/29.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page36/E9/30.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E9/31.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E9/32.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/33.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/34.jpg' },
        { url: 'img/FriendsPlus/Page36/E9/35.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E9/36.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page36/E9/37.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page36/E9/38.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page36/E9/39.jpg', input: true, answer: 'm' },
        { url: 'img/FriendsPlus/Page36/E9/40.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page36/E9/41.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page36/E9/42.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page36/E9/43.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E9/44.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E9/45.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/46.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/47.jpg' },
        { url: 'img/FriendsPlus/Page36/E9/48.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E9/49.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page36/E9/50.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page36/E9/51.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page36/E9/52.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page36/E9/53.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page36/E9/54.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page36/E9/55.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page36/E9/56.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E9/57.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page36/E9/58.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E9/59.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  10: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P36-E10',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page36/E10/Key/1.png',
    // titleImage: "img/FriendsPlus/Page32/E3/1.png",
    titleQuestion: [{ color: '#3B3074', num: '10', title: 'Make the negative sentences affirmative and the affirmative sentences negative.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> I don’t like cooking. ✔ #. </p>" +
          "<p><span style='font-weight:600'>2</span> We don’t play football every afternoon. ✔#. </p>" +
          "<p><span style='font-weight:600'>3</span> She doesn’t wash the car. ✔ #. </p>" +
          "<p><span style='font-weight:600'>4</span> They help with the housework. ✘ #. </p>" +
          "<p><span style='font-weight:600'>5</span> My brother studies in the evenings. ✘  #. </p>" +
          "<p><span style='font-weight:600'>6</span> You play video games. ✘ #.</p>",


        answer: [
          "I like cooking",
          "We play football every afternoon",
          "She washes the car",
          "They don't help with the housework",
          "My brother doesn't study in the evenings",
          "You don't play video games",
        ],
        type: 'longAnwser'
      },
    ]
  },
  11: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P36-E11',
    audio: 'img/FriendsPlus/Page36/E11/Audio/G6 SB Track 1.28.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page36/E11/Key/answerKey.png',
    typeInput: 'center',
    titleImage: 'img/FriendsPlus/Page36/E2/title.png',
    titleQuestion: [{ color: '#3B3074', num: '11', title: 'Listen and draw lines from names 1-6 to people a-f in the picture.' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page36/E11/1.jpg' },
        { url: 'img/FriendsPlus/Page36/E11/2.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page36/E11/3.jpg' },
        { url: 'img/FriendsPlus/Page36/E11/4.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page36/E11/5.jpg' },
        { url: 'img/FriendsPlus/Page36/E11/6.jpg', input: true, answer: 'd' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E11/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E11/8.jpg' },
        { url: 'img/FriendsPlus/Page36/E11/9.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page36/E11/10.jpg' },
        { url: 'img/FriendsPlus/Page36/E11/11.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page36/E11/12.jpg' },
        { url: 'img/FriendsPlus/Page36/E11/13.jpg', input: true, answer: 'a' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page36/E11/14.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


}
export default json