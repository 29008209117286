import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';


const json = {
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P97-E3',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: DesignUnderLine,
    titleImage: '',
    totalInput: 8,
    exerciseKey: 'img/FriendsPlus/Page97/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Work in pairs. Do the <i>Travel Quiz</i> and compare your answers.', color: '#115D98' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page97/E3/01.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/03.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/04.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page97/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page97/E3/07.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/08.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page97/E3/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page97/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page97/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page97/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/22.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page97/E3/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/25.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page97/E3/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/28.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page97/E3/29.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/31.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/32.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page97/E3/33.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/34.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page97/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/36.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page97/E3/37.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/38.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/39.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/40.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page97/E3/41.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/42.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page97/E3/43.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/44.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page97/E3/45.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/46.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/47.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/48.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page97/E3/49.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/50.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/51.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page97/E3/52.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/53.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/54.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page97/E3/55.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/56.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/57.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/58.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page97/E3/59.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/60.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page97/E3/61.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/62.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page97/E3/63.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/64.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/65.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/66.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page97/E3/67.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/68.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page97/E3/69.jpg' },
        { url: 'img/FriendsPlus/Page97/E3/70.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page97/E3/71.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page97/E3/72.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P97-E4',
    audio: 'img/FriendsPlus/Page97/E4/Audio/G6 SB Track 2.32.WAV',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page97/E4/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Listen and complete the Key Phrases.', color: '#115D98' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page97/E4/3.jpg', input: true, answer: 'holiday' },
        { url: 'img/FriendsPlus/Page97/E4/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page97/E4/7.jpg', input: true, answer: 'means of transport' },
        { url: 'img/FriendsPlus/Page97/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page97/E4/11.jpg', input: true, answer: 'boat' },
        { url: 'img/FriendsPlus/Page97/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page97/E4/15.jpg', input: true, answer: 'plane' },
        { url: 'img/FriendsPlus/Page97/E4/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page97/E4/19.jpg', input: true, answer: 'subway train' },
        { url: 'img/FriendsPlus/Page97/E4/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page97/E4/21.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P97-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page97/E5/1.png",
    imgSize: '70%',
    // titleQuestion: [{ color: '#2D2C25', num: '5', title: 'Find someone who … . Walk around the class and ask questions. Write a different name for each question.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json