import React from 'react'
import classNames from 'classnames';
import { ControlBar, CurrentTimeDisplay, ForwardControl, Player, ReplayControl } from 'video-react';
import { Container, Row } from 'reactstrap';

import DragItem from './DragItem';
import DropZone from './DropZone';
import FooterModal from '../../../FooterModal';
import TitleQuestion from '../../../TitleQuestion';

import styles from './styles.module.css';
import 'video-react/dist/video-react.css';
import BigPlayButton from 'video-react/lib/components/BigPlayButton';
import ClosedCaptionButton from 'video-react/lib/components/control-bar/ClosedCaptionButton';

const DD1 = (props) => {
  const correctAnswers = React.useMemo(() => {
    return props.questions.filter(x => typeof x.correctIndex === 'number')
  }, [props.questions]);

  const defaultState = React.useRef({
    isPointed: false,
    booleanArray: [],
    studentAnswer: null,
    dropZones: Array(correctAnswers.length).fill({}),
    dragItems: JSON.parse(JSON.stringify(props.questions)),
  });

  const [state, setState] = React.useState(JSON.parse(JSON.stringify(defaultState.current)));

  const onDrop = React.useCallback((item, index) => (payload) => {
    const { dragItems, dropZones } = state;

    if (item === -1) {// Drop from answer place to drag answer list
      const dropIndex = dropZones.findIndex((x) => x.text === payload.text);
      if (dropIndex > -1) {
        dropZones[dropIndex] = {};

        dragItems.push(payload);
      }
    } else {
      const dragIndex = dragItems.findIndex((x) => x.text === payload.text);

      if (dragIndex > -1) { // Drop from answer list
        let temp;
        if (dropZones[index].text) { // Drop to exist answer place
          temp = JSON.parse(JSON.stringify(dropZones[index]));
        }

        dropZones[index] = payload;

        if (temp) { // Drop to exist answer place
          dragItems.splice(dragIndex, 1, temp);
        } else {
          dragItems.splice(dragIndex, 1);
        }
      } else { // Drop around from answer places
        const prevDropIndex = dropZones.findIndex((x) => x.text === payload.text);

        // swap position ES6
        [dropZones[prevDropIndex], dropZones[index]] = [dropZones[index], dropZones[prevDropIndex]];
      }
    }

    setState((prevState) => ({
      ...prevState, dropZones, dragItems
    }));
  }, [state]);

  const renderDragItem = React.useCallback((item, index) => {
    return (
      <DragItem
        key={index}
        item={item}
        style={{ fontSize: 25 }}
        className={`${styles.dragItem} ${styles.textCenter}`}
      >
        {item.text}
      </DragItem>
    );
  }, []);

  const renderDropZoneItem = React.useCallback((item, index) => {
    let isCorrect = correctAnswers[index].text === item.text;
    const customStyles = { fontSize: 25 };

    const imageVisible = typeof correctAnswers[index].image === 'string';

    if (state.isPointed) {
      customStyles.color = isCorrect ? 'green' : 'red';
    }

    return (
      <DropZone
        key={index}
        className={classNames({
          [styles.flex]: true,
          [styles.dropZone]: true,
          [styles.columnFlex]: imageVisible,
          [styles.alignCenter]: imageVisible,
          [styles.justifyCenter]: !imageVisible,
        })
        }
        onZoneDrop={onDrop(item, index)} >
        {imageVisible && (
          <div className={`${styles.flex} ${styles.columnFlex} ${styles.alignCenter}`}>
            <img src={correctAnswers[index].image} alt="" className={styles.dropZoneImage} />
          </div>
        )}
        <div className={`${styles.flex} ${styles.alignCenter} ${styles.dropZoneText}`} >
          {index + 1}
          <DragItem
            item={item}
            disable={!item.text}
            className={`${styles.dropZoneContent} ${styles.justifyCenter}`}
            style={customStyles}
          >
            {item.text}
          </DragItem>
        </div>
      </DropZone >
    );
  }, [correctAnswers, onDrop, state.isPointed]);

  const onRetry = React.useCallback(() => {
    setState(JSON.parse(JSON.stringify(defaultState.current)))
  }, []);

  const toApi = React.useCallback((data) => {

    let studentAnswer = []

    studentAnswer.push(state.dropZones);

    let correctAnswer = 0;

    data.forEach((item, index) => {
      if (item) {
        correctAnswer++
      }
    })

    const percent = parseInt(correctAnswer / data.length * 100)
    // const resultString = `${correctAnswer}/${data.length} (${percent}%)`
    const result_String = `${correctAnswer}/${data.length}`
    const params = {
      score: percent,
      results: result_String,
      exerciseId: props.id,
      studentAnswer: JSON.stringify(studentAnswer),
      Unit: props.unit,
    }
    props.postAnswerToApi(params)

  }, [props, state.dropZones]);
  const onSubmit = React.useCallback(() => {
    let booleanArray = [];

    state.dropZones.forEach((element, index) => {
      const Answer = props.questions[index].text;
      if (element?.text === Answer) {
        booleanArray.push(true)
      } else {
        booleanArray.push(false)
      }
    });

    // eslint-disable-next-line no-unused-expressions
    toApi(booleanArray);

    setState((prevState) => ({ ...prevState, isPointed: true, booleanArray }));
  }, [state.dropZones, toApi]);



  if (!props) return null;

  const disableSubmit = state.dropZones.findIndex((x) => !x.text) > -1;

  return (
    <Container className='fluid'>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 200 }}>
        <TitleQuestion color={props.color} titleQuestion={props.titleQuestion} />
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={props?.titleImage} alt='...' />
      </Row>
      <div className={styles.flex}>
        {props.video && (
          <div className={styles.videoContainer}>
            <Player
              // playsInline
              src={props.video}
              className={styles.video}
            >
              <BigPlayButton position="center" />
              <source
                src={props.video}
                type="video/mp4"
                default
              />
              <track
                kind="captions"
                src={props.videoSub}
                srcLang="en"
                label="English"
              />
              <ControlBar autoHide={false}>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={10} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <ClosedCaptionButton order={7} />
              </ControlBar>
            </Player>
          </div>
        )}


      </div>
      <DropZone
        className={`${styles.flex} ${styles.flex1} ${styles.listContainer} ${styles.mv14}`}
        style={{ borderColor: props.color }}
        onZoneDrop={onDrop(-1, -1)}
      >
        {state.dragItems.map(renderDragItem)}
      </DropZone>
      <div className={styles.flex}>{state.dropZones.map(renderDropZoneItem)}</div>

      <FooterModal
        onRetry={onRetry}
        onSubmit={onSubmit}
        audioUrl={props.audio}
        result={state?.booleanArray}
        exerciseKey={props.exerciseKey}
        isDisabledRetry={!state.isPointed}
        isDisabledSubmit={disableSubmit || state.isPointed}
      />
    </Container>
  )
}
export default DD1