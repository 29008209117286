import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: { // Exercise num
    unit : 'Unit 6',
    id : 'SB6-U6-P76-E1',
    audio: 'img/FriendsPlus/Page76/E1/Audio/G6 SB Track 2.15.mp3',
    video: '',
    component: D1,
    // isHiddenCheck: true,
    fontSize: 25,
    height: 35,
    exerciseKey: 'img/FriendsPlus/Page76/E1/Key/answerKey.jpg',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Complete the words in the sentences.', color:'#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/3.jpg', input: true, answer: 'learned' },
        { url: 'img/FriendsPlus/Page76/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/5.jpg', input: true, answer: 'became' },
        { url: 'img/FriendsPlus/Page76/E1/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/8.jpg', input: true, answer: 'started' },
        { url: 'img/FriendsPlus/Page76/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/10.jpg', input: true, answer: 'watched' },
        { url: 'img/FriendsPlus/Page76/E1/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/13.jpg', input: true, answer: 'went' },
        { url: 'img/FriendsPlus/Page76/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/15.jpg', input: true, answer: 'did' },
        { url: 'img/FriendsPlus/Page76/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/18.jpg', input: true, answer: 'won' },
        { url: 'img/FriendsPlus/Page76/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/20.jpg', input: true, answer: 'competed' },
        { url: 'img/FriendsPlus/Page76/E1/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/23.jpg', input: true, answer: 'travelled' },
        { url: 'img/FriendsPlus/Page76/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page76/E1/25.jpg', input: true, answer: 'decided' },
        { url: 'img/FriendsPlus/Page76/E1/26.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  // 2: { // Exercise num
  unit : 'Unit 6',
  //   audio: 'img/FriendsPlus/Page76/E2/Audio/Friends Plus for Vietnam G6 SB Track 2.16.mp3',
  //   video: '',
  //   component: MC6,
  //   exerciseKey: 'img/FriendsPlus/Page76/E2/Key/answerKey.png',
  //   // titleQuestion: [{ num: '1', title: 'Look at the photo. Choose and write a title a–c for the text. Then read the text and check your idea.', color: '#C02A32' }],
  //   titleImage: 'img/FriendsPlus/Page76/E2/title.jpg',
  //   questionImage: [ // Row
  //     [
  //       // Column
  //       { url: 'http://' },
  //       { url: 'http://', input: true },
  //       { url: 'http://' },
  //     ],
  //   ],
  //   questions: [
  //     {
  //       title: 'A',
  //       answers: [
  //         { image: "", isCorrect: false, right: "", text: "parents talking" },
  //         { image: "", isCorrect: true, right: "", text: "sports fans talking" },
  //         { image: "", isCorrect: false, right: "", text: "Alana and Tom talking" },
  //       ],
  //       no: 1,
  //       question: '',
  //       type: '',
  //     },
  //     {
  //       title: 'B',
  //       answers: [
  //         { image: "", isCorrect: false, right: "", text: "at school" },
  //         { image: "", isCorrect: false, right: "", text: "at the X Games Asia" },
  //         { image: "", isCorrect: true, right: "", text: "on a TV programme" },
  //       ],
  //       no: 2,
  //       question: '',
  //       type: '',
  //     },
  //   ]
  // },

  2: {
    unit: 'Unit 6',
    id : 'SB6-U6-P76-E2',
    audio: 'img/FriendsPlus/Page76/E2/Audio/Friends Plus for Vietnam G6 SB Track 2.16.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page76/E2/Key/Key.png',
    component: DesignUnderLine,
    totalInput: 2,
    // titleQuestion: [{ num: '3', title: `Listen again and look at sentences 1–4 in the text. Choose the correct answers for Alana and then for Tom.`, color: '#D97C40' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page76/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/3.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page76/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/5.jpg', input: 2, },
        { url: 'img/FriendsPlus/Page76/E2/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/10.jpg', input: 2, },
        { url: 'img/FriendsPlus/Page76/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/13.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page76/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/15.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E2/17.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: {
    unit: 'Unit 6',
    id : 'SB6-U6-P76-E3',
    audio: 'img/FriendsPlus/Page76/E3/Audio/G6 SB Track 2.16.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page76/E3/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 8,
    titleQuestion: [{ num: '3', title: `Listen again and look at sentences 1–4 in the text. Choose the correct answers for Alana and then for Tom.`, color: '#D97C40' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page76/E3/02.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/03.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/04.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page76/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E3/07.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/08.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page76/E3/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/13.jpg', input: 2, },
        { url: 'img/FriendsPlus/Page76/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/15.jpg', input: 2, },
        { url: 'img/FriendsPlus/Page76/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/19.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/21.jpg', input: 3, },
        { url: 'img/FriendsPlus/Page76/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/23.jpg', input: 3, },
        { url: 'img/FriendsPlus/Page76/E3/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/26.jpg', input: 4, },
        { url: 'img/FriendsPlus/Page76/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/28.jpg', input: 4, },
        { url: 'img/FriendsPlus/Page76/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/30.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E3/31.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/33.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/34.jpg', input: 5, },
        { url: 'img/FriendsPlus/Page76/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/36.jpg', input: 5, },
        { url: 'img/FriendsPlus/Page76/E3/37.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/38.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E3/39.jpg' },
      ],
      [
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/40.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/41.jpg', input: 6, },
        { url: 'img/FriendsPlus/Page76/E3/42.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/43.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E3/44.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/45.jpg', input: 6, },
        { url: 'img/FriendsPlus/Page76/E3/46.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/47.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/48.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/49.jpg', input: 7, },
        { url: 'img/FriendsPlus/Page76/E3/50.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/51.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E3/52.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/53.jpg', input: 7, },
        { url: 'img/FriendsPlus/Page76/E3/54.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/55.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/56.jpg', input: 8, },
        { url: 'img/FriendsPlus/Page76/E3/57.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/58.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E3/59.jpg' },
        { url: 'img/FriendsPlus/Page76/E3/60.jpg', input: 8, },
        { url: 'img/FriendsPlus/Page76/E3/61.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page76/E3/62.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit : 'Unit 6',
    id : 'SB6-U6-P76-E4',
    audio: '',
    video: '',
    component: D1,
    isHiddenCheck: true,
    padding: 5,
    fontSize: 25,
    height: 25,
    exerciseKey: 'img/FriendsPlus/Page76/E4/Key/1.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Complete the words in the sentences.', color:'#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page76/E4/3.jpg', input: true, answer: 'learn' },
        { url: 'img/FriendsPlus/Page76/E4/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page76/E4/7.jpg', input: true, answer: 'travel' },
        { url: 'img/FriendsPlus/Page76/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page76/E4/11.jpg', input: true, answer: 'become' },
        { url: 'img/FriendsPlus/Page76/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page76/E4/15.jpg', input: true, answer: 'winning' },
        { url: 'img/FriendsPlus/Page76/E4/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page76/E4/19.jpg', input: true, answer: 'competing' },
        { url: 'img/FriendsPlus/Page76/E4/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/21.jpg' },

      ], [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/22.jpg' },
        { url: 'img/FriendsPlus/Page76/E4/23.jpg', input: true, answer: 'do' },
        { url: 'img/FriendsPlus/Page76/E4/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page76/E4/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}
export default json