import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T3 from '../../components/ExcerciseTypes/TypeIn/T3';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';

const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P24-E1',
    audio: 'img/FriendsPlus/Page24/E1/Audio/G6 SB Track 1.20.mp3',
    video: '',
    component: D1,
    fontSize: 19,
    exerciseKey: 'img/FriendsPlus/Page24/E1/Key/1.png',
    titleQuestion: [{ num: '1', title: 'Complete the table with the verbs in blue in the Days and Nights Quiz. Then listen and check.', color: '#005293' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/3.jpg', input: true, answer: "have breakfast/have lunch/have dinner" },
        { url: 'img/FriendsPlus/Page24/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/5.jpg', input: true, answer: "go to school/get home" },
        { url: 'img/FriendsPlus/Page24/E1/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/9.jpg', input: true, answer: "have breakfast/have lunch/have dinner" },
        { url: 'img/FriendsPlus/Page24/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/11.jpg', input: true, answer: "go to school/get home" },
        { url: 'img/FriendsPlus/Page24/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/13.jpg', input: true, answer: "watch TV/relax/play video games/chat online" },
        { url: 'img/FriendsPlus/Page24/E1/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/17.jpg', input: true, answer: "have breakfast/have lunch/have dinner" },
        { url: 'img/FriendsPlus/Page24/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/19.jpg', input: true, answer: "watch TV/relax/play video games/chat online" },
        { url: 'img/FriendsPlus/Page24/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/21.jpg', input: true, answer: "study/have classes/do their homework/help with the housework" },
        { url: 'img/FriendsPlus/Page24/E1/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/23.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/25.jpg', input: true, answer: "brush my teeth/wash my face/go to bed/sleep" },
        { url: 'img/FriendsPlus/Page24/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/27.jpg', input: true, answer: "watch TV/relax/play video games/chat online" },
        { url: 'img/FriendsPlus/Page24/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/29.jpg', input: true, answer: "study/have classes/do their homework/help with the housework" },
        { url: 'img/FriendsPlus/Page24/E1/30.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/31.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/33.jpg', input: true, answer: "brush my teeth/wash my face/go to bed/sleep" },
        { url: 'img/FriendsPlus/Page24/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/35.jpg', input: true, answer: "watch TV/relax/play video games/chat online" },
        { url: 'img/FriendsPlus/Page24/E1/36.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/37.jpg', input: true, answer: "study/have classes/do their homework/help with the housework" },
        { url: 'img/FriendsPlus/Page24/E1/38.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/39.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/41.jpg', input: true, answer: "brush my teeth/wash my face/go to bed/sleep" },
        { url: 'img/FriendsPlus/Page24/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/43.jpg', input: true, answer: "study/have classes/do their homework/help with the housework" },
        { url: 'img/FriendsPlus/Page24/E1/44.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/45.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/46.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/47.jpg', input: true, answer: "brush my teeth/wash my face/go to bed/sleep" },
        { url: 'img/FriendsPlus/Page24/E1/48.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/E1/49.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page24/9.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P24-E2',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T3,
    titleImage: 'img/FriendsPlus/Page24/E2/1.jpg',
    exerciseKey: 'img/FriendsPlus/Page24/E2/Key/answerKey.png',
    imgSize: '100%',
    inputSize: 100,
    titleQuestion: [{ color: '#005293', num: '2', title: 'Read the Days and Nights Quiz.Are the sentences True or False? Compare with a partner using the key phrases.' }],
    questionImage: [],
    questions: [
      {
        title:
          '<p>1. # </p>' +
          '<p>2. # </p>' +
          '<p>3. # </p>' +
          '<p>4. # </p>' +
          '<p>5. # </p>' +
          '<p>6. # </p>' +
          '<p>7. # </p>' +
          '<p>8. # </p>' +
          '<p>9. # </p>' +
          '<p>10. # </p>' +
          '<p>11. # </p>' +
          '<p>12. # </p>',

        answer: [
          "True",
          "True",
          'True',
          "False",
          'False',
          'True',
          'False',
          'True',
          'False',
          'True',
          'False',
          'False'
        ],
        // type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P24-E3',
    audio: '',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P24E3.mp4',
    videoSub: 'Videos/P24E3.vtt',
    component: T1,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page24/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Watch or listen. Which verbs in exercise 1 do the people mention?', color: '#005293' }],
    questionImage: [],
    questions: [
      {
        title: "</br>" +
          "<p><span style='font-weight:600'>1 Paul: </span> # </p>" +
          "<p><span style='font-weight:600'>2 Lydia: </span> # </p>" +
          "<p><span style='font-weight:600'>3 Michelle: </span> # </p>" +
          "<p><span style='font-weight:600'>4 Georgia and Sophie: </span> # </p>",
        answer: [
          "get up, have breakfast",
          "get up, have breakfast, go to school",
          "relax, watch TV, go to bed",
          "do their homework, watch TV, play video games, have dinner, chat online",
        ],
        type: "longAnwser",
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P24-E4',
    audio: 'img/FriendsPlus/Page24/E4/Audio/G6 SB Track 1.21.mp3',
    video: 'Videos/P24E3.mp4',
    videoSub: 'Videos/P24E3.vtt',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page24/E4/Key/1.png',
    titleImage: 'img/FriendsPlus/Page24/E4/1.png',
    imgSize: '60%',
    titleQuestion: [{ color: '#005293', num: '4', title: ' Check the meanings of the words in the box. Then watch or listen again and complete the sentences' }],
    questionImage: [],
    questions: [
      {
        title: "<p><span style='font-weight:600'>1</span> My work # starts early.</p>" +
          "<p><span style='font-weight:600'>2</span> I # have breakfast.</p>" +
          "<p><span style='font-weight:600'>3</span> I’m # late for school.</p>" +
          "<p><span style='font-weight:600'>4</span> The children # go to bed at about 9.</p>" +
          "<p><span style='font-weight:600'>5</span> I # go to bed before 11.</p>" +
          "<p><span style='font-weight:600'>6</span> I # watch TV or play video games</p>",
        answer: ['sometimes', 'always', 'often', 'normally', 'never', 'usually'],
        type: ''
      },
    ]
  },
}
export default json;