import DMC from '../../components/ExcerciseTypes/Design/MultipleChoice/MC3';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P54-E1',
    audio: 'img/FriendsPlus/Page54/E1/Audio/G6 SB Track 1.45.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P54E1.mp4',
    videoSub: 'Videos/P54E1.vtt',
    component: D1,
    fontSize: 18,
    exerciseKey: 'img/FriendsPlus/Page54/E1/Key/answerKey.png',
    //titleImage: 'http://',
    titleQuestion: [{ num: '1', title: 'Complete the dialogue with the key phrases. Then watch or listen and check. What word can’t Zac remember?', color: '#d91d71' }],
    // typeInput : 'center',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/3.jpg', input: true, answer: 'Can you help me with something, please?' },
        { url: 'img/FriendsPlus/Page54/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/7.jpg', input: true, answer: "How do you say" },
        { url: 'img/FriendsPlus/Page54/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/11.jpg', input: true, answer: 'Can you say that again, please?' },
        { url: 'img/FriendsPlus/Page54/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/15.jpg', input: true, answer: "How do you spell that?" },
        { url: 'img/FriendsPlus/Page54/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E1/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P54-E2',
    audio: 'img/FriendsPlus/Page54/E2/Audio/G6 SB Track 1.46.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page54/E2/Key/answerKey.png',
    //titleImage: 'http://',
    // titleQuestion: [{ num: '3', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    typeInput: 'center',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/3.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/4.jpg', input: true, answer: 'j' },
        { url: 'img/FriendsPlus/Page54/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/8.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page54/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/10.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page54/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/12.jpg', input: true, answer: "v" },
        { url: 'img/FriendsPlus/Page54/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/16.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page54/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/18.jpg', input: true, answer: "x" },
        { url: 'img/FriendsPlus/Page54/E2/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/22.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page54/E2/23.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page54/E2/26.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page54/E2/27.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page54/E2/28.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P54-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page54/E3/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P54-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page54/E4/Key/answerKey.png',
    component: DMC,
    titleQuestion: [{ num: '4', title: 'PRONUNCIATION: Consonant clusters', color: '#B63766', colorText: '#B63766', text: 'Indicate the word whose bold part differs from the other three in pronunciation.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "#im", boldText: "sw" },
          { image: "", isCorrect: false, right: "", text: "#ing", boldText: "sw" },
          { image: "", isCorrect: true, right: "", text: "#ord", boldText: "sw" },
          { image: "", isCorrect: false, right: "", text: "#eet", boldText: "sw" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "#ool", boldText: "sh" },
          { image: "", isCorrect: true, right: "", text: "#illing", boldText: "Sh" },
          { image: "", isCorrect: false, right: "", text: "#eme", boldText: "sh" },
          { image: "", isCorrect: false, right: "", text: "#olar", boldText: "sh" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "me#er", boldText: "mb" },
          { image: "", isCorrect: false, right: "", text: "a#er", boldText: "mb" },
          { image: "", isCorrect: true, right: "", text: "cli#er", boldText: "mb" },
          { image: "", isCorrect: false, right: "", text: "Dece#er", boldText: "mb" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "#ene", boldText: "sc" },
          { image: "", isCorrect: false, right: "", text: "#arf", boldText: "sc" },
          { image: "", isCorrect: false, right: "", text: "#an", boldText: "sc" },
          { image: "", isCorrect: false, right: "", text: "#ore", boldText: "sc" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "hu#er", boldText: "ng" },
          { image: "", isCorrect: false, right: "", text: "fi#er", boldText: "ng" },
          { image: "", isCorrect: false, right: "", text: "a#er", boldText: "ng" },
          { image: "", isCorrect: true, right: "", text: "si#er", boldText: "ng" },
        ],
        no: 5,
        question: '',
        type: '',
      },

    ],
  },
  5: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P54-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page54/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;
