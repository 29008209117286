import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {

  1: { // Exercise num
    unit : 'Unit 7',
    id : 'SB6-U7-P95-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page95/E1/Key/1.png',
    fontSize: 19,
    titleImage: '',
    titleQuestion: [{ num: '1', title: 'Read the sentences and complete the chart. Then draw the men’s faces.', color: '#32262a' }], 
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/page95/E1/1.jpg' },
      ],

      [//2
        // Column
        { url: 'img/FriendsPlus/page95/E1/2.jpg' },
        { url: 'img/FriendsPlus/page95/E1/3.jpg', input: true, answer: 'brown' },
        { url: 'img/FriendsPlus/page95/E1/4.jpg' },
        { url: 'img/FriendsPlus/page95/E1/5.jpg', input: true, answer: 'black' },
        { url: 'img/FriendsPlus/page95/E1/6.jpg' },
        { url: 'img/FriendsPlus/page95/E1/7.jpg', input: true, answer: 'blonde' },
        { url: 'img/FriendsPlus/page95/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E1/9.jpg' },
      ],
      [//3
        // Column
        { url: 'img/FriendsPlus/page95/E1/10.jpg' },
        { url: 'img/FriendsPlus/page95/E1/11.jpg', input: true, answer: 'curly' },
        { url: 'img/FriendsPlus/page95/E1/12.jpg' },
        { url: 'img/FriendsPlus/page95/E1/13.jpg', input: true, answer: 'long' },
        { url: 'img/FriendsPlus/page95/E1/14.jpg' },
        { url: 'img/FriendsPlus/page95/E1/15.jpg', input: true, answer: 'spiky' },
        { url: 'img/FriendsPlus/page95/E1/16.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E1/17.jpg' },
      ],
      [
        // Column

        { url: 'img/FriendsPlus/page95/E1/18.jpg' },
        { url: 'img/FriendsPlus/page95/E1/19.jpg', input: true, answer: 'blue' },
        { url: 'img/FriendsPlus/page95/E1/20.jpg' },
        { url: 'img/FriendsPlus/page95/E1/21.jpg', input: true, answer: 'brown' },
        { url: 'img/FriendsPlus/page95/E1/22.jpg' },
        { url: 'img/FriendsPlus/page95/E1/23.jpg', input: true, answer: 'grey' },
        { url: 'img/FriendsPlus/page95/E1/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E1/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E1/26.jpg' },
        { url: 'img/FriendsPlus/page95/E1/27.jpg', input: true, answer: 'square' },
        { url: 'img/FriendsPlus/page95/E1/28.jpg' },
        { url: 'img/FriendsPlus/page95/E1/29.jpg', input: true, answer: 'thin' },
        { url: 'img/FriendsPlus/page95/E1/30.jpg' },
        { url: 'img/FriendsPlus/page95/E1/31.jpg', input: true, answer: 'thin' },
        { url: 'img/FriendsPlus/page95/E1/32.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E1/33.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E1/34.jpg' },
        { url: 'img/FriendsPlus/page95/E1/35.jpg', input: true, answer: 'beard' },
        { url: 'img/FriendsPlus/page95/E1/36.jpg' },
        { url: 'img/FriendsPlus/page95/E1/37.jpg', input: true, answer: 'moustache' },
        { url: 'img/FriendsPlus/page95/E1/38.jpg' },
      ],

      [//7
        // Column
        { url: 'img/FriendsPlus/page95/E1/39.jpg' },

      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit : 'Unit 7',
    id : 'SB6-U7-P95-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page95/E2/1.png",
    imgSize:'70%',
    // titleQuestion: [{ color: '#2D2C25', num: '5', title: 'Find someone who … . Walk around the class and ask questions. Write a different name for each question.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit : 'Unit 7',
    id : 'SB6-U7-P95-E3',
    audio: '',
    video: '',
    component: T6,
    titleImage: "img/FriendsPlus/Page95/E3/1.png",
    exerciseKey: 'img/FriendsPlus/Page95/E3/Key/1.png',
    imgSize:'70%',
    inputSize:300,
    // titleQuestion: [{ color: '#2D2C25', num: '5', title: 'Find someone who … . Walk around the class and ask questions. Write a different name for each question.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> # </p>" +
          "<p><span style='font-weight:600'>2</span> # </p>" +
          "<p><span style='font-weight:600'>3</span> # </p>" +
          "<p><span style='font-weight:600'>4</span> # </p>" +
          "<p><span style='font-weight:600'>5</span> # </p>" +
          "<p><span style='font-weight:600'>6</span> # </p>" +
          "<p><span style='font-weight:600'>7</span> # </p>" ,
        answer: ['Them','her','you','us','it','me','him'],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit : 'Unit 7',
    id : 'SB6-U7-P95-E4',
    audio: '',
    video: '',
    component: D1,
    fontSize: 25,
    height: 27,
    exerciseKey: 'img/FriendsPlus/Page95/E4/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the conversation with the correct form of the verbs in the box.', color: '#17bbd8' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/page95/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E4/2.jpg' },
        { url: 'img/FriendsPlus/page95/E4/3.jpg', input: true, answer: 'get a job' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E4/4.jpg' },
        { url: 'img/FriendsPlus/page95/E4/5.jpg', input: true, answer: 'grow up' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E4/6.jpg' },
        { url: 'img/FriendsPlus/page95/E4/7.jpg', input: true, answer: 'leave school' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E4/8.jpg' },
        { url: 'img/FriendsPlus/page95/E4/9.jpg', input: true, answer: 'go to university' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page95/E4/10.jpg' },
        { url: 'img/FriendsPlus/page95/E4/11.jpg', input: true, answer: 'have children' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit : 'Unit 7',
    id : 'SB6-U7-P95-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page95/E5/1.jpg",
    titleQuestion: [{ color: '#2D2C25', num: '5', title: 'Find someone who … . Walk around the class and ask questions. Write a different name for each question.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json