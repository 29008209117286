import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {

    1: { // Exercise num
        unit: 'Unit 7',
        id: 'SB6-U7-P86-E1',
        audio: '',
        video: '',
        component: D1,
        fontSize: 23,
        height: 28,
        exerciseKey: 'img/FriendsPlus/Page86/E1/Key/1.jpg',
        titleQuestion: [{ color: '#006cbd', num: '1', title: 'Complete 1–6 with the words in the box.' }],
        titleImage: '',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/0.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page86/E1/3.jpg', input: true, answer: 'short' },
                { url: 'img/FriendsPlus/Page86/E1/4.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/5.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page86/E1/7.jpg', input: true, answer: 'long' },
                { url: 'img/FriendsPlus/Page86/E1/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page86/E1/11.jpg', input: true, answer: 'red' },
                { url: 'img/FriendsPlus/Page86/E1/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page86/E1/15.jpg', input: true, answer: 'round' },
                { url: 'img/FriendsPlus/Page86/E1/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/17.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/18.jpg' },
                { url: 'img/FriendsPlus/Page86/E1/19.jpg', input: true, answer: 'green' },
                { url: 'img/FriendsPlus/Page86/E1/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/21.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/22.jpg' },
                { url: 'img/FriendsPlus/Page86/E1/23.jpg', input: true, answer: 'moustache' },
                { url: 'img/FriendsPlus/Page86/E1/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E1/25.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    2: { // Exercise num
        unit: 'Unit 7',
        id: 'SB6-U7-P86-E2',
        audio: 'img/FriendsPlus/Page86/E2/Audio/G6 SB Track 2.23.mp3',
        video: '',
        component: T2,
        titleQuestion: [{ num: '2', title: ' Listen and repeat the words in exercise 1. Which words describe you?', color: '#1b70b7' }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>Which words describe you?</p>" +
                    " # " +
                    " # " +
                    " # ",
                answer: [

                ],
                type: 'longAnwser'
            },
        ]
    },

    3: { // Exercise num
        unit: 'Unit 7',
        id: 'SB6-U7-P86-E3',
        audio: 'img/FriendsPlus/Page86/E3/Audio/Friends Plus for Vietnam G6 SB Track 2.24.mp3',
        video: '',
        component: DesignUnderLine,
        totalInput: 4,
        exerciseKey: 'img/FriendsPlus/Page86/E3/Key/Key.png',
        // titleQuestion: [{ color: '#006cbd', num: '1', title: 'Complete 1–6 with the words in the box.' }],
        titleImage: '',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E3/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page86/E3/3.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page86/E3/4.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page86/E3/5.jpg', input: 1, isCorrect: true },
                { url: 'img/FriendsPlus/Page86/E3/6.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page86/E3/7.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page86/E3/9.jpg', input: 2 },
                { url: 'img/FriendsPlus/Page86/E3/10.jpg', input: 2 },
                { url: 'img/FriendsPlus/Page86/E3/11.jpg', input: 2, isCorrect: true },
                { url: 'img/FriendsPlus/Page86/E3/12.jpg', input: 2 },
                { url: 'img/FriendsPlus/Page86/E3/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E3/14.jpg' },
                {
                    url: 'img/FriendsPlus/Page86/E3/15.jpg', input: 3, isCorrect: true
                },
                { url: 'img/FriendsPlus/Page86/E3/16.jpg', input: 3 },
                { url: 'img/FriendsPlus/Page86/E3/17.jpg', input: 3 },
                { url: 'img/FriendsPlus/Page86/E3/18.jpg', input: 3 },
                { url: 'img/FriendsPlus/Page86/E3/19.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E3/20.jpg' },
                { url: 'img/FriendsPlus/Page86/E3/21.jpg', input: 4 },
                { url: 'img/FriendsPlus/Page86/E3/22.jpg', input: 4, isCorrect: true },
                { url: 'img/FriendsPlus/Page86/E3/23.jpg', input: 4 },
                { url: 'img/FriendsPlus/Page86/E3/24.jpg', input: 4 },
                { url: 'img/FriendsPlus/Page86/E3/25.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page86/E3/26.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
}
export default json;
