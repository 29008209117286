

import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox'



const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P71-E1',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page71/E1/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P71-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page71/E2/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P71-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page71/E3/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '2', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    // typeInput: 'center',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page71/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E3/2.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page71/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page71/E3/4.jpg', input: true, answer: "healthy" },
        { url: 'img/FriendsPlus/Page71/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page71/E3/6.jpg', input: true, answer: "full" },
        { url: 'img/FriendsPlus/Page71/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page71/E3/8.jpg', input: true, answer: "well" },
        { url: 'img/FriendsPlus/Page71/E3/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E3/10.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page71/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page71/E3/12.jpg', input: true, answer: "active" },
        { url: 'img/FriendsPlus/Page71/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page71/E3/14.jpg', input: true, answer: "fit" },
        { url: 'img/FriendsPlus/Page71/E3/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E3/16.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P71-E4',
    audio: '',
    video: '',
    component: WordsBox,
    fontSize: 40,
    exerciseKey: 'img/FriendsPlus/Page71/E4/Key/answerKey.png',
    //titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    typeInput: 'center',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/1.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/2.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page71/E4/3.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/7.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page71/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/12.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page71/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/14.jpg', input: true, answer: "z" },
        { url: 'img/FriendsPlus/Page71/E4/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/16.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/19.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page71/E4/20.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/21.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page71/E4/22.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/23.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page71/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/25.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page71/E4/26.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/27.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page71/E4/28.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/29.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page71/E4/30.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/31.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page71/E4/32.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/33.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page71/E4/34.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/35.jpg', input: true, answer: "y" },
        { url: 'img/FriendsPlus/Page71/E4/36.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/37.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/38.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/39.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/40.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page71/E4/41.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/42.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page71/E4/43.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/44.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page71/E4/45.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/46.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/47.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/48.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/49.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/50.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page71/E4/51.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/52.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page71/E4/53.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/54.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/55.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/56.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/57.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/58.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page71/E4/59.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/60.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page71/E4/61.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/62.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/63.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/64.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/65.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/66.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page71/E4/67.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/68.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page71/E4/69.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/70.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/71.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/72.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E4/73.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/74.jpg', input: true, answer: "y" },
        { url: 'img/FriendsPlus/Page71/E4/75.jpg' },
        { url: 'img/FriendsPlus/Page71/E4/76.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P71-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page71/E5/Key/answerKey.png',
    //titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    // typeInput: 'center',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page71/E5/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page71/E5/3.jpg', input: true, answer: "learning languages" },
      ],

      [
        { url: 'img/FriendsPlus/Page71/E5/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E5/5.jpg' },
        { url: 'img/FriendsPlus/Page71/E5/6.jpg', input: true, answer: "playing tennis" },
      ],

      [
        { url: 'img/FriendsPlus/Page71/E5/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page71/E5/9.jpg', input: true, answer: "sitting on the sofa" },
      ],

      [
        { url: 'img/FriendsPlus/Page71/E5/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page71/E5/12.jpg', input: true, answer: "doing housework" },
      ],

      [
        { url: 'img/FriendsPlus/Page71/E5/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page71/E5/14.jpg' },
        { url: 'img/FriendsPlus/Page71/E5/15.jpg', input: true, answer: "drinking fizzy drinks" },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P71-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page71/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },


}
export default json;
