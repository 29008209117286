import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';



const json = {

  9: { // Exercise num
    unit : 'Progress Review 4',
    id: 'SB6-PV4-P108-E9',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 6,
    // titleImage: 'img/FriendsPlus/Page12/E2/04.jpg',
    exerciseKey: 'img/FriendsPlus/Page108/E9/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Check the meanings of the words in blue. Then choose the correct words in the quiz.' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page108/E9/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/2.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page108/E9/4.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/5.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/6.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/7.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/10.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/11.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/12.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/13.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/14.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/15.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page108/E9/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/18.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/19.jpg', input: 3, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/20.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/21.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page108/E9/22.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/23.jpg', input: 3, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/25.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/26.jpg', input: 4, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/27.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/28.jpg', input: 4, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/29.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/31.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/33.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/34.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page108/E9/35.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/37.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/38.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/39.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page108/E9/40.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/41.jpg', input: 5, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/42.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/43.jpg', input: 5, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/44.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/45.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page108/E9/46.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/47.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page108/E9/48.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/49.jpg', input: 6, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/50.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/51.jpg', input: 6, isCorrect: false },
        { url: 'img/FriendsPlus/Page108/E9/52.jpg' },
        { url: 'img/FriendsPlus/Page108/E9/53.jpg' },
      ],
      [
        // { url: 'img/FriendsPlus/Page108/E9/54.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  10: { // Exercise num
    unit : 'Progress Review 4',
    id: 'SB6-PV4-P108-E10',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page108/E10/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Complete the Key Phrases with words in the description.', color: '#0caa50' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E10/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E10/2.jpg' },
        { url: 'img/FriendsPlus/Page108/E10/3.jpg', input: true, answer: "'m going to see" },
        { url: 'img/FriendsPlus/Page108/E10/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E10/5.jpg' },
        { url: 'img/FriendsPlus/Page108/E10/6.jpg', input: true, answer: 'are going to travel' },
        { url: 'img/FriendsPlus/Page108/E10/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E10/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E10/9.jpg' },
        { url: 'img/FriendsPlus/Page108/E10/10.jpg', input: true, answer: "isn't going to take" },
        { url: 'img/FriendsPlus/Page108/E10/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E10/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E10/13.jpg' },
        { url: 'img/FriendsPlus/Page108/E10/14.jpg', input: true, answer: 'Are you going to buy' },
        { url: 'img/FriendsPlus/Page108/E10/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E10/16.jpg' },
      ],
    ]
  },
  8: { // Exercise num
    unit : 'Progress Review 4',
    id: 'SB6-PV4-P108-E8',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page108/E8/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '8', title: 'Read the clue and write the object.', color: '#4f3e9d' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/2.jpg' },
        { url: 'img/FriendsPlus/Page108/E8/3.jpg', input: true, answer: "taxi" },
        { url: 'img/FriendsPlus/Page108/E8/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/6.jpg' },
        { url: 'img/FriendsPlus/Page108/E8/7.jpg', input: true, answer: 'ferry' },
        { url: 'img/FriendsPlus/Page108/E8/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/10.jpg' },
        { url: 'img/FriendsPlus/Page108/E8/11.jpg', input: true, answer: 'cable car' },
        { url: 'img/FriendsPlus/Page108/E8/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/14.jpg' },
        { url: 'img/FriendsPlus/Page108/E8/15.jpg', input: true, answer: 'helicopter' },
        { url: 'img/FriendsPlus/Page108/E8/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/18.jpg' },
        { url: 'img/FriendsPlus/Page108/E8/19.jpg', input: true, answer: "bicycle" },
        { url: 'img/FriendsPlus/Page108/E8/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/22.jpg' },
        { url: 'img/FriendsPlus/Page108/E8/23.jpg', input: true, answer: 'spaceship' },
        { url: 'img/FriendsPlus/Page108/E8/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/26.jpg' },
        { url: 'img/FriendsPlus/Page108/E8/27.jpg', input: true, answer: 'truck' },
        { url: 'img/FriendsPlus/Page108/E8/28.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E8/29.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  11: { // Exercise num
    unit : 'Progress Review 4',
    id: 'SB6-PV4-P108-E11',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page108/E11/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '11', title: 'Complete the sentences using the correct form of will and one of the verbs.', color: '#4f3e9d' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E11/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E11/2.jpg' },
        { url: 'img/FriendsPlus/Page108/E11/3.jpg', input: true, answer: "'ll need " },
        { url: 'img/FriendsPlus/Page108/E11/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E11/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E11/6.jpg' },
        { url: 'img/FriendsPlus/Page108/E11/7.jpg', input: true, answer: "won't be" },
        { url: 'img/FriendsPlus/Page108/E11/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E11/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E11/10.jpg' },
        { url: 'img/FriendsPlus/Page108/E11/11.jpg', input: true, answer: 'Will' },
        { url: 'img/FriendsPlus/Page108/E11/12.jpg' },
        { url: 'img/FriendsPlus/Page108/E11/13.jpg', input: true, answer: 'tell' },
        { url: 'img/FriendsPlus/Page108/E11/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E11/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E11/16.jpg' },
        { url: 'img/FriendsPlus/Page108/E11/17.jpg', input: true, answer: "will" },
        { url: 'img/FriendsPlus/Page108/E11/18.jpg' },
        { url: 'img/FriendsPlus/Page108/E11/19.jpg', input: true, answer: 'eat' },
        { url: 'img/FriendsPlus/Page108/E11/20.jpg' },
      ],
      [
        // Column
        // { url: 'img/FriendsPlus/Page108/E11/21.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  12: { // Exercise num
    unit : 'Progress Review 4',
    id: 'SB6-PV4-P108-E12',
    audio: 'img/FriendsPlus/Page108/E12/Audio/G6 SB Track 2.41.WAV',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page108/E12/Key/answerKey.png',
    titleImage: '',
    typeInput: true,
    //titleQuestion: [{ num: '2', title: 'Complete the Key Phrases with words in the description.', color: '#0caa50' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E12/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E12/2.jpg' },
        { url: 'img/FriendsPlus/Page108/E12/3.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page108/E12/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E12/5.jpg' },
        { url: 'img/FriendsPlus/Page108/E12/6.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page108/E12/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E12/8.jpg' },
        { url: 'img/FriendsPlus/Page108/E12/9.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page108/E12/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E12/11.jpg' },
        { url: 'img/FriendsPlus/Page108/E12/12.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page108/E12/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E12/14.jpg' },
        { url: 'img/FriendsPlus/Page108/E12/15.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page108/E12/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page108/E12/17.jpg' },
        { url: 'img/FriendsPlus/Page108/E12/18.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page108/E12/19.jpg' },
      ],
      [
        // Column
        // { url: 'img/FriendsPlus/Page108/E12/20.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}
export default json