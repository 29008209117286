import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
// import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  // 1: { // Exercise num
  //   audio: '',
  //   video: '',
  //   component: T6,
  //   titleImage: "img/FriendsPlus/Page42/E1/1.png",
  //   titleQuestion: [{ color: '#E26D28', num: '1', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title:
  //         "<p><span style='font-weight:600'>1</span> # </p>" +
  //         "<p><span style='font-weight:600'>2</span> # </p>" +
  //         "<p><span style='font-weight:600'>3</span> # </p>" +
  //         "<p><span style='font-weight:600'>4</span> # </p>" +
  //         "<p><span style='font-weight:600'>5</span> # </p>" +
  //         "<p><span style='font-weight:600'>6</span> # </p>",
  //       answer: [
  //         "hear",
  //         "grow",
  //         "kill",
  //         "drink",
  //         "survive",
  //         "see",
  //         "fly",
  //         "run"

  //       ],
  //       // type: 'longAnwser'
  //     },
  //   ]
  // },
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P42-E1',
    audio: 'img/FriendsPlus/Page42/E1/Audio/G6 SB Track 1.34.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page42/E1/Key/answerKey.png',
    // typeInput:'center',
    titleImage: 'img/FriendsPlus/Page42/E2/title.png',
    titleQuestion: [{ num: '1', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.', color: "#f77a19" }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/31.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/4.jpg', input: true, answer: 'hear' },
        { url: 'img/FriendsPlus/Page42/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/6.jpg', input: true, answer: 'drink' },
        { url: 'img/FriendsPlus/Page42/E1/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/10.jpg', input: true, answer: 'survive' },
        { url: 'img/FriendsPlus/Page42/E1/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/14.jpg', input: true, answer: 'grow' },
        { url: 'img/FriendsPlus/Page42/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/16.jpg', input: true, answer: 'see' },
        { url: 'img/FriendsPlus/Page42/E1/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/20.jpg', input: true, answer: 'see' },
        { url: 'img/FriendsPlus/Page42/E1/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/24.jpg', input: true, answer: 'kill' },
        { url: 'img/FriendsPlus/Page42/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/26.jpg', input: true, answer: 'fly' },
        { url: 'img/FriendsPlus/Page42/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/28.jpg', input: true, answer: 'run' },
        { url: 'img/FriendsPlus/Page42/E1/29.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page42/E1/30.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: {
    unit: 'Unit 3',
    id: 'SB6-U3-P42-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page42/E2/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    titleQuestion: [{ num: '2', title: `Work in pairs. Answer questions 1–6 in the quiz.`, color: '#E26D28' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page42/E2/01.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/02.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/03.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/04.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/05.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page42/E2/06.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/07.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/08.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E2/09.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/13.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page42/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/19.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page42/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/22.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page42/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/24.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page42/E2/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/27.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/29.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/31.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/32.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page42/E2/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/35.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/36.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E2/37.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/38.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page42/E2/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/40.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/41.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E2/42.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/43.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page42/E2/44.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/45.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/46.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/47.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/48.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/49.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/50.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: {
    unit: 'Unit 3',
    id: 'SB6-U3-P42-E3',
    audio: 'img/FriendsPlus/Page42/E3/Audio/G6 SB Track 1.35.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page42/E3/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    titleQuestion: [{ num: '3', title: `Listen to a podcast about the animals in the quiz and check your answers. How many correct answers have you got?`, color: '#E26D28' }],
    questionImage: [ // Row

      // [
      //   { url: 'img/FriendsPlus/Page42/E3/titleImg.png' },
      // ],
      [
        { url: 'img/FriendsPlus/Page42/E2/01.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/02.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/03.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/04.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/05.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page42/E2/06.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/07.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/08.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E2/09.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/13.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page42/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/19.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page42/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/22.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page42/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/24.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page42/E2/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/27.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/29.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/31.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/32.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page42/E2/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/35.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/36.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E2/37.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/38.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page42/E2/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/40.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/41.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E2/42.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/43.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page42/E2/44.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/45.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/46.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/47.jpg' },
        { url: 'img/FriendsPlus/Page42/E2/48.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E2/49.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E2/50.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: {
    unit: 'Unit 3',
    id: 'SB6-U3-P42-E4',
    audio: 'img/FriendsPlus/Page42/E3/Audio/G6 SB Track 1.35.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page42/E4/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 2,
    titleQuestion: [{ num: '4', title: `Read the Skills Strategy and listen again. What two extra pieces of information do you hear? Choose from a–d.`, color: '#E26D28' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page42/E3/titleImg.png' },
      ],

      [
        { url: 'img/FriendsPlus/Page42/E4/02.jpg' },
        { url: 'img/FriendsPlus/Page42/E4/03.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E4/04.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E4/05.jpg' },
        { url: 'img/FriendsPlus/Page42/E4/06.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E4/07.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page42/E4/08.jpg' },
        { url: 'img/FriendsPlus/Page42/E4/09.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E4/10.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page42/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page42/E4/12.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page42/E4/13.jpg' },

      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P42-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page42/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [
          "hear",
          "grow",
          "kill",
          "drink",
          "survive",
          "see",
          "fly",
          "run"

        ],
        type: 'longAnwser'
      },
    ]
  },
}

export default json