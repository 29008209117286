import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import TypeIn_Uderline from "../../components/ExcerciseTypes/Design/TypeIn_Uderline";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "SB6-U5-P65-E1",
    audio: "",
    video: "Videos/Animation - Unit 5 grammar 1 trang 65.mp4",
    component: D1,
    height: 30,
    exerciseKey: "img/FriendsPlus/Page65/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Find examples of the words below in the texts on pages 62 and 63.<br/> Which are countable (c) and which are uncountable (u)? Add the words to Rules 1–2.",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page65/E1/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page65/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/3.jpg",
          input: true,
          answer: "an egg",
        },
        { url: "img/FriendsPlus/Page65/E1/4.jpg" },
        { url: "img/FriendsPlus/Page65/E1/5.jpg", input: true, answer: "eggs" },
        { url: "img/FriendsPlus/Page65/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/7.jpg",
          input: true,
          answer: " a sweet",
        },
        { url: "img/FriendsPlus/Page65/E1/8.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page65/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/10.jpg",
          input: true,
          answer: "sweets",
        },
        { url: "img/FriendsPlus/Page65/E1/11.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/12.jpg",
          input: true,
          answer: "a snack",
        },
        { url: "img/FriendsPlus/Page65/E1/13.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/14.jpg",
          input: true,
          answer: "snacks",
        },
        { url: "img/FriendsPlus/Page65/E1/15.jpg" },
      ],

      [{ url: "img/FriendsPlus/Page65/E1/16.jpg" }],

      [
        { url: "img/FriendsPlus/Page65/E1/17.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/18.jpg",
          input: true,
          answer: "bread/pasta/water",
        },
        { url: "img/FriendsPlus/Page65/E1/19.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/20.jpg",
          input: true,
          answer: "bread/pasta/water",
        },
        { url: "img/FriendsPlus/Page65/E1/21.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/22.jpg",
          input: true,
          answer: "bread/pasta/water",
        },
        { url: "img/FriendsPlus/Page65/E1/23.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E1/24.jpg" }],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 5",
    id: "SB6-U5-P65-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page65/E2/Key/1.png",
    fontSize: 18,
    questionImage: [
      [{ url: "img/FriendsPlus/Page65/E2/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page65/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page65/E2/3.jpg",
          input: 1,
          answer: "vegetables",
        },
      ],
      [{ url: "img/FriendsPlus/Page65/E2/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page65/E2/5.jpg" },
        { url: "img/FriendsPlus/Page65/E2/6.jpg", input: 1, answer: "rice" },
        { url: "img/FriendsPlus/Page65/E2/7.jpg" },
        { url: "img/FriendsPlus/Page65/E2/8.jpg", input: 1, answer: "pears" },
        { url: "img/FriendsPlus/Page65/E2/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E2/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page65/E2/11.jpg" },
        { url: "img/FriendsPlus/Page65/E2/12.jpg", input: 1, answer: "cheese" },
        { url: "img/FriendsPlus/Page65/E2/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E2/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page65/E2/15.jpg" },
        { url: "img/FriendsPlus/Page65/E2/16.jpg", input: 1, answer: "meat" },
        { url: "img/FriendsPlus/Page65/E2/17.jpg" },
        { url: "img/FriendsPlus/Page65/E2/18.jpg", input: 1, answer: "crisps" },
        { url: "img/FriendsPlus/Page65/E2/19.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E2/20.jpg" }],
    ],
  },

  3: {
    unit: "Unit 5",
    id: "SB6-U5-P65-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page65/E3/Key/answerKey.png",
    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "3",
        title: `Look at the sentences in exercise 2 and choose the correct words in Rules 1–3.`,
        color: "#57B4C3",
      },
    ],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page65/E3/03.jpg" }],
      [
        { url: "img/FriendsPlus/Page65/E3/05.jpg" },
        { url: "img/FriendsPlus/Page65/E3/06.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page65/E3/07.jpg" },
        { url: "img/FriendsPlus/Page65/E3/08.jpg", input: 1 },
        { url: "img/FriendsPlus/Page65/E3/09.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E3/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page65/E3/11.jpg" },
        { url: "img/FriendsPlus/Page65/E3/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page65/E3/13.jpg" },
        { url: "img/FriendsPlus/Page65/E3/14.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page65/E3/15.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E3/16.jpg" }],
      [
        { url: "img/FriendsPlus/Page65/E3/17.jpg" },
        { url: "img/FriendsPlus/Page65/E3/18.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page65/E3/19.jpg" },
        { url: "img/FriendsPlus/Page65/E3/20.jpg", input: 3 },
        { url: "img/FriendsPlus/Page65/E3/21.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E3/22.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 5",
    id: "SB6-U5-P65-E4",
    audio: "",
    video: "",
    component: TypeIn_Uderline,
    exerciseKey: "img/FriendsPlus/Page65/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `Complete questions 1–5 with <i>much</i> and <i>many</i>.<br/> Then choose the correct words in the answers.`,
        color: "#57B4C3",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page65/E4/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page65/E4/2.jpg" },
        { url: "img/FriendsPlus/Page65/E4/3.jpg", input: true, answer: "much" },
        { url: "img/FriendsPlus/Page65/E4/4.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page65/E4/5.jpg" },
        { url: "img/FriendsPlus/Page65/E4/6.jpg", select: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page65/E4/7.jpg" },
        { url: "img/FriendsPlus/Page65/E4/8.jpg", select: 1 },
        { url: "img/FriendsPlus/Page65/E4/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/10.jpg" },
        { url: "img/FriendsPlus/Page65/E4/11.jpg", select: 1 },
        { url: "img/FriendsPlus/Page65/E4/12.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/13.jpg" },
        {
          url: "img/FriendsPlus/Page65/E4/14.jpg",
          input: true,
          answer: "many",
        },
        { url: "img/FriendsPlus/Page65/E4/15.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/16.jpg" },
        { url: "img/FriendsPlus/Page65/E4/17.jpg", select: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page65/E4/18.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/19.jpg" },
        { url: "img/FriendsPlus/Page65/E4/20.jpg", select: 2 },
        { url: "img/FriendsPlus/Page65/E4/21.jpg" },
        { url: "img/FriendsPlus/Page65/E4/22.jpg", select: 2 },
        { url: "img/FriendsPlus/Page65/E4/23.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E4/24.jpg" }],
      [
        { url: "img/FriendsPlus/Page65/E4/25.jpg" },
        {
          url: "img/FriendsPlus/Page65/E4/26.jpg",
          input: true,
          answer: "much",
        },
        { url: "img/FriendsPlus/Page65/E4/27.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/28.jpg" },
        { url: "img/FriendsPlus/Page65/E4/29.jpg", select: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page65/E4/30.jpg" },
        { url: "img/FriendsPlus/Page65/E4/31.jpg", select: 3 },
        { url: "img/FriendsPlus/Page65/E4/32.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/33.jpg" },
        { url: "img/FriendsPlus/Page65/E4/34.jpg", select: 3 },
        { url: "img/FriendsPlus/Page65/E4/35.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/36.jpg" },
        {
          url: "img/FriendsPlus/Page65/E4/37.jpg",
          input: true,
          answer: "much",
        },
        { url: "img/FriendsPlus/Page65/E4/38.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/39.jpg" },
        { url: "img/FriendsPlus/Page65/E4/40.jpg", select: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page65/E4/41.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/42.jpg" },
        { url: "img/FriendsPlus/Page65/E4/43.jpg", select: 4 },
        { url: "img/FriendsPlus/Page65/E4/44.jpg" },
        { url: "img/FriendsPlus/Page65/E4/45.jpg", select: 4 },
        { url: "img/FriendsPlus/Page65/E4/46.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/47.jpg" },
        {
          url: "img/FriendsPlus/Page65/E4/48.jpg",
          input: true,
          answer: "many",
        },
        { url: "img/FriendsPlus/Page65/E4/49.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page65/E4/50.jpg" },
        { url: "img/FriendsPlus/Page65/E4/51.jpg", select: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page65/E4/52.jpg" },
        { url: "img/FriendsPlus/Page65/E4/53.jpg", select: 5 },
        { url: "img/FriendsPlus/Page65/E4/54.jpg" },
        { url: "img/FriendsPlus/Page65/E4/55.jpg", select: 5 },
        { url: "img/FriendsPlus/Page65/E4/56.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E4/57.jpg" }],
    ],
  },

  5: {
    // Exercise num
    unit: "Unit 5",
    id: "SB6-U5-P65-E5",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page65/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
