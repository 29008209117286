import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P79-E1',
    audio: '',
    video: '',
    component: T1,
    // inputSize: 900,
    titleImage: "img/FriendsPlus/Page79/E1/title.jpg",
    exerciseKey: "img/FriendsPlus/Page79/E1/Key/answerKey.jpg",
    imgSize: '110%',
    // titleQuestion: [{ num: '1', title: 'Read the profile. Where was Usain Bolt born? When was his first world champion?', color: '#009241' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p>1. Where was Usain Bolt born? #. </p>" +
          "<p>2. When was his first world champion? #. </p>",
        answer: [
          'In Sherwood Content, Jamaica',
          'In 2002'
        ],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P79-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page79/E2/Key/answerKey.png',
    // inputSize: 500,
    titleImage: "img/FriendsPlus/Page79/E2/1.png",
    imgSize: '60%',
    titleQuestion: [{ num: '2', title: 'Look at the Key Phrases. Which phrases do we use in paragraphs 1–3? Read the profile again and check.', color: '#009241' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p><span style='font-weight:600'>Paragraph 1</span> # </p>" +
          "<p><span style='font-weight:600'>Paragraph 2</span> # </p>" +
          "<p><span style='font-weight:600'>Paragraph 3</span> # </p>",

        answer: [
          '1 and 2', '3', '4, 5 and 6',
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P79-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page79/E3/Key/answerKey.png',
    inputSize: 50,
    textCenter: true,
    //titleImage: "img/FriendsPlus/Page79/E3/1.png",
    imgSize: '60%',
    titleQuestion: [{ num: '3', title: 'Read the text again. Match three topics from a–e with paragraphs 1–3.', color: '#009241' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p><span style='font-weight:600'>a</span> His life now </p>" +
          "<p><span style='font-weight:600'>b</span> Greatest moments </p>" +
          "<p><span style='font-weight:600'>c</span> Basic information </p>" +
          "<p><span style='font-weight:600'>d</span> Problems </p>" +
          "<p><span style='font-weight:600'>e</span> Early career </p>" +
          "<p><span style='font-weight:600'>Paragraph 1</span> # </p>" +
          "<p><span style='font-weight:600'>Paragraph 2</span> # </p>" +
          "<p><span style='font-weight:600'>Paragraph 3</span> # </p>",

        answer: [
          'c', 'e', 'b',
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P79-E4',
    audio: '',
    video: '',
    imgSize: 1000,
    component: T2,
    titleImage: "img/FriendsPlus/Page79/E4/title2.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json