import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MC7 from '../../components/ExcerciseTypes/MultipleChoice/MC7';
import DMC from '../../components/ExcerciseTypes/Design/MultipleChoice/MC1';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P19-E1",
    audio: "",
    video: "Videos/Animation - Unit 1 grammar 2 trang 19.mp4",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page19/E1/Key/answerKey.png",
    titleQuestion: [{ num: '1', title: 'Write the comparative form of the adjectives in the table.' }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page19/E1/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page19/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/3.jpg", input: true, answer: "cleaner",
        },
        { url: "img/FriendsPlus/Page19/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/5.jpg", input: true, answer: "slower",
        },
        { url: "img/FriendsPlus/Page19/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/7.jpg", input: true, answer: "more modern",
        },
        { url: "img/FriendsPlus/Page19/E1/8.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page19/E1/9.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/10.jpg", input: true, answer: "nicer",
        },
        { url: "img/FriendsPlus/Page19/E1/11.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/12.jpg", input: true, answer: "safer",
        },
        { url: "img/FriendsPlus/Page19/E1/13.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/14.jpg", input: true, answer: "more expensive",
        },
        { url: "img/FriendsPlus/Page19/E1/15.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page19/E1/16.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/17.jpg", input: true, answer: "friendlier",
        },
        { url: "img/FriendsPlus/Page19/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/19.jpg", input: true, answer: "prettier",
        },
        { url: "img/FriendsPlus/Page19/E1/20.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page19/E1/21.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/22.jpg", input: true, answer: "bigger",
        },
        { url: "img/FriendsPlus/Page19/E1/23.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/24.jpg", input: true, answer: "hotter",
        },
        { url: "img/FriendsPlus/Page19/E1/25.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page19/E1/26.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page19/E1/27.jpg" },
        { url: "img/FriendsPlus/Page19/E1/28.jpg", input: true, answer: "better" },
        { url: "img/FriendsPlus/Page19/E1/29.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page19/E1/30.jpg" },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P19-E2',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page19/E2/Key/Key.png',
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Write sentences using the correct comparative form of the adjectives in brackets. How do you say <i>than</i> in your language?.' }],
    questionImage: [],
    questions: [
      {
        title: `<p>New York / is / than / Washington. (big)</p>
        <p>New York is bigger than Washington.</p>
        <p>1 It’s / here / than / in my country. (expensive) #.</p>
        <p>2 The weather today / is / than / it was yesterday. (bad) #.</p>
        <p>3 Why / is / this class / than / the other class? (quiet) #?</p>
        <p>4 Are / the buildings / in New York / than / the buildings in Oxford? (modern)#?</p>
        <p>5 This house / is / than / that house. (pretty) #.</p>`,
        answer: [
          "It's more expensive here than in my country/It's more expensive here than in my country.",
          "The weather today is worse than it was yesterday/The weather today is worse than it was yesterday.",
          'Why is this class quieter than the other class/Why is this class quieter than the other class?',
          'Are the buildings in New York more modern than the buildings in Oxford/Are the buildings in New York more modern than the buildings in Oxford?',
          'This house is prettier than that house/This house is prettier than that house.',
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P19-E3',
    audio: 'img/FriendsPlus/Page19/E3/Audio/Friends Plus for Vietnam G6 SB Track 1.17.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page19/E3/Key/answerKey.png',
    component: DMC,
    // titleQuestion: [{ num: '3', title: 'Now indicate the word whose bold part differs from the other three in pronunciation.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "clean#r", boldText: "e" },
          { image: "", isCorrect: false, right: "", text: "wom#n", boldText: "a" },
          { image: "", isCorrect: false, right: "", text: "moth#r", boldText: "e" },
          { image: "", isCorrect: true, right: "", text: "m#n", boldText: "a" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "nic#r", boldText: "e" },
          { image: "", isCorrect: false, right: "", text: "saf#r", boldText: "e" },
          { image: "", isCorrect: false, right: "", text: "#bout", boldText: "a" },
          { image: "", isCorrect: true, right: "", text: "#ny", boldText: "a" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "cheap#r", boldText: "e" },
          { image: "", isCorrect: false, right: "", text: "bigg#r", boldText: "e" },
          { image: "", isCorrect: true, right: "", text: "comp#te", boldText: "e" },
          { image: "", isCorrect: false, right: "", text: "tut#r", boldText: "o" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "larg#r", boldText: "e" },
          { image: "", isCorrect: false, right: "", text: "ent#r", boldText: "e" },
          { image: "", isCorrect: false, right: "", text: "gramm#r", boldText: "a" },
          { image: "", isCorrect: true, right: "", text: "v#rb", boldText: "e" },
        ],
        no: 4,
        question: '',
        type: '',
      },

    ],
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P19-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page19/E4/Key/answerKey.png',
    component: MC7,
    titleQuestion: [{ num: '4', title: 'Read City on the Sea (page16) again. You may discover some letters pronounced differently in different words. Now indicate the word whose bold part differs from the other three in pronunciation.' }],
    // titleImage: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "#ea", boldText: "s" },
          { image: "", isCorrect: false, right: "", text: "#quare", boldText: "s" },
          { image: "", isCorrect: false, right: "", text: "#tar", boldText: "s" },
          { image: "", isCorrect: true, right: "", text: "cinema#", boldText: "s" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "#abin", boldText: "c" },
          { image: "", isCorrect: false, right: "", text: "#omfortable", boldText: "c" },
          { image: "", isCorrect: false, right: "", text: "#afé", boldText: "c" },
          { image: "", isCorrect: true, right: "", text: "#inema", boldText: "c" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "tree#", boldText: "s" },
          { image: "", isCorrect: false, right: "", text: "plant#", boldText: "s" },
          { image: "", isCorrect: false, right: "", text: "sport#", boldText: "s" },
          { image: "", isCorrect: false, right: "", text: "shop#", boldText: "s" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "#ork", boldText: "w" },
          { image: "", isCorrect: false, right: "", text: "s#imming", boldText: "w" },
          { image: "", isCorrect: false, right: "", text: "t#enty", boldText: "w" },
          { image: "", isCorrect: true, right: "", text: "ans#er", boldText: "w" },
        ],
        no: 4,
        question: '',
        type: '',
      },

    ],
  },
  5: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P19-E5',
    audio: '',
    video: '',
    component: T2,
    imgSize: '70%',
    titleImage: "img/FriendsPlus/Page19/E5/title.jpg",
    titleQuestion: '',
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

  5.1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P19-E5.1',
    audio: '',
    video: '',
    component: T2,
    imgSize: '80%',
    titleImage: "img/FriendsPlus/Page19/E5.1/title.jpg",
    titleQuestion: '',
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;