import T3 from "../../components/ExcerciseTypes/TypeIn/T3";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "SB6-U6-P81-E1",
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: "",
    component: T3,
    fontSize: 30,
    height: 32,
    titleImage: "img/FriendsPlus/Page81/E1/1.jpg",
    exerciseKey: "img/FriendsPlus/Page81/E1/Key/1.png",
    // titleQuestion: [{ color: '#005293', num: '2', title: 'Read the Days and Nights Quiz.Are the sentences True or False? Compare with a partner using the key phrases.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. BA BE  </p>" +
          "<p>#</p>" +
          "<p>2. PA PO </p>" +
          "<p>#</p>" +
          "<p>3. TE PU </p>" +
          "<p>#</p>" +
          "<p>4. BI PU </p>" +
          "<p>#</p>" +
          "<p>5. PE PO </p>" +
          "<p>#</p>" +
          "<p>6. SO BE </p>" +
          "<p>#</p>" +
          "<p>7. SE GI </p>" +
          "<p>#</p>" +
          "<p>8. TO PO </p>" +
          "<p>#</p>",

        answer: [
          "football",
          "athletics",
          "hockey",
          "rugby",
          "gymnastics",
          "volleyball",
          "judo",
          "tennis",
        ],
        // type: 'longAnwser'
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "SB6-U6-P81-E2",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page81/E2/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "SB6-U6-P81-E3",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page81/E3/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "SB6-U6-P81-E4",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page81/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },

  5: {
    // Exercise num
    unit: "Unit 6",
    id: "SB6-U6-P81-E5",
    audio: "",
    video: "",
    component: D1,
    height: 50,
    fontSize: 30,
    paddingLeft: 7,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page81/E5/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/2.jpg" },
        { url: "img/FriendsPlus/Page81/E5/3.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page81/E5/4.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page81/E5/5.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page81/E5/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/7.jpg" },
        { url: "img/FriendsPlus/Page81/E5/8.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page81/E5/9.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page81/E5/10.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page81/E5/11.jpg", input: true, answer: "k" },
        { url: "img/FriendsPlus/Page81/E5/12.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page81/E5/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/14.jpg" },
        { url: "img/FriendsPlus/Page81/E5/15.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page81/E5/16.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page81/E5/17.jpg", input: true, answer: "n" },
        { url: "img/FriendsPlus/Page81/E5/18.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/19.jpg" },
        { url: "img/FriendsPlus/Page81/E5/20.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page81/E5/21.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page81/E5/22.jpg", input: true, answer: "n" },
        { url: "img/FriendsPlus/Page81/E5/23.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/24.jpg" },
        { url: "img/FriendsPlus/Page81/E5/25.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page81/E5/26.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page81/E5/27.jpg", input: true, answer: "n" },
        { url: "img/FriendsPlus/Page81/E5/28.jpg", input: true, answer: "t" },
        { url: "img/FriendsPlus/Page81/E5/29.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/30.jpg" },
        { url: "img/FriendsPlus/Page81/E5/31.jpg", input: true, answer: "d" },
        { url: "img/FriendsPlus/Page81/E5/32.jpg", input: true, answer: "i" },
        { url: "img/FriendsPlus/Page81/E5/33.jpg", input: true, answer: "d" },
        { url: "img/FriendsPlus/Page81/E5/34.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/35.jpg" },
        { url: "img/FriendsPlus/Page81/E5/36.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page81/E5/37.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page81/E5/38.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page81/E5/39.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page81/E5/40.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/41.jpg" },
        {
          url: "img/FriendsPlus/Page81/E5/42.jpg",
          input: true,
          answer: "skate",
        },
        { url: "img/FriendsPlus/Page81/E5/43.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page81/E5/44.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};
export default json;
