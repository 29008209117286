import T3 from '../../components/ExcerciseTypes/TypeIn/T3';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import TypeIn_Uderline from '../../components/ExcerciseTypes/Design/TypeIn_Uderline';

const json = {
  4: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P87-E4',
    audio: 'img/FriendsPlus/Page87/E4/Audio/Friends Plus for Vietnam G6 SB Track 2.25.mp3',
    video: '',
    component: T3,
    // inputSize: 100,
    exerciseKey: 'img/FriendsPlus/Page87/E4/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page87/E4/1.png",
    imgSize: '100%',
    inputSize: 100,
    titleQuestion: [{ num: '4', title: 'Watch or listen. Look at photos a-g. Which photos are the people talking about?', color: '#1257A4' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p><span style='font-weight:600'>1</span> photo # </p>" +
          "<p><span style='font-weight:600'>2</span> photo # </p>" +
          "<p><span style='font-weight:600'>3</span> photo # </p>",

        answer: [
          'a', 'g', 'b'
        ],
        // type: 'longAnwser'
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P87-E5',
    audio: 'img/FriendsPlus/Page87/E5/Audio/Friends Plus for Vietnam G6 SB Track 2.25.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page87/E5/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Check the meanings of the words in blue. Then choose the correct words in the quiz.' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page87/E5/3.jpg' },
        // { url: 'img/FriendsPlus/Page87/E5/4.jpg', videoUrl: 'Videos/E87P4.mp4' },
        { url: 'img/FriendsPlus/Page87/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page87/E5/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E5/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page87/E5/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page87/E5/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page87/E5/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E5/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E5/14.jpg' },
        { url: 'img/FriendsPlus/Page87/E5/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E5/16.jpg' },
        { url: 'img/FriendsPlus/Page87/E5/17.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page87/E5/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E5/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E5/20.jpg' },
        { url: 'img/FriendsPlus/Page87/E5/21.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page87/E5/22.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E5/23.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },



  6: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P87-E6',
    // audio: 'img/FriendsPlus/Page87/E5/Audio/Friends Plus for Vietnam G6 SB Track 2.25.mp3',
    // video: 'Videos/E87P4.mp4',
    component: TypeIn_Uderline,
    titleImage: '',
    fontSize: 24,
    height: 25,
    typeInput: true,
    exerciseKey: 'img/FriendsPlus/Page87/E6/Key/answerKey.png',
    titleQuestion: [{ num: '6', title: 'Choose the correct words in descriptions 1–7. Then match the descriptions to photos a–g and name the famous people. Compare with a partner using the key phrases.', color: '#0963ba' }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page87/E6/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/3.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page87/E6/4.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/5.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page87/E6/6.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/7.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page87/E6/8.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/9.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page87/E6/10.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/11.jpg', select: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E6/12.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/13.jpg', select: 1 },
        { url: 'img/FriendsPlus/Page87/E6/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/16.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/17.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page87/E6/18.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/19.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page87/E6/20.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/21.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page87/E6/22.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/23.jpg', select: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E6/24.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/25.jpg', select: 2 },
        { url: 'img/FriendsPlus/Page87/E6/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/27.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/28.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/29.jpg', select: 3 },
        { url: 'img/FriendsPlus/Page87/E6/30.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/31.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/32.jpg', select: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E6/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/34.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/35.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/36.jpg', select: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E6/37.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/38.jpg', select: 4 },
        { url: 'img/FriendsPlus/Page87/E6/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/40.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/41.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/42.jpg', select: 5 },
        { url: 'img/FriendsPlus/Page87/E6/43.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/44.jpg', select: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E6/45.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/46.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/47.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/48.jpg', select: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E6/49.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/50.jpg', select: 6 },
        { url: 'img/FriendsPlus/Page87/E6/51.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/52.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/53.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/54.jpg', select: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page87/E6/55.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/56.jpg', select: 7 },
        { url: 'img/FriendsPlus/Page87/E6/57.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page87/E6/58.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


}
export default json;