import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T1 from "../../components/ExcerciseTypes/TypeIn/T1";
// import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import UI from "../../components/ExcerciseTypes/UseIt";

const json = {
  1: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P13-E1",
    audio: "",
    video: "",
    component: T6,
    titleImage: "img/FriendsPlus/Page13/E1/1.jpg",
    imgSize: "60%",
    exerciseKey: "img/FriendsPlus/Page13/E1/Key/1.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Check the meanings of the bold words in examples 1–3. Then answer the questions. Compare with a partner.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;margin-left:40px'>1 Where’s </span> Neymar Junior from? # </p>" +
          "<p><span style='font-weight:600;margin-left:40px'>2 What’s </span> a taco?  # </p>" +
          "<p><span style='font-weight:600;margin-left:40px'>3 Who’s </span> Usain Bolt? # </p>",
        answer: ["Brazil", "A Mexican food", "A sports star"],
        type: "longAnwser",
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P13-E2",
    audio:
      "img/FriendsPlus/Page13/Audio/Friends Plus for Vietnam G6 SB Track 1.11.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page13/E2/Key/Key.png",
    component: T1,
    imgSize: "90%",
    titleImage: "img/FriendsPlus/Page13/E2/1.jpg",
    questionImage: [
      // Row
    ],
    questions: [
      {
        // title: 'The dictionaries are # the shelf',
        title: "#",
        answer: [
          "For the first question, the woman is wrong and the man is right. For the second question, both people are wrong.",
        ],
        type: "longAnwser",
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P13-E3",
    audio:
      "img/FriendsPlus/Page13/Audio/Friends Plus for Vietnam G6 SB Track 1.11.mp3",
    video: "",
    component: D1,
    fontSize: 24,
    height: 26,
    exerciseKey: "img/FriendsPlus/Page13/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen again and complete the Key Phrases. Use the words in the box.",
      },
    ],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page13/E3/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page13/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page13/E3/3.jpg",
          input: true,
          answer: "think",
        },
        { url: "img/FriendsPlus/Page13/E3/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page13/E3/5.jpg" },
        { url: "img/FriendsPlus/Page13/E3/6.jpg", input: true, answer: "you" },
        { url: "img/FriendsPlus/Page13/E3/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page13/E3/8.jpg" },
        { url: "img/FriendsPlus/Page13/E3/9.jpg", input: true, answer: "sure" },
        { url: "img/FriendsPlus/Page13/E3/10.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page13/E3/11.jpg" },
        {
          url: "img/FriendsPlus/Page13/E3/12.jpg",
          input: true,
          answer: "right",
        },
        { url: "img/FriendsPlus/Page13/E3/13.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page13/E3/14.jpg" },
        {
          url: "img/FriendsPlus/Page13/E3/15.jpg",
          input: true,
          answer: "know",
        },
        { url: "img/FriendsPlus/Page13/E3/16.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page13/E3/17.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P13-E4",
    audio: "",
    video: "",
    component: T1,
    exerciseKey: "img/FriendsPlus/Page13/E4/Key/Key.png",
    titleImage: "img/FriendsPlus/Page13/E4/title.jpg",
    imgSize: "90%",
    // titleQuestion: [{ num: '4', title: 'Read the email and answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p><b>1.</b> Is Beth from Spain? #. </p>" +
          "<p><b>2.</b> What are her interests? #. </p>" +
          "<p><b>3.</b> Which words in the email have got capital letters? Why? #. </p>" +
          "<p><b>4.</b> What are the meanings of the words in <b style='color:#33B1D6;'>blue</b>? #. </p></div>",
        answer: [
          "No, she's from the USA",
          "She's into music, sport and cooking",
          "Hi, My, Beth, I'm, Boston, USA, I've, Cranville High School, Boston, Boston Celtics, What, Email, English, Spanish, I, Italian, Bye, Beth. We use capital letters for the names of people, countries , teams, languages and the first word of a sentence / Hi, My, Beth, I'm, Boston, USA, I've, Cranville High School, Boston, Boston Celtics, What, Email, English, Spanish, I, Italian, Bye, Beth. We use capital letters for the names of people, cities , teams, languages and the first word of a sentence / Hi, My, Beth, I'm, Boston, USA, I've, Cranville High School, Boston, Boston Celtics, What, Email, English, Spanish, I, Italian, Bye, Beth. We use capital letters for the names of people, countries , teams, nationalities and the first word of a sentence / Hi, My, Beth, I'm, Boston, USA, I've, Cranville High School, Boston, Boston Celtics, What, Email, English, Spanish, I, Italian, Bye, Beth. We use capital letters for the names of people, cities , teams, nationalities and the first word of a sentence",
          "",
        ],
        type: "longAnwser",
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P13-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page13/E5/Key/1.png",
    inputSize: 80,
    titleImage: "",
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the sentences with <i>and</i>, <i>or</i> and <i>but</i>.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p><b>1</b> Is her name Beth # Bess? </p><p><b>2</b> I speak English # Japanese.</p><p><b>3</b> I’ve got a laptop # I haven’t got any speakers.</p><p><b>4</b> Are you from the UK # the USA? </p>",
        answer: ["or", "and", "but", "or"],
        // type: 'longAnwser'
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P13-E6",
    audio: "",
    video: "",
    component: UI,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page13/E6/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page13/E6/2.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
