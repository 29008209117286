import T1 from "../../components/ExcerciseTypes/TypeIn/T1";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "SB6-U4-P49-E3",
    audio: "",
    video: "https://cloud.softech.vn/storage/SachSo/Videos/P49E3_4.mp4",
    videoSub: "Videos/P49E3_4.vtt",
    component: T1,
    exerciseKey: "img/FriendsPlus/Page49/E3/Key/1.png",
    // titleImage: "img/FriendsPlus/Page45/E1/1.png",
    titleQuestion: [
      {
        color: "#005293",
        num: "3",
        title:
          "Watch or listen. Why does Georgia like Wednesday? What language does Ben prefer?",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> #. </p>" +
          "<p><span style='font-weight:600'>2.</span> #. </p>",
        answer: [
          "Georgia likes Wednesday because she has her two favourite subjects: maths and geography",
          "Ben prefers Spanish",
        ],
        type: "longAnwser",
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 4",
    id: "SB6-U4-P49-E4",
    audio: "",
    video: "https://cloud.softech.vn/storage/SachSo/Videos/P49E3_4.mp4",
    videoSub: "Videos/P49E3_4.vtt",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page49/E4/Key/1.png",
    titleImage: "",
    titleQuestion: [
      {
        color: "#1b6cc0",
        num: "4",
        title: "Watch or listen again and complete the key phrases.",
      },
    ],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page49/E4/3.jpg",
          input: true,
          answer: "science",
        },
        { url: "img/FriendsPlus/Page49/E4/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/6.jpg" },
        {
          url: "img/FriendsPlus/Page49/E4/7.jpg",
          input: true,
          answer: "history",
        },
        { url: "img/FriendsPlus/Page49/E4/8.jpg" },
      ],

      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/9.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/10.jpg" },
        {
          url: "img/FriendsPlus/Page49/E4/11.jpg",
          input: true,
          answer: "French",
        },
        { url: "img/FriendsPlus/Page49/E4/12.jpg" },
      ],

      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/14.jpg" },
        {
          url: "img/FriendsPlus/Page49/E4/15.jpg",
          input: true,
          answer: "French",
        },
        { url: "img/FriendsPlus/Page49/E4/16.jpg" },
      ],

      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/17.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/18.jpg" },
        {
          url: "img/FriendsPlus/Page49/E4/19.jpg",
          input: true,
          answer: "Spanish",
        },
        { url: "img/FriendsPlus/Page49/E4/20.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page49/E4/21.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 4",
    id: "SB6-U4-P49-E5",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page49/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
