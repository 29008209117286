import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import UI from '../../components/ExcerciseTypes/UseIt';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
    1: { // Exercise num
        unit: 'Unit 2',
        id: 'SB6-U2-P28-E1',
        audio: '',
        video: '',
        component: D1,
        fontSize: 18,
        exerciseKey: 'img/FriendsPlus/Page28/E1/Key/answerKey.png',
        // titleQuestion: [{ color: '#E26D28', num: '1', title: 'Compete the questionnaire with the words in the box.' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/title.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/2.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/3.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/4.jpg', input: true, answer: 'sing' },
                { url: 'img/FriendsPlus/Page28/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/6.jpg', input: true, answer: 'play' },
                { url: 'img/FriendsPlus/Page28/E1/7.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/8.jpg', input: true, answer: 'dance' },
                { url: 'img/FriendsPlus/Page28/E1/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/10.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/11.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/12.jpg', input: true, answer: 'wear' },
                { url: 'img/FriendsPlus/Page28/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/14.jpg', input: true, answer: 'make' },
                { url: 'img/FriendsPlus/Page28/E1/15.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/17.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/18.jpg', input: true, answer: 'give' },
                { url: 'img/FriendsPlus/Page28/E1/19.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/21.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/22.jpg', input: true, answer: 'go out' },
                { url: 'img/FriendsPlus/Page28/E1/23.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/25.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/26.jpg', input: true, answer: 'visit' },
                { url: 'img/FriendsPlus/Page28/E1/27.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/28.jpg', input: true, answer: 'invite' },
                { url: 'img/FriendsPlus/Page28/E1/29.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/30.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/31.jpg' },
                { url: 'img/FriendsPlus/Page28/E1/32.jpg', input: true, answer: 'have' },
                { url: 'img/FriendsPlus/Page28/E1/33.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E1/34.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 2',
        id: 'SB6-U2-P28-E2',
        audio: '',
        video: '',
        component: T2,
        titleImage: "img/FriendsPlus/Page28/E2/1.png",
        // titleQuestion: [{ num: '2', title: 'Choose the correct verbs.' }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>",
                answer: [],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit: 'Unit 2',
        id: 'SB6-U2-P28-E3',
        audio: 'img/FriendsPlus/Page28/E3/Audio/G6 SB Track 1.24.mp3',
        video: '',
        component: T1,
        titleImage: 'img/FriendsPlus/Page28/E3/1.png',
        exerciseKey: 'img/FriendsPlus/Page28/E3/Key/answerKey.png',
        titleQuestion: [{ color: '#E26D28', num: '3', title: 'Read the introduction to the radio programme. Then listen. In what order do they mention the objects in the photos?' }],
        questionImage: [],
        questions: [
            {
                title:
                    '<p>1. # </p>' +
                    '<p>2. # </p>' +
                    '<p>3. # </p>' +
                    '<p>4. # </p>',
                answer: [
                    "D",
                    "B",
                    'C',
                    "A",
                ],
                // type: 'longAnwser'
            },
        ]
    },
    4: { // Exercise num
        unit: 'Unit 2',
        id: 'SB6-U2-P28-E4',
        audio: 'img/FriendsPlus/Page28/E3/Audio/G6 SB Track 1.24.mp3',
        video: '',
        component: T1,
        titleImage: '',
        exerciseKey: 'img/FriendsPlus/Page28/E4/Key/1.png',
        stylesContent: {
            justifyContent: 'flex-start'
        },
        stylesTextInput: {
            textTransform: 'capitalize',
        },
        titleQuestion: [{ color: '#E26D28', num: '4', title: 'Listen again and write True or False.' }],
        questionImage: [],
        questions: [
            {
                title:
                    '<p>1 People give money as a present at Lunar New Year. #  </p>' +
                    '<p>2 People eat a lot before Lunar New Year. # </p>' +
                    '<p>3 People eat lots of meat at Lunar New Year. # </p>' +
                    '<p>4 The grandmother invites all the family to a big meal. # </p>' +
                    '<p>5 The lion only stops at some restaurants. # </p>',
                answer: [
                    "True",
                    "False",
                    "False",
                    'True',
                    'False'
                ],
                // type: 'longAnwser'
            },
        ]
    },
    5: { // Exercise num
        unit: 'Unit 2',
        id: 'SB6-U2-P28-E5',
        audio: '',
        video: '',
        component: UI,
        exerciseKey: '',
        //titleImage: 'http://',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E5/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page28/E5/2.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
}
export default json;