
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P77-E1',
    audio: '',
    video: 'Videos/Animation - Unit 6 grammar 2 trang 77.mp4',
    component: D1,
    fontSize: 25,
    height: 30,
    exerciseKey: 'img/FriendsPlus/Page77/E1/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page50/E15/1.png",
    titleQuestion: [{ num: '1', title: 'Look at the examples and complete Rules 1–3 with have got or haven’t got.', color: '#3CC8DF' }],
    questionImage: [ // Row      
      [
        // Column
        { url: 'img/FriendsPlus/page77/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page77/E1/2.jpg' },
        { url: 'img/FriendsPlus/page77/E1/3.jpg', input: true, answer: 'have got' },
        { url: 'img/FriendsPlus/page77/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page77/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page77/E1/6.jpg' },
        { url: 'img/FriendsPlus/page77/E1/7.jpg', input: true, answer: "haven't got" },
        { url: 'img/FriendsPlus/page77/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page77/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page77/E1/10.jpg' },
        { url: 'img/FriendsPlus/page77/E1/11.jpg', input: true, answer: 'have got' },
        { url: 'img/FriendsPlus/page77/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page77/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page77/E1/14.jpg' },
      ],

    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P77-E2',
    audio: '',
    video: '',
    component: D1,
    isHiddenCheck: true,
    height: 30,
    paddingRight: 3,
    paddingLeft: 10,
    fontSize: 25,
    exerciseKey: 'img/FriendsPlus/Page77/E2/Key/Key.png',
    // titleImage: "img/FriendsPlus/Page50/E15/1.png",
    titleQuestion: [{ num: '2', title: 'Read the Study Strategy. Then complete the <i>Young sports superstars</i> text with the past simple form of the verbs in <span style="color:#33B1D6;">blue</span>.', color: '#3CC8DF' }],
    questionImage: [ // Row      
      [
        // Column1
        { url: 'img/FriendsPlus/Page77/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page77/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/3.jpg', input: true, answer: "started" },
        { url: 'img/FriendsPlus/Page77/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page77/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/6.jpg', input: true, answer: "went" },
        { url: 'img/FriendsPlus/Page77/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page77/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page77/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/10.jpg', input: true, answer: "won" },
        { url: 'img/FriendsPlus/Page77/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page77/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/13.jpg', input: true, answer: "competed" },
        { url: 'img/FriendsPlus/Page77/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page77/E2/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page77/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/17.jpg', input: true, answer: "became" },
        { url: 'img/FriendsPlus/Page77/E2/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page77/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/20.jpg', input: true, answer: "scored" },
        { url: 'img/FriendsPlus/Page77/E2/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page77/E2/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page77/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/24.jpg', input: true, answer: "played" },
        { url: 'img/FriendsPlus/Page77/E2/25.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page77/E2/26.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page77/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/28.jpg', input: true, answer: "trained" },
        { url: 'img/FriendsPlus/Page77/E2/29.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page77/E2/30.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page77/E2/31.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/32.jpg', input: true, answer: "gave" },
        { url: 'img/FriendsPlus/Page77/E2/33.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page77/E2/34.jpg' },
      ],


    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P77-E3',
    audio: 'img/FriendsPlus/Page77/E3/Audio/G6 SB Track 2.17.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page77/E3/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page77/E3/1.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>",
        answer: [
          "'t' and 'd'",
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P77-E4',
    audio: '',
    video: '',
    component: T6,
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page77/E4/1.png",
    imgSize: '70%',
    titleQuestion: [{ num: '4', title: 'Look at the examples and answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p><span style='font-weight:600'>1</span> How do you say the words in <span style='color:#0099D4'>blue</span> in your language? # </p>" +
          "<p><span style='font-weight:600'>2</span> Do we put ago before or after a past time expression? # </p>" +
          "<p>He <span style='color:#0099D4'>first</span> competed <span style='color:#0099D4'>when he was</span> twelve.</p>" +
          "<p> She <span style='color:#0099D4'>last</span> competed in 1981. </p>" +
          "<p>He was on this programme two years <span style='color:#0099D4'>ago</span>.</p>",
        answer: [
          "",
          'after',
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P77-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page77/E5/title.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json;