import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  12: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P37-E12',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E12/Key/1.png',
    titleImage: "img/FriendsPlus/Page37/E12/1.png",
    titleQuestion: [{ color: '#3B3074', num: '12', title: 'Read the email and write the questions.' }],
    questionImage: [],
    inputSize: 400,
    questions: [
      {
        title:
          "<div style='margin-left:45px'><p>Hi Maria,</p>" +
          "<p> I’ve got a lot of questions for you! # </p>" +
          "<p> (what time / you / get up / ?) </p>" +
          "<p> I usually get up at 6 a.m. – that’s very early! </p>" +
          "<p> And 2 # (you / have /</p>" +
          "<p> a big breakfast / ?) I only have bread and orange juice.</p>" +
          "<p> what about your sister, Anna? 3 # </p>" +
          "<p> (she / help / at home / ?) My /</p>" +
          "<p> sister never helps with the housework! And /</p>" +
          "<p> # (Anna / study /</p>" +
          "<p> a lot / ?) My sister is always in the library with</p>" +
          "<p> her friends.</p>" +
          "<p> And what about your parents? 5 # </p>" +
          "<p> (when / they / get home from work / ?) My</p>" +
          "<p> parents get home at 5.30. 6 # </p>" +
          "<p> (your parents / watch TV / in the evening / ?)</p>" +
          "<p> My mum and dad love American films!</p>" +
          "<p> Those are all my questions for now!</p>" +
          "<p> See you soon,</p>" +
          "<p> Lily</p>",
        answer: [
          "What time do you get up?",
          "do you have a big breakfast?",
          "Does she help at home?",
          "does Anna study a lot?",
          "When do they get home from work?",
          "Do your parents watch TV in the evening?",
        ],
        // type: 'longAnwserInLine'
      },
    ]
  },
  13: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P37-E13',
    audio: '',
    video: '',
    component: T6,
    imgSize: '70%',
    exerciseKey: 'img/FriendsPlus/Page37/E13/Key/1.png',
    titleImage: "img/FriendsPlus/Page37/E13/1.png",
    titleQuestion: [{ color: '#3B3074', num: '13', title: 'Complete the dialogue with the phrases.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:50px'>" +
          "<p><span style='font-weight:600'>Ben </span> There’s a family fun day in town on Sunday. </p>" +
          "<p><span style='font-weight:600'>Jack </span> Oh yes. 1 # </p>" +
          "<p><span style='font-weight:600'>Ben </span> Lots of different things. Look at this. </p>" +
          "<p><span style='font-weight:600'>Jack </span> 2 # to do? </p>" +
          "<p><span style='font-weight:600'>Ben </span> Well, 3 # in the</p>" +
          "<p>cooking competition in the morning.</p>" +
          "<p><span style='font-weight:600'>Jack </span> No? But 4 # of</p>" +
          "<p>the sports quiz in the afternoon.</p>" +
          "<p><span style='font-weight:600'>Ben </span> Right, and 5 # of</p>" +
          "<p>the barbecue at 6 p.m.?</p>" +
          "<p><span style='font-weight:600'>Jack </span> Yes, OK. 6 # to</p>" +
          "<p>the barbecue. Then we can go to the</p>" +
          "<p>fireworks in the evening.</p>" +
          "<p><span style='font-weight:600'>Ben </span> OK. Great.</p></div>",
        answer: [
          "What's on/What's on?",
          "What do you want/What do you want?",
          "I'm not really interested",
          "I like the sound",
          "what about",
          "Let's go",
        ],
        type: 'longAnwserInLine'
      },
    ]
  },

  14: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P37-E14',
    audio: '',
    video: '',
    component: D1,
    fontSize: 27,
    exerciseKey: 'img/FriendsPlus/Page37/E14/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ color: '#4F3A96', num: '14', title: 'Complete the text with the words. There are five extra words.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/3.jpg' },
        { url: 'img/FriendsPlus/Page37/E14/4.jpg', input: true, answer: 'day' },
        { url: 'img/FriendsPlus/Page37/E14/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/7.jpg' },
        { url: 'img/FriendsPlus/Page37/E14/8.jpg', input: true, answer: 'streets' },
        { url: 'img/FriendsPlus/Page37/E14/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/11.jpg' },
        { url: 'img/FriendsPlus/Page37/E14/12.jpg', input: true, answer: 'play' },
        { url: 'img/FriendsPlus/Page37/E14/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/15.jpg' },
        { url: 'img/FriendsPlus/Page37/E14/16.jpg', input: true, answer: 'also' },
        { url: 'img/FriendsPlus/Page37/E14/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/18.jpg' },
      ],
      [
        // Column   
        { url: 'img/FriendsPlus/Page37/E14/19.jpg' },
        { url: 'img/FriendsPlus/Page37/E14/20.jpg', input: true, answer: 'like' },
        { url: 'img/FriendsPlus/Page37/E14/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page37/E14/22.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  15: {
    unit: 'Progress Review 1',
    id: 'SB6-PV-P37-E15',
    audio: '',
    video: '',
    component: DesignUnderLine,
    exerciseKey: 'img/FriendsPlus/Page37/E15/Key/1.png',
    totalInput: 1,
    titleQuestion: [{ color: '#4F3A96', num: '15', title: "Choose the best title for the text. Tick (✔) one box." }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page37/E15/1.jpg' },
        { url: 'img/FriendsPlus/Page37/E15/2.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page37/E15/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E15/4.jpg' },
        { url: 'img/FriendsPlus/Page37/E15/5.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page37/E15/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E15/7.jpg' },
        { url: 'img/FriendsPlus/Page37/E15/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page37/E15/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page37/E15/10.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}
export default json