import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
  // 1: { // Exercise num
  //   audio: '',
  //   video: '',
  //   component: D1,
  //   fontSize: 25,
  //   height: 25,
  //   exerciseKey: 'img/FriendsPlus/Page33/E1/Key/1.png',
  //   titleImage: 'img/FriendsPlus/Page30/E2/title.png',
  //   titleQuestion: [{ num: '1', title: 'Look at the code and write the sentences.', color: 'black' }],
  //   questionImage: [ // Row
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/1.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/2.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/3.jpg' },
  //       { url: 'img/FriendsPlus/Page33/E1/4.jpg', input: true, answer: 'I get up at six' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/5.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/6.jpg' },
  //       { url: 'img/FriendsPlus/Page33/E1/7.jpg', input: true, answer: 'I sometimes go to school on foot' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/8.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/9.jpg' },
  //       { url: 'img/FriendsPlus/Page33/E1/10.jpg', input: true, answer: "My mum doesn't watch TV" },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/11.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page33/E1/12.jpg' },
  //       { url: 'img/FriendsPlus/Page33/E1/13.jpg', input: true, answer: 'We relax after dinner' },
  //     ],
  //   ],
  //   questions: [
  //     {
  //       title: 'The dictionaries are # the shelf',
  //       answer: ['table']
  //     },
  //   ]
  // },

  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P33-E1',
    audio: '',
    video: '',
    component: T6,
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page33/E1/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page33/E1/title.jpg",
    imgSize: '100%',
    // titleQuestion: [{ num: '3', title: 'Read the information about the Vietnamese scholar Lê Quý Đôn. Write questions using the words in blue.', color: '#19beda' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p>R ZODZBH SZEV YIVZPUZHG. </p>" +
          "<p style='font-family:OneStrokeScriptStd-Bold,font-size:30px'>I always have breakfast.</p>" +
          "<p><span style='font-weight:600'>1.</span> R TVG FK ZG HRC.  #. </p>" +
          "<p><span style='font-weight:600'>2.</span> R HLNVGRNVH TL GL HXSLLO LM ULLG. #. </p>" +
          "<p><span style='font-weight:600'>3.</span> NB NFN WLVHMG DZGXS GE. #. </p>" +
          "<p><span style='font-weight:600'>4.</span> DV IVOZC ZUGVI WRMMVI. #. </p>",

        answer: [
          "I get up at six",
          "I sometimes go to school on foot",
          "My mum doesn't watch TV",
          "We relax after dinner",
        ],
        type: 'longAnwser'
      },
    ]
  },


  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P33-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page33/E2/1.png",
    // titleQuestion: [{ num: '1', title: 'Check the meanings of the words in the box. Then describe the photo using the words.', color: '#009241' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P33-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page33/E3/1.png",
    // titleQuestion: [{ num: '1', title: 'Check the meanings of the words in the box. Then describe the photo using the words.', color: '#009241' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P33-E4',
    audio: '',
    video: '',
    component: D1,
    fontSize: 17,
    exerciseKey: 'img/FriendsPlus/Page33/E4/Key/1.png',
    titleImage: 'img/FriendsPlus/Page30/E2/title.png',
    titleQuestion: [{ num: '4', title: 'Find eight verbs about celebration in the grid. Each verb in a different colour. Then match the verb to picture 1-8' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page33/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page33/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/3.jpg', input: true, answer: 'give presents' },
        { url: 'img/FriendsPlus/Page33/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/5.jpg', input: true, answer: 'have a special meal' },
        { url: 'img/FriendsPlus/Page33/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/7.jpg', input: true, answer: 'dance' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page33/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page33/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/10.jpg', input: true, answer: 'visit family' },
        { url: 'img/FriendsPlus/Page33/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/12.jpg', input: true, answer: 'play music' },
        { url: 'img/FriendsPlus/Page33/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/14.jpg', input: true, answer: 'celebrate' },
        { url: 'img/FriendsPlus/Page33/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/16.jpg', input: true, answer: 'make costumes' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P33-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page33/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;