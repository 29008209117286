import U1 from "../../components/ExcerciseTypes/UnderLine/U1";
import T1 from "../../components/ExcerciseTypes/TypeIn/T1";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import DMC from "../../components/ExcerciseTypes/Design/MultipleChoice/MC2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P27-E1",
    audio: "",
    video: "",
    component: D1,
    titleImage: "",

    exerciseKey: "img/FriendsPlus/Page27/E1/Key/1.png",
    titleQuestion: [
      {
        num: "1",
        title: "Compare the sentences. Then match a–d with Spelling Rules 1–4.",
        color: "#1EBFD9",
      },
    ],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/3.jpg" },
        { url: "img/FriendsPlus/Page27/E1/4.jpg", input: true, answer: "d" },
        { url: "img/FriendsPlus/Page27/E1/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/7.jpg" },
        { url: "img/FriendsPlus/Page27/E1/8.jpg", input: true, answer: "c" },
        { url: "img/FriendsPlus/Page27/E1/9.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/10.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/11.jpg" },
        { url: "img/FriendsPlus/Page27/E1/12.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page27/E1/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/14.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/15.jpg" },
        { url: "img/FriendsPlus/Page27/E1/16.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page27/E1/17.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page27/E1/18.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P27-E2",
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: "",
    component: U1,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page27/E2/Key/Key.png",
    titleQuestion: [{ num: "2", title: "Choose the correct verbs." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> You # /#  to bed at 9.30 p.m.</p>" +
          "<p><span style='font-weight:600'>2</span> My parents  # / # in the evening.</p>" +
          "<p> <span style='font-weight:600'>3</span> My teacher # / # four languages.</p>" +
          "<p><span style='font-weight:600'>4</span> Our English class # / #  at 3 p.m.</p>" +
          "<p><span style='font-weight:600'>5</span> I # / # lunch at home.</p>" +
          "<p><span style='font-weight:600'>6</span> My sister # / # at the weekend.</p>",

        answerString: [
          "go ",
          "goes",
          "relaxes",
          "relax",
          "speaks",
          "speak",
          "finish",
          "finishes",
          "has",
          "have",
          "studies",
          "study",
        ],
        answer: ["go", "relax", "speaks", "finishes", "have", "studies"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P27-E3",
    audio: "img/FriendsPlus/Page27/E3/Audio/G6 SB Track 1.23.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/E3/Key/answerKey.png",
    component: DMC,
    // titleQuestion: [{ num: '3', title: 'Now indicate the word whose bold part differs from the other three in pronunciation.' }],
    questionImage: [
      // Row
      [
        // Column
        { url: "http://" },
        { url: "http://", input: true },
        { url: "http://" },
      ],
    ],
    questions: [
      {
        // title: '',
        answers: [
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "relax#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "finish#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: true,
            right: "",
            text: "studi#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "box#",
            boldText: "es",
          },
        ],
        no: 1,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "go#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "do#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: true,
            right: "",
            text: "watch#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "to#",
            boldText: "es",
          },
        ],
        no: 2,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          {
            image: "",
            isCorrect: true,
            right: "",
            text: "ach#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "watch#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "beach#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "match#",
            boldText: "es",
          },
        ],
        no: 3,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "wash#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "wish#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: true,
            right: "",
            text: "mak#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "witch#",
            boldText: "es",
          },
        ],
        no: 4,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "tak#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "mak#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "ach#",
            boldText: "es",
          },
          {
            image: "",
            isCorrect: true,
            right: "",
            text: "watch#",
            boldText: "es",
          },
        ],
        no: 5,
        question: "",
        type: "",
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P27-E4",
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: "",
    component: T6,
    exerciseWidth: "50%",
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page27/E4/Key/1.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the examples from the text on page 26. Then choose the correct words in rules 1–3.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1 The younger children #.</p>" +
          "<p>2 Sue # TV a lot.</p>" +
          "<p>3 The Radfords # usually # to restaurants.</p>",
        answer: ["don't help", "doesn't watch", "don't", "go"],
        // type: 'longAnwser'
      },
    ],
  },
  "4-1": {
    unit: "Unit 2",
    id: "SB6-U2-P27-E4.1",
    audio: "",
    video: "Videos/Animation - Unit 2 grammar 1 trang 27.mp4",
    component: DesignUnderLine,
    right: -10,
    exerciseKey: "img/FriendsPlus/Page27/E4/Key/answerKey.png",
    titleQuestion: [
      { num: "4", title: "Choose the correct words in the rules 1-3." },
    ],
    totalInput: 3,
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page27/E4/01.jpg" }],
      [
        { url: "img/FriendsPlus/Page27/E4/2.jpg" },
        { url: "img/FriendsPlus/Page27/E4/3.jpg", input: 1, isCorrect: false },
        { url: "img/FriendsPlus/Page27/E4/4.jpg" },
        { url: "img/FriendsPlus/Page27/E4/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page27/E4/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page27/E4/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page27/E4/8.jpg" },
        { url: "img/FriendsPlus/Page27/E4/9.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page27/E4/10.jpg" },
        { url: "img/FriendsPlus/Page27/E4/11.jpg", input: 2, isCorrect: false },
        { url: "img/FriendsPlus/Page27/E4/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page27/E4/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page27/E4/14.jpg" },
        { url: "img/FriendsPlus/Page27/E4/15.jpg", input: 3, isCorrect: false },
        { url: "img/FriendsPlus/Page27/E4/16.jpg" },
        { url: "img/FriendsPlus/Page27/E4/17.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page27/E4/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page27/E4/19.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P27-E5",
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: "",
    component: T1,
    titleImage: "img/FriendsPlus/Page27/E5/1.png",
    imgSize: "50%",
    exerciseKey: "img/FriendsPlus/Page27/E5/Key/1.png",
    titleQuestion: [
      {
        num: "5",
        title:
          "Look at the information in the table. Write affirmative and negative sentences about the people.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-family:OneStrokeScriptStd-Bold;color:gray'>Tom and Mina wash the dishes.</p>" +
          "<p style='font-family:OneStrokeScriptStd-Bold;color:gray'>Vicky doesn’t wash the car.</p>" +
          "<p>1. # </p>" +
          "<p>2. # </p>" +
          "<p>3. # </p>" +
          "<p>4. # </p>" +
          "<p>5. # </p>" +
          "<p>6. # </p>" +
          "<p>7. # </p>" +
          "<p>8. # </p>",
        answer: [
          "Tom and Mina wash the dishes.",
          "Vicky doesn't wash the car.",
          "Tom and Vicky sit on the sofa a lot.",
          "Mina doesn't sit on the sofa a lot.",
          "Tom and Vicky don't make breakfast.",
          "Mina makes breakfast.",
          "Tom and Mina don't watch TV a lot.",
          "Vicky watches TV a lot.",
        ],
        type: "longAnwser",
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P27-E6",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page27/E6/1.jpg",
    titleQuestion: "",
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
