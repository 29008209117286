import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import U1 from "../../components/ExcerciseTypes/UnderLine/U1";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/UseIt";

const json = {
  1: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P9-E1",
    audio: "",
    video: "",
    component: D1,
    height: 25,
    exerciseKey: "img/FriendsPlus/Page9/E1/Key/1.png",
    titleImage: "",
    // titleQuestion: [{ num: '1', title: 'Look at the examples and complete the rules with singular and plural.' }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page9/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page9/E1/2.jpg" }],
      [
        { url: "img/FriendsPlus/Page9/E1/3.jpg" },
        {
          url: "img/FriendsPlus/Page9/E1/4.jpg",
          input: true,
          answer: "singular",
        },
        { url: "img/FriendsPlus/Page9/E1/5.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page9/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page9/E1/7.jpg",
          input: true,
          answer: "plural",
        },
        { url: "img/FriendsPlus/Page9/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page9/E1/9.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P9-E2",
    audio:
      "img/FriendsPlus/Page9/E2/Audio/Friends Plus for Vietnam G6 SB Track 1.07.mp3",
    video: "",
    component: U1,
    exerciseKey: "img/FriendsPlus/Page9/E2/Key/Key.png",
    titleImage: "img/FriendsPlus/Page9/E2/1.jpg",
    imgSize: "80%",
    titleImagePosition: "bottom",
    titleQuestion: [
      {
        num: "2",
        title: "Read and listen to the text. Choose the correct answers.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'>" +
          "<p>1 Charlie’s mum is # /# .</p>" +
          "<p>2 Her laptop is # / # .</p>" +
          "<p>3 Her speakers are in # / # .</p></div>",
        answerString: [
          "at home",
          "at work",
          "in the drawer",
          "on the TV",
          "Charlie’s room",
          "her room",
        ],
        answer: ["at home", "in the drawer", "Charlie’s room"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P9-E3",
    audio: "",
    video: "",
    component: D1,
    height: 22,
    exerciseKey: "img/FriendsPlus/Page9/E3/Key/answerKey.png",
    titleImage: "",
    titleQuestion: [
      { num: "3", title: "Read the dialogue again and complete the table." },
    ],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page9/E3/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page9/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page9/E3/3.jpg",
          input: true,
          answer: "i'm not",
        },
        { url: "img/FriendsPlus/Page9/E3/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page9/E3/5.jpg" },
        { url: "img/FriendsPlus/Page9/E3/6.jpg", input: true, answer: "is" },
        { url: "img/FriendsPlus/Page9/E3/7.jpg" },
        { url: "img/FriendsPlus/Page9/E3/8.jpg", input: true, answer: "is" },
        { url: "img/FriendsPlus/Page9/E3/9.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page9/E3/10.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page9/E3/11.jpg" },
        { url: "img/FriendsPlus/Page9/E3/12.jpg", input: true, answer: "are" },
        { url: "img/FriendsPlus/Page9/E3/13.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page9/E3/14.jpg" },
        {
          url: "img/FriendsPlus/Page9/E3/15.jpg",
          input: true,
          answer: "aren't",
        },
        { url: "img/FriendsPlus/Page9/E3/16.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P9-E4",
    audio: "",
    video: "",
    component: D1,
    fontSize: 22,
    height: 24,
    exerciseKey: "img/FriendsPlus/Page9/E4/Key/answerKey.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "4",
        title:
          "Match the possessive pronouns in the box with the possessive adjectives.",
      },
    ],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page9/E4/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page9/E4/2.jpg" },
        { url: "img/FriendsPlus/Page9/E4/3.jpg", input: true, answer: "his" },
        { url: "img/FriendsPlus/Page9/E4/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page9/E4/5.jpg" },
        { url: "img/FriendsPlus/Page9/E4/6.jpg", input: true, answer: "hers" },
        { url: "img/FriendsPlus/Page9/E4/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page9/E4/8.jpg" },
        { url: "img/FriendsPlus/Page9/E4/9.jpg", input: true, answer: "ours" },
        { url: "img/FriendsPlus/Page9/E4/10.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page9/E4/11.jpg" },
        {
          url: "img/FriendsPlus/Page9/E4/12.jpg",
          input: true,
          answer: "yours",
        },
        { url: "img/FriendsPlus/Page9/E4/13.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page9/E4/14.jpg" },
        {
          url: "img/FriendsPlus/Page9/E4/15.jpg",
          input: true,
          answer: "theirs",
        },
        { url: "img/FriendsPlus/Page9/E4/16.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P9-E5",
    audio: "",
    video: "",
    component: T6,
    inputSize: 220,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page9/E5/Key/1.png",
    titleQuestion: [
      { num: "5", title: " Write phrases with possessive pronouns." },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1</span> This is my pen.<b style='color:#8E8F94;'>This is mine</b>.</p>" +
          "<p><span style='font-weight:600'>2</span> This is his book.  # .</p>" +
          "<p><span style='font-weight:600'>3</span> This is her desk. # .</p>" +
          "<p><span style='font-weight:600'>4</span> This is our table # .</p>" +
          "<p><span style='font-weight:600'>5</span> This is your bag. # .</p>" +
          "<p><span style='font-weight:600'>6</span> This is their dictionary. # .</p></div>",
        answer: [
          "This is his",
          "This is hers",
          "This is ours",
          "This is yours",
          "This is theirs",
        ],
        type: "longAnwser",
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P9-E6",
    audio: "",
    video: "",
    component: UI,
    exerciseKey: "",
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page9/E6/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page9/E6/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page9/E6/3.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
