import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {


  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P74-E1',
    audio: '',
    video: '',
    component: D1,
    fontSize: 27,
    height: 29,
    exerciseKey: 'img/FriendsPlus/Page74/E1/Key/answerKey.png',
    //titleImage: 'http://',
    titleQuestion: [{ color: '#D43B41', num: '1', title: 'Read the text. What sports does it mention?' }],
    // typeInput: 'center',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page74/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page74/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page74/E1/3.jpg', input: true, answer: "swimming/marathon/running/golf/rugby" },
        { url: 'img/FriendsPlus/Page74/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page74/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page74/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page74/E1/7.jpg', input: true, answer: "swimming/marathon/running/golf/rugby" },
        { url: 'img/FriendsPlus/Page74/E1/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page74/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page74/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page74/E1/11.jpg', input: true, answer: "swimming/marathon/running/golf/rugby" },
        { url: 'img/FriendsPlus/Page74/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page74/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page74/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page74/E1/15.jpg', input: true, answer: "swimming/marathon/running/golf/rugby" },
        { url: 'img/FriendsPlus/Page74/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page74/E1/17.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P74-E2',
    audio: 'img/FriendsPlus/Page74/E2/Audio/G6 SB Track 2.14.mp3',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page74/E2/Key/answerKey.png",
    exerciseWidth: '45%',
    stylesTextInput: {
      textTransform: 'capitalize'
    },
    titleQuestion: [{ color: '#D43B41', num: '2', title: 'Read and listen to the text and write True or False.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. There were gold medals in the first Olympic Games. # </p>" +
          "<p>2. There weren’t any games in 1916. # </p>" +
          "<p>3. Inge Sorensen was a swimmer from Ethiopia. # </p>" +
          "<p>4. Abebe Bikila was a marathon runner. # </p>" +
          "<p>5. Golf and rugby were Olympic sports for the first time in 2012. # </p>",
        answer: [
          'False',
          'True',
          'False',
          'True',
          'False'
        ],
        // type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P74-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page74/E3/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P74-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page74/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;