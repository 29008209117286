/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import styles from './styles.module.css';
import { Row, Input, } from 'reactstrap';
import 'video-react/dist/video-react.css';
import ReactHtmlParser from 'react-html-parser';
import TitleQuestion from '../../../TitleQuestion';
import FooterIeltsMindset from '../../../FooterModal';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Player, BigPlayButton, ControlBar, ClosedCaptionButton, ReplayControl, ForwardControl, CurrentTimeDisplay } from 'video-react';

const TypeIn6 = (props) => {

  const inputTag = '#';

  const FormItem = Form.Item;

  const submitButton = React.useRef();

  const refForm = React.useRef();

  const inputCount = React.useRef(0);

  const [form] = Form.useForm();

  const [state, setState] = React.useState({
    audioUrl: null,
    videoUrl: null,
    sentences: null,
    isPointed: false,
    studentAnswer: null,
    videoVisible: false,
    isDisabledRetry: true,
    isDisabledSubmit: true,
  });

  React.useEffect(() => {
    const sentences = JSON.parse(JSON.stringify(props.questions));
    sentences.titleImage = props.titleImage
    sentences.titleQuestion = props.titleQuestion
    const audioUrl = props.audio
    const videoUrl = props.video
    const maxInput = sentences[0].answer.length
    const inputArray = new Array(maxInput).fill()

    setState((prevState) => ({ ...prevState, sentences, audioUrl, videoUrl, inputArray, maxInput }))
  }, [props])


  const toggleState = React.useCallback((fieldName) => () => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  }, []);

  const onPlayVideo = React.useCallback(() => {
    toggleState('videoVisible')();
  }, [toggleState])


  const onSubmit = React.useCallback(() => {
    submitButton.current?.click();
    setState(pre => ({ ...pre, isDisabledSubmit: true }))
  }, [])

  const onRetry = React.useCallback(() => {
    form.resetFields();
    state.sentences.booleanArray = null;
    setState((preState) => ({ ...preState, isDisabledInput: false, isDisabledRetry: true, isPointed: false, resultString: null, isDisabledSubmit: true, inputArray: new Array(state.maxInput).fill() }));
  }, [form, state.maxInput, state.sentences])

  // Khi hoàn thành các field
  const onFinish = React.useCallback((value) => {
    const regex = props.regex || /(\W+)$/g
    let booleanArray = []
    let studentAnswer = []
    state.sentences[0].answer.forEach((item, index) => {
      let isCorrect = false;
      const isManyAnswers = item.includes('/');
      if (isManyAnswers && value[index]) {
        const answers = item.split('/');
        for (let i = 0; i < answers.length; i++) {
          // isCorrect = answers[i].trim().toLowerCase() === value[index].trim().toLowerCase();
          isCorrect = answers[i].trim().toLowerCase().replace(regex, '') === value[index].trim().toLowerCase().replace(regex, '');
          if (isCorrect) break;
        }
      } else {
        if (!item) {
          isCorrect = true;
        }
        else {
          if (value[index]) {
            // isCorrect = item.trim().toLowerCase() === value[index].trim().toLowerCase()
            isCorrect = item.trim().toLowerCase().replace(regex, '') === value[index].trim().toLowerCase().replace(regex, '')
          }
        }
      }
      studentAnswer.push(value[index])
      booleanArray.push(isCorrect)
    })
    state.sentences.booleanArray = booleanArray

    let correctAnswer = 0;
    booleanArray.forEach((item, index) => {
      if (item) {
        correctAnswer++
      }
    })
    const percent = parseInt(correctAnswer / booleanArray.length * 100)
    const resultString = `${correctAnswer}/${booleanArray.length} (${percent}%)`
    const result_String = `${correctAnswer}/${booleanArray.length}`

    const params = {
      score: percent,
      exerciseId: props.id,
      studentAnswer: JSON.stringify(studentAnswer),
      results: result_String,
      Unit: props.unit,
    }
    props.postAnswerToApi(params)
    setState((preState) => ({
      ...preState,
      resultString,
      isPointed: true,
      isDisabledRetry: false,
      sentences: state.sentences,
    }))
  }, [props, state.sentences])

  const onChangeText = React.useCallback((inputIndex) => (e) => {
    const text = e.target.value;
    let emptyInputNum = 0;
    state.inputArray[inputIndex] = text
    state.inputArray.forEach((item, index) => {
      if (item) {
        emptyInputNum++
      }
    })

    if (emptyInputNum === state.maxInput) {
      setState((prevState) => ({ ...prevState, isDisabledSubmit: false }))
    }
  }, [state.inputArray, state.maxInput])


  const transform = React.useCallback((node, index) => {

    if (node.type === 'text') {

      if (!node.data.includes(inputTag)) return;

      const elementArray = node.data.split(inputTag);

      let currentInputNo = 0;

      return (
        <span key={index} style={{ fontSize: 25 }}>
          {elementArray.map((item, index) => {
            if (index > 0) {

              currentInputNo = inputCount.current;

              const maxInput = state.sentences[0].answer.length

              inputCount.current++;

              if (inputCount.current >= maxInput) {
                inputCount.current = 0;
              }
            }
            let widthInput = 150;
            const type = state.sentences[0].type

            if (type === 'longAnwser') {
              widthInput = 300
            }
            if (type === 'longAnwserInLine') {
              widthInput = 750
            }

            if (props.inputSize) {
              widthInput = props.inputSize
            }

            return (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <FormItem
                    className='ml-2 mr-2'
                    name={currentInputNo}
                    style={{ display: 'inline-block', marginBottom: 0 }}
                    rules={[{ required: true, message: 'Please fill the answer' },]}
                  >
                    <div>
                      <Input
                        maxLength={props.textCenter ? 1 : ''}
                        autoComplete='off'
                        autoCapitalize
                        onChange={onChangeText(currentInputNo)}

                        style={{
                          height: 30,
                          fontSize: 26,
                          borderWidth: 0,
                          borderRadius: 0,
                          fontWeight: '500',
                          display: 'inline',
                          borderBottomWidth: 1,
                          width: widthInput,
                          borderStyle: 'dotted',
                          backgroundColor: 'white',
                          borderBottomColor: '#bdc3c7',
                          boxShadow: 'none',
                          textTransform: props.textCenter ? 'capitalize' : '',
                          textAlign: props.textCenter ? 'center' : '',
                          color: state.isPointed ? (state.sentences.booleanArray?.[currentInputNo] ? '#2ecc71' : '#e74c3c') : 'black',
                          ...props.stylesTextInput
                        }}
                        id={currentInputNo}
                        disabled={state.isPointed}
                        className={!state.isPointed ? styles.input : styles.checkInput}
                      />
                      {state.isPointed && state.sentences.booleanArray?.[currentInputNo] && (
                        <CheckCircleOutlined style={{ fontSize: 25, color: '#2ecc71' }} />
                      )}
                      {state.isPointed && !state.sentences.booleanArray?.[currentInputNo] && (
                        <CloseCircleOutlined style={{ fontSize: 25, color: '#e74c3c' }} />
                      )}
                    </div>
                  </FormItem>
                )}
                {item}
              </React.Fragment>
            )
          })}
        </span>
      )
    }
  }, [state.sentences, state.isPointed, props.inputSize, props.textCenter, props.stylesTextInput, onChangeText])

  if (!state.sentences) return null;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div style={{ width: props.exerciseWidth ?? '45%' }}>
        <Row>
          <TitleQuestion titleQuestion={state.sentences.titleQuestion} />
        </Row>
        <Row className='d-flex justify-content-center mb-3'>
          {state.videoUrl && (
            <Player>
              <BigPlayButton position="center" />
              <source
                src={props.video}
                type="video/mp4"
                default

              />

              <track
                kind="captions"
                src={props.videoSub}
                srcLang="en"
                label="English"
              />
              <ControlBar autoHide={false}>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={10} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <ClosedCaptionButton order={7} />
              </ControlBar>
            </Player>
          )}
          {state.sentences.titleImage && (
            <img
              alt='...'
              style={{
                width: props?.imgSize ?? '100%',
                marginBottom: 10
              }}
              src={state.sentences.titleImage}
            />
          )}
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>

          <Form
            autoComplete="off"
            form={form}
            ref={refForm}
            onFinish={onFinish}
          >
            {ReactHtmlParser(state.sentences[0].title, { transform })}
            <FormItem>
              <Button
                style={{
                  display: 'none'
                }}
                ref={submitButton}
                id='submitButton'
                htmlType="submit" />
            </FormItem>
          </Form>
        </Row>
      </div>

      <FooterIeltsMindset
        onRetry={onRetry}
        onSubmit={onSubmit}
        onPlayVideo={onPlayVideo}
        audioUrl={state.audioUrl}
        exerciseKey={props.exerciseKey}
        isDisabledSubmit={state.isDisabledSubmit}
        resultStringProps={state.resultString}
        isDisabledRetry={state.isDisabledRetry}
        hideBtnFooter={state.maxInput === 0}  //not answer
      />
    </div>
  );
};
TypeIn6.propTypes = {
  question: PropTypes.instanceOf(Object),

}
export default React.memo(TypeIn6);
