import T1 from "../../components/ExcerciseTypes/TypeIn/T1";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P99-E1",
    audio: "",
    video: "Videos/Animation - Unit 8 grammar 1 trang 99.mp4",
    component: T1,
    exerciseKey: "img/FriendsPlus/Page99/E1/Key/answerKey.png",
    titleImage: "img/FriendsPlus/Page99/E1/title.jpg",
    imgSize: "100%",
    // imgSize: '100%',
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the text on page 98 again and complete the examples. Then choose the correct words in rules 1–2.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> We’re # travel by train.</p>" +
          "<p><span style='font-weight:600'>2</span> I # going away </p>" +
          "<p><span style='font-weight:600'>3</span> What # you going to do this summer?</p>",
        answer: ["going to", "'m not/am not", "are"],
        // type: 'longAnwser'
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P99-E2",
    audio: "",
    video: "",
    component: T6,
    // titleImage: "img/FriendsPlus/Page100/E1/1.png",
    // imgSize: '100%',
    // inputSize: 350,
    inputSize: 280,
    exerciseKey: "img/FriendsPlus/Page99/E2/Key/1.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences with the affirmative or negative form of <i>be going to</i> and the verbs in brackets.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p> I<span style='font-family:OneStrokeScriptStd-Bold;color:gray'>’m going to see</span> (see) a film at the cinema. ✓ </p>" +
          "<p> They<span style='font-family:OneStrokeScriptStd-Bold;color:gray'> aren’t going to go</span> (go) to school tomorrow. ✗ </p>" +
          "<p><span style='font-weight:600'>1</span> You # (visit) your grandparents tomorrow. ✓</p>" +
          "<p><span style='font-weight:600'>2</span> They # (travel) by train. ✗</p>" +
          "<p><span style='font-weight:600'>3</span> Lily # (buy) a guidebook for Hà Nội. ✓</p>" +
          "<p><span style='font-weight:600'>4</span> I # (take) my phone or my tablet. ✗</p>" +
          "<p><span style='font-weight:600'>5</span> We # (stay) in a modern hotel. ✓</p>" +
          "<p><span style='font-weight:600'>6</span> Pavel # (study) Vietnamese next year. ✗</p></div>",
        answer: [
          "'re going to visit/are going to visit",
          "aren't going to travel/are not going to travel",
          "'s going to buy/is going to buy",
          "'m not going to take/am not going to take",
          "'re going to stay/are going to stay",
          "isn't going to study/is not going to study",
        ],
        // type: 'longAnwser'
      },
    ],
  },

  3: {
    unit: "Unit 8",
    id: "SB6-U8-P99-E3",
    audio:
      "img/FriendsPlus/Page99/E3/Audio/Friends Plus for Vietnam G6 SB Track 2.34.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page99/E3/Key/1.png",
    component: DesignUnderLine,
    totalInput: 4,
    // titleQuestion: [{ num: '1', title: `Work in pairs. Answer questions 1–6 in the quiz.`, color: '#E26D28' }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page99/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page99/E3/2.jpg" },
        { url: "img/FriendsPlus/Page99/E3/3.jpg", input: 1, isCorrect: false },
        { url: "img/FriendsPlus/Page99/E3/4.jpg", input: 2, isCorrect: false },
        { url: "img/FriendsPlus/Page99/E3/5.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page99/E3/6.jpg", input: 4, isCorrect: false },
        { url: "img/FriendsPlus/Page99/E3/7.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page99/E3/8.jpg", input: 6, isCorrect: false },
        { url: "img/FriendsPlus/Page99/E3/9.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page99/E3/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page99/E3/11.jpg" },
        { url: "img/FriendsPlus/Page99/E3/12.jpg", input: 8, isCorrect: true },
        { url: "img/FriendsPlus/Page99/E3/13.jpg", input: 9, isCorrect: false },
        {
          url: "img/FriendsPlus/Page99/E3/14.jpg",
          input: 10,
          isCorrect: false,
        },
        {
          url: "img/FriendsPlus/Page99/E3/15.jpg",
          input: 11,
          isCorrect: false,
        },
        {
          url: "img/FriendsPlus/Page99/E3/16.jpg",
          input: 12,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page99/E3/17.jpg", input: 13, isCorrect: true },
        { url: "img/FriendsPlus/Page99/E3/18.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page99/E3/19.jpg" },
        { url: "img/FriendsPlus/Page99/E3/20.jpg", input: 14, isCorrect: true },
        {
          url: "img/FriendsPlus/Page99/E3/21.jpg",
          input: 15,
          isCorrect: false,
        },
        {
          url: "img/FriendsPlus/Page99/E3/22.jpg",
          input: 16,
          isCorrect: false,
        },
        {
          url: "img/FriendsPlus/Page99/E3/23.jpg",
          input: 17,
          isCorrect: false,
        },
        {
          url: "img/FriendsPlus/Page99/E3/24.jpg",
          input: 18,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page99/E3/25.jpg", input: 19, isCorrect: true },
        { url: "img/FriendsPlus/Page99/E3/26.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page99/E3/27.jpg" },
        {
          url: "img/FriendsPlus/Page99/E3/28.jpg",
          input: 20,
          isCorrect: false,
        },
        {
          url: "img/FriendsPlus/Page99/E3/29.jpg",
          input: 21,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page99/E3/30.jpg", input: 22, isCorrect: true },
        {
          url: "img/FriendsPlus/Page99/E3/31.jpg",
          input: 23,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page99/E3/32.jpg", input: 24, isCorrect: true },
        {
          url: "img/FriendsPlus/Page99/E3/33.jpg",
          input: 25,
          isCorrect: false,
        },
        {
          url: "img/FriendsPlus/Page99/E3/34.jpg",
          input: 26,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page99/E3/35.jpg", input: 27, isCorrect: true },
        { url: "img/FriendsPlus/Page99/E3/36.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page99/E3/37.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P99-E4",
    audio: "",
    video: "Videos/Animation - Unit 8 grammar 1 trang 99.mp4",
    fontSize: 22,
    inputHeight: "100%",
    exerciseKey: "img/FriendsPlus/Page99/E4/Key/answerKey.png",
    component: D1,
    //titleImage: '',
    titleQuestion: [
      { num: "4", title: "Look at the examples and complete Rules 1–4." },
    ],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page99/E4/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page99/E4/2.jpg" },
        { url: "img/FriendsPlus/Page99/E4/3.jpg", input: true, answer: "will" },
        { url: "img/FriendsPlus/Page99/E4/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page99/E4/5.jpg" },
        { url: "img/FriendsPlus/Page99/E4/6.jpg", input: true, answer: "'ll" },
        { url: "img/FriendsPlus/Page99/E4/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page99/E4/8.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page99/E4/9.jpg" },
        {
          url: "img/FriendsPlus/Page99/E4/10.jpg",
          input: true,
          answer: "won't",
        },
        { url: "img/FriendsPlus/Page99/E4/11.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page99/E4/12.jpg" },
        {
          url: "img/FriendsPlus/Page99/E4/13.jpg",
          input: true,
          answer: "will",
        },
        { url: "img/FriendsPlus/Page99/E4/14.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page99/E4/15.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page99/E4/16.jpg" },
        {
          url: "img/FriendsPlus/Page99/E4/17.jpg",
          input: true,
          answer: "will",
        },
        { url: "img/FriendsPlus/Page99/E4/18.jpg" },
        {
          url: "img/FriendsPlus/Page99/E4/19.jpg",
          input: true,
          answer: "won't",
        },
        { url: "img/FriendsPlus/Page99/E4/20.jpg" },
      ],
      [
        // Column9
        { url: "img/FriendsPlus/Page99/E4/21.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  5: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P99-E5",
    audio: "",
    video: "",
    component: T1,
    // titleImage: "img/FriendsPlus/Page100/E1/1.png",
    // imgSize: '100%',
    // inputSize:350,
    exerciseKey: "img/FriendsPlus/Page99/E5/Key/answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title:
          "Nick and his family are going to move from London to Australia. Write questions and answers about his future.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p> Nick / like / Australia? ✓ </p>" +
          "<p style='font-family:OneStrokeScriptStd-Bold;color:gray'>Will Nick like Australia? Yes, he will.</p>" +
          "<p><span style='font-weight:600'>1</span> Nick’s family / live / in a big house? ✗ #.</p>" +
          "<p><span style='font-weight:600'>2</span> Nick / make / many new friends? ✓ #.</p>" +
          "<p><span style='font-weight:600'>3</span> Nick / learn / a new language? ✗ #. </p>" +
          "<p><span style='font-weight:600'>4</span> be / hot and sunny / in December? ✓ #.</p>" +
          "<p><span style='font-weight:600'>5</span> Nick’s family / have a barbecue on the beach / in January? ✓ #.</p>" +
          "<p><span style='font-weight:600'>6</span> Nick’s friends in London / visit / him next summer? ✗ #.</p>",
        answer: [
          "Will Nick's family live in a big house? No, they won't",
          "Will Nick make many new friends? Yes, he will",
          "Will Nick learn a new language? No, he won't",
          "Will it be hot and sunny in December? Yes, it will",
          "Will Nick's family have a barbecue on the beach in January? Yes, they will",
          "Will Nick's friends in London visit him next summer? No, they won't",
        ],
        type: "longAnwser",
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P99-E6",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page99/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
