import T3 from '../../components/ExcerciseTypes/TypeIn/T3';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import WordBox from '../../components/ExcerciseTypes/Design/WordBox';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';

const json = {
  1: { // Exercise num
    unit : 'Option Song 5',
    id : 'SB6-OPS5-P120-E1',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T3,
    titleImage: 'img/FriendsPlus/Page120/E1/1.png',
    imgSize: '60%',
    // inputSize: 300,
    exerciseKey: 'img/FriendsPlus/Page120/E1/Key/answerKey.png',
    titleQuestion: [{ color: '#BF2A32', num: '1', title: 'Look at the picture. What different types of food can you see?' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          "<p style='font-weight:600'>1 #  </p>" +
          "<p style='font-weight:600'>2 #  </p>" +
          "<p style='font-weight:600'>3 #  </p>" +
          "<p style='font-weight:600'>4 #  </p>" +
          "<p style='font-weight:600'>5 #  </p>" +
          "<p style='font-weight:600'>6 #  </p>" +
          "<p style='font-weight:600'>7 #  </p>" +
          "<p style='font-weight:600'>8 #  </p>" +
          "<p style='font-weight:600'>9 #  </p>",
        answer: [
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",
          "bread/sandwich/chips/juice/beans/meat/eggs/salad/pasta",

        ],
        // type: 'longAnwser'

      },
    ]
  },
  // 2: { // Exercise num
  unit : 'Option Song 5',
  //   // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
  //   video: '',
  //   component: T3,
  //   titleImage: 'img/FriendsPlus/Page120/E2/1.png',
  //   imgSize: '60%',
  //   inputSize: 60,
  //   // exerciseKey: 'img/FriendsPlus/Page120/E2/Key/1.png',
  //   titleQuestion: [{ color: '#BF2A32', num: '2', title: 'Listen to the song. Put the verses in the correct order.' }],
  //   questionImage: [

  //   ],
  //   questions: [
  //     {
  //       title:
  //         "<p style='font-weight:600'>1 #  </p>" +
  //         "<p style='font-weight:600'>2 #  </p>" +
  //         "<p style='font-weight:600'>3 #  </p>" +
  //         "<p style='font-weight:600'>4 #  </p>" +
  //         "<p style='font-weight:600'>5 #  </p>" +
  //         "<p style='font-weight:600'>6 #  </p>" ,
  //       answer: [
  //         'e','c','a','f','b','d'

  //       ],
  //       // type: 'longAnwser'

  //     },
  //   ]
  // },


  2: { // Exercise num
    unit : 'Option Song 5',
    id : 'SB6-OPS5-P120-E2',
    audio: 'img/FriendsPlus/Page120/E2/Audio/Friends Plus for Vietnam G6 SB Track 3.23.mp3',
    video: '',
    fontSize: 30,
    height: 52,
    paddingLeft: 10,
    paddingTop: -1,
    padding: -1,
    component: WordBox,
    typeInput: true,
    exerciseKey: 'img/FriendsPlus/Page120/E2/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '2', title: 'Listen to the dialogue and write the correct answers.', color: '#f67f24' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page120/E2/3.jpg', input: true, answer: '3' },
        { url: 'img/FriendsPlus/Page120/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page120/E2/7.jpg', input: true, answer: '5' },
        { url: 'img/FriendsPlus/Page120/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page120/E2/11.jpg', input: true, answer: '2' },
        { url: 'img/FriendsPlus/Page120/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page120/E2/15.jpg', input: true, answer: '6' },
        { url: 'img/FriendsPlus/Page120/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page120/E2/19.jpg', input: true, answer: '1' },
        { url: 'img/FriendsPlus/Page120/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page120/E2/23.jpg', input: true, answer: '4' },
        { url: 'img/FriendsPlus/Page120/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page120/E2/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit : 'Option Song 5',
    id : 'SB6-OPS5-P120-E3',
    audio: 'img/FriendsPlus/Page120/E3/Audio/G6 SB Track 3.23.mp3',
    video: '',
    component: T6,
    // titleImage: 'img/FriendsPlus/Page120/E1/1.png',
    //   imgSize: '60%',
    // inputSize: 300,
    exerciseKey: 'img/FriendsPlus/Page120/E3/Key/answerKey.png',
    titleQuestion: [{ color: '#BF2A32', num: '3', title: 'Listen to the song again. Which foods in exercise 1 are mentioned in the song?' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          "<p style='font-weight:600'>1 #  </p>" +
          "<p style='font-weight:600'>2 #  </p>" +
          "<p style='font-weight:600'>3 #  </p>" +
          "<p style='font-weight:600'>4 #  </p>" +
          "<p style='font-weight:600'>5 #  </p>" +
          "<p style='font-weight:600'>6 #  </p>",
        answer: [
          'sandwich/juice/meat/eggs/salad/pasta',
          'sandwich/juice/meat/eggs/salad/pasta',
          'sandwich/juice/meat/eggs/salad/pasta',
          'sandwich/juice/meat/eggs/salad/pasta',
          'sandwich/juice/meat/eggs/salad/pasta',
          'sandwich/juice/meat/eggs/salad/pasta',
        ],
        type: 'longAnwser'

      },
    ]
  },
  4: { // Exercise num
    unit : 'Option Song 5',
    id : 'SB6-OPS5-P120-E4',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T6,
    // titleImage: 'img/FriendsPlus/Page120/E1/1.png',
    //   imgSize: '60%',
    // inputSize: 300,
    exerciseKey: 'img/FriendsPlus/Page120/E4/Key/answerKey.png',
    titleQuestion: [{ color: '#BF2A32', num: '4', title: 'Are the sentences about the singer True or False? Correct the false sentences.' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> She’s normally lazy and unhealthy. #  </p>" +
          "<p><span style='font-weight:600'>2</span> She eats eggs every day.#  </p>" +
          "<p><span style='font-weight:600'>3</span> She can cook. #  </p>" +
          "<p><span style='font-weight:600'>4</span> She eats a lot of meat. #  </p>" +
          "<p><span style='font-weight:600'>5</span> She doesn’t like running and swimming.#  </p>",
        answer: [
          'False', 'True', 'True', 'False', 'False'
        ],
        type: 'longAnwser'

      },
    ]
  },
  5: { // Exercise num
    unit : 'Option Song 5',
    id : 'SB6-OPS5-P120-E5',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T6,
    titleImage: 'img/FriendsPlus/Page120/E5/1.png',
    imgSize: '60%',
    // inputSize: 300,
    exerciseKey: 'img/FriendsPlus/Page120/E5/Key/answerKey.png',
    titleQuestion: [{ color: '#BF2A32', num: '5', title: 'Choose the correct words in the boxfor sentences 1–6.' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> You drink this. It’s fruity. #  </p>" +
          "<p><span style='font-weight:600'>2</span> A kind of food, for example, spaghetti and lasagne #  </p>" +
          "<p><span style='font-weight:600'>3</span> The first meal of the day #  </p>" +
          "<p><span style='font-weight:600'>4</span> A word that means the same as delicious #  </p>" +
          "<p><span style='font-weight:600'>5</span> Something small to eat between meals #  </p>" +
          "<p><span style='font-weight:600'>6</span> Food that is good for you is ... . #  </p>",
        answer: [
          'juice', 'pasta', 'breakfast', 'tasty', 'snack', 'healthy'
        ],
        type: 'longAnwser'

      },
    ]
  },
  6: { // Exercise num
    unit : 'Option Song 5',
    id : 'SB6-OPS5-P120-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page120/E6/1.png",
    imgSize: '70%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json