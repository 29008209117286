import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MC1 from '../../components/ExcerciseTypes/MultipleChoice/MC1';


const json = {
    13: { // Exercise num
        unit : 'Progress Review 4',
        id : 'SB6-PV4-P109-E13',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page109/E13/Key/1.png',
        titleImage: '',
        titleQuestion: [{ num: '13', title: 'Complete the first conditional sentences with the correct form of the verbs.', color: '#4f3e9d' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/2.jpg' },
                { url: 'img/FriendsPlus/Page109/E13/3.jpg', input: true, answer: 'see' },
                { url: 'img/FriendsPlus/Page109/E13/4.jpg' },
                { url: 'img/FriendsPlus/Page109/E13/5.jpg', input: true, answer: "'ll invite" },
                { url: 'img/FriendsPlus/Page109/E13/6.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/7.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/8.jpg' },
                { url: 'img/FriendsPlus/Page109/E13/9.jpg', input: true, answer: "won't be" },
                { url: 'img/FriendsPlus/Page109/E13/10.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/11.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/12.jpg' },
                { url: 'img/FriendsPlus/Page109/E13/13.jpg', input: true, answer: 'are' },
                { url: 'img/FriendsPlus/Page109/E13/14.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/15.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/16.jpg' },
                { url: 'img/FriendsPlus/Page109/E13/17.jpg', input: true, answer: 'has' },
                { url: 'img/FriendsPlus/Page109/E13/18.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/19.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/20.jpg' },
                { url: 'img/FriendsPlus/Page109/E13/21.jpg', input: true, answer: "'ll come" },
                { url: 'img/FriendsPlus/Page109/E13/22.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/23.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/24.jpg' },
                { url: 'img/FriendsPlus/Page109/E13/25.jpg', input: true, answer: 'will you do' },
                { url: 'img/FriendsPlus/Page109/E13/26.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/27.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E13/28.jpg' },
                { url: 'img/FriendsPlus/Page109/E13/29.jpg', input: true, answer: 'miss' },
                { url: 'img/FriendsPlus/Page109/E13/30.jpg' },
            ],
            [
                // Column
                //{ url: 'img/FriendsPlus/Page109/E13/31.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    14: { // Exercise num
        unit : 'Progress Review 4',
        id : 'SB6-PV4-P109-E14',
        audio: '',
        video: '',
        component: MC1,
        exerciseKey: 'img/FriendsPlus/Page109/E14/Key/answerKey.png',
        titleQuestion: [{ num: '14', title: 'Choose the correct words and write them on the lines.', color: '#3B3074' }],
        titleImage: 'img/FriendsPlus/Page109/E14/1.jpg',
        questionImage: [ // Row
            [
                // Column
                { url: 'http://' },
                { url: 'http://', input: true },
                { url: 'http://' },
            ],
        ],
        questions: [
            {
                // title: '',
                answers: [
                    { image: "", isCorrect: false, right: "", text: "can’t" },
                    { image: "", isCorrect: false, right: "", text: "won’t" },
                    { image: "", isCorrect: true, right: "", text: "can" },
                ],
                no: 1,
                question: '',
                type: '',
            },
            {
                // title: '',
                answers: [
                    { image: "", isCorrect: false, right: "", text: "have" },
                    { image: "", isCorrect: true, right: "", text: "make" },
                    { image: "", isCorrect: false, right: "", text: "carry" },
                ],
                no: 2,
                question: '',
                type: '',
            },
            {
                // title: '',
                answers: [
                    { image: "", isCorrect: true, right: "", text: "’ll ask" },
                    { image: "", isCorrect: false, right: "", text: "ask" },
                    { image: "", isCorrect: false, right: "", text: "asked" },
                ],
                no: 3,
                question: '',
                type: '',
            },
            {
                // title: '',
                answers: [
                    { image: "", isCorrect: false, right: "", text: "listen" },
                    { image: "", isCorrect: true, right: "", text: "see" },
                    { image: "", isCorrect: false, right: "", text: "know" },
                ],
                no: 4,
                question: '',
                type: '',
            },
            {
                // title: '',
                answers: [
                    { image: "", isCorrect: false, right: "", text: "move" },
                    { image: "", isCorrect: false, right: "", text: "visit" },
                    { image: "", isCorrect: true, right: "", text: "drive" },
                ],
                no: 5,
                question: '',
                type: '',
            },
            {
                // title: '',
                answers: [
                    { image: "", isCorrect: false, right: "", text: "be" },
                    { image: "", isCorrect: true, right: "", text: "’ll be" },
                    { image: "", isCorrect: false, right: "", text: "’m" },
                ],
                no: 6,
                question: '',
                type: '',
            },
        ],
    },
    15: { // Exercise num
        unit : 'Progress Review 4',
        id : 'SB6-PV4-P109-E15',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page109/E15/Key/answerKey.png',
        titleImage: '',
        fontSize: 25,
        height: 27,
        titleQuestion: [{ num: '15', title: 'Read the postcard and complete the text. Write one word on each line.', color: '#4f3e9d' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/1.jpg' },

            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/2.jpg' },
                { url: 'img/FriendsPlus/Page109/E15/3.jpg', input: true, answer: "won't/doesn't" },
                { url: 'img/FriendsPlus/Page109/E15/4.jpg' },

            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/5.jpg' },
                { url: 'img/FriendsPlus/Page109/E15/6.jpg', input: true, answer: 'shopping' },
                { url: 'img/FriendsPlus/Page109/E15/7.jpg' },

            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/8.jpg' },

            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/9.jpg' },
                { url: 'img/FriendsPlus/Page109/E15/10.jpg', input: true, answer: 'weather' },
                { url: 'img/FriendsPlus/Page109/E15/11.jpg' },

            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/12.jpg' },
                { url: 'img/FriendsPlus/Page109/E15/13.jpg', input: true, answer: 'going' },
                { url: 'img/FriendsPlus/Page109/E15/14.jpg' },

            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/15.jpg' },

            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/16.jpg' },
                { url: 'img/FriendsPlus/Page109/E15/17.jpg', input: true, answer: 'Hope' },
                { url: 'img/FriendsPlus/Page109/E15/18.jpg' },

            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page109/E15/19.jpg' },

            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
}
export default json