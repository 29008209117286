// import M1 from '../../components/Exercises/Matching/M1';
import MCTI1 from '../../components/ExcerciseTypes/MultipleChoice&TypeIn/MC&TI1';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import UI from '../../components/ExcerciseTypes/UseIt';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1'

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P17-E1',
    audio: '',
    video: '',
    component: MCTI1,
    exerciseKey: 'img/FriendsPlus/Page17/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Complete the questions. Then choose the correct answers.' }],
    titleImage: '',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: `# there a school on the ship?`,
        title1: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Yes, there is." },
          { image: "", isCorrect: true, right: "", text: "No, there isn't." },
        ],
        titleAnswer: 'Is',
        no: 1,
        question: '',
        type: '',
      },
      {
        title: `# there any swimming pools?`,
        title1: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "Yes, there are." },
          { image: "", isCorrect: false, right: "", text: "No, there aren't." },
        ],
        titleAnswer: 'Are',
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'How many cafés and restaurants # there on the ship?',
        title1: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Yes, there are." },
          { image: "", isCorrect: true, right: "", text: "There are twenty." },

        ],
        titleAnswer: 'are',
        no: 3,
        question: '',
        type: '',
      },
    ]
  },
  2: {
    unit: 'Unit 1',
    id: 'SB6-U1-P17-E2',
    audio: '',
    video: 'Videos/Animation - Unit 1 grammar 1 trang 17.mp4',
    component: DesignUnderLine,
    exerciseKey: 'img/FriendsPlus/Page17/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: "Choose the correct words in the Rules." }],
    totalInput: 4,
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page17/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/3.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page17/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/5.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page17/E2/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/9.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page17/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/11.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page17/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/15.jpg', input: 3, isCorrect: false },
        { url: 'img/FriendsPlus/Page17/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page17/E2/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/20.jpg', input: 4, isCorrect: false },
        { url: 'img/FriendsPlus/Page17/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/23.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page17/E2/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/25.jpg' },
      ]
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  // 2: { // Exercise num

  //   audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
  //   video: '',
  //   component: U1,
  //   titleImage: 'img/FriendsPlus/Page17/E2/1.jpg',
  //   titleImagePosition: 'top',
  //   titleQuestion: [{ num: '2', title: 'Choose the correct words in the rules.' }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title: '<p>1. We use # /# in questions.</p><p>2. We use Yes, there is and No, there isn’t with</p><p> # / # .</p><p>3. We use Yes, there are and No, there aren’t with</p><p> # / # .</p><p>4. We use How many … ? with # / # .</p>',
  //       answerString: ['some', 'any', 'singular nouns', 'plural nouns', 'singular nouns', 'plural nouns', 'singular nouns', 'plural nouns'],
  //       answer: ['any', 'singular nouns', 'plural nouns', 'plural nouns'],
  //     },
  //   ]
  // },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P17-E3',
    audio: '',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page17/E3/Key/answerKey.png',
    // titleImage: 'img/FriendsPlus/Page17/E6/1.jpg',
    titleQuestion: [{ num: '3', title: 'Match 1–6 with a–f to make questions. Then write answers about your town or city.' }],
    questionImage: [],
    total: 6,
    questions: [
      {
        right: [
          {
            id: 1,
            text: "1. Is there a",
          },
          {
            id: 2,
            text: "2. Are there",
          },
          {
            id: 3,
            text: "3. Is there an",
          },
          {
            id: 4,
            text: "4. How many",
          },
          {
            id: 5,
            text: "5. Is there",
          },
          {
            id: 6,
            text: "6. Are there any",
          },
        ],
        left: [
          {
            id: 1,
            text: "a. people are there?",
          },
          {
            id: 2,
            text: "b. a good school?",
          },
          {
            id: 3,
            text: "c. sports centre?",
          },
          {
            id: 4,
            text: "d. bridges?",
          },
          {
            id: 5,
            text: "e. old part?",
          },
          {
            id: 6,
            text: "f. any five-star hotels?",
          },
        ],
        answer: [
          {
            right: 1,
            left: 3,
          },
          {
            right: 2,
            left: 6,
          },
          {
            right: 3,
            left: 5,
          },
          {
            right: 4,
            left: 1,
          },
          {
            right: 5,
            left: 2,
          },
          {
            right: 6,
            left: 4,
          },
        ],
        type: '',
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P17-E4',
    audio: '',
    video: '',
    component: T6,
    titleImage: '',
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page17/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Complete the questions with <i>Is there … ?</i>, <i>Are there … ?</i> and <i>How many … ?</i>. Then ask and answer the questions with a partner.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p><span style='font-weight:600'>1</span> # any trees in your town or city?</p>" +
          "<p><span style='font-weight:600'>2</span> # a park or a sports centre?</p>" +
          "<p><span style='font-weight:600'>3</span> # swimming pools are there?</p>" +
          "<p><span style='font-weight:600'>4</span> # any exciting places for young people?</p>" +
          "<p><span style='font-weight:600'>5</span> #  people live in your town or city?</p></div>",
        answer: [
          'Are there',
          'Is there',
          'How many',
          'Are there',
          'How many'
        ],
        type: 'longAnwser'
      }]
  },
  // 5: { // Exercise num

  //   audio: '',
  //   video: '',
  //   component: U1,
  //   titleImage: 'img/FriendsPlus/Page17/E5/1.jpg',
  //   titleImagePosition: 'top',
  //   titleQuestion: [{ num: '5', title: 'Choose the correct words in the rules.' }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title: '<p>1 We use # /# to talk about one particular thing.</p><p>2 We use # / # to talk about things in general.</p>',
  //       answerString: ['a', 'the', 'some', 'no article'],
  //       answer: ['the', 'no article'],
  //     },
  //   ]
  // },
  5: {
    unit: 'Unit 1',
    id: 'SB6-U1-P17-E5',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page17/E5/Key/answerKey.png',
    titleQuestion: [{ num: '5', title: 'Choose the correct words in the Rules.' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page17/E5/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page17/E5/3.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page17/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page17/E5/5.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page17/E5/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E5/7.jpg' },
        { url: 'img/FriendsPlus/Page17/E5/8.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page17/E5/9.jpg' },
        { url: 'img/FriendsPlus/Page17/E5/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page17/E5/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E5/12.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page17/E6/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page17/E6/1.jpg',
    imgSize: '80%',
    titleQuestion: [{ num: '6', title: 'Complete the sentences with <i>the</i> or –.' }],
    questionImage: [],
    questions: [
      {
        title: "<div style='margin-left:35px'><p><span style='font-weight:600'>1</span> There are lots of things to do on # cruise ship.</p>" +
          "<p><span style='font-weight:600'>2</span> I go running in # park near my house</p>" +
          "<p><span style='font-weight:600'>3</span> I think # climbing is an exciting sport.</p>" +
          "<p><span style='font-weight:600'>4</span> My dad likes cooking # Italian food.</p>" +
          "<p><span style='font-weight:600'>5</span> There are lots of boats at # Cái Răng floating market.</p>",
        answer: ['the', 'the', '-', '-', 'the'],
        type: ''
      },
    ]
  },
  7: { // Exercise num
    unit: 'Unit 1',
    audio: '',
    video: '',
    component: UI,
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page17/E7/1.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  7.1: { // Exercise num
    unit: 'Unit 1',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page17/E7.1/title.jpg",
    titleQuestion: '',
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;