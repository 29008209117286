import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import D1_W from '../../components/ExcerciseTypes/Design/TypeIn_W';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P44-E1',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page44/E1/1.png",
    titleQuestion: [{ color: '#BF215C', num: '1', title: 'Look at the photo. What is the situation?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P44-E2',
    audio: 'img/FriendsPlus/Page44/E2/Audio/G6 SB Track 1.37.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P44E2.mp4',
    videoSub: 'Videos/P44E2.vtt',
    fontSize: 15,
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page44/E2/Key/answerKey.png',
    // typeInput:'center',
    titleImage: 'img/FriendsPlus/Page44/E2/title.png',
    titleQuestion: [{ color: '#BF215C', num: '2', title: "Watch or listen and complete the dialogue with the phrases in the box. Check your answer to exercise 1. When can Lottie watch the TV programme?" }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/3.jpg', input: true, answer: 'watch that wildlife programme on TV' },
        { url: 'img/FriendsPlus/Page44/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/7.jpg', input: true, answer: "It's really good" },
        { url: 'img/FriendsPlus/Page44/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/11.jpg', input: true, answer: "it's time for dinner" },
        { url: 'img/FriendsPlus/Page44/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page44/E2/15.jpg', input: true, answer: 'watch TV later' },
        { url: 'img/FriendsPlus/Page44/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E2/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P44-E3',
    audio: '',
    video: 'Videos/P44E2.mp4',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/E3/Key/Key.png',
    // titleImage: "img/FriendsPlus/Page43/E5/1.png",
    titleQuestion: [{ color: '#BF215C', num: '3', title: 'Look at the dialogue again. Match phrases a–d with 1–4 in the dialogue to create a new situation. Then practise the dialogue.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>a</span> I think you’ve got homework # </p>" +
          "<p><span style='font-weight:600'>b</span> go to the cinema with Hana # </p>" +
          "<p><span style='font-weight:600'>c</span> go at the weekend # </p>" +
          "<p><span style='font-weight:600'>d</span> There’s a really good film # </p></div>",
        answer: ['3', '1', '4', '2'],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P44-E4',
    audio: '',
    video: '',

    exerciseKey: 'img/FriendsPlus/Page44/E4/Key/answerKeyP44E4.jpg',
    component: D1_W,
    titleImage: "img/FriendsPlus/Page44/E4/1.png",
    // titleQuestion: [{ color: '#BF215C', num: '4', title: 'Match a–f with 1–3 in the key phrases.There is more than one correct answer.' }],
    questionImage: [
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/3.jpg', input: true, answer: 'b/d/e' },
        { url: 'img/FriendsPlus/Page44/E4/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/7.jpg', input: true, answer: 'b/d/e' },
        { url: 'img/FriendsPlus/Page44/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E4/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page44/E4/11.jpg', input: true, answer: 'a/c/f' },
        { url: 'img/FriendsPlus/Page44/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page44/E4/13.jpg' },
      ],
    ],
    // questions: [
    //   {
    //     title:
    //       "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>a</span> you’re always on the computer # </p>" +
    //       "<p><span style='font-weight:600'>b</span> watch TV # </p>" +
    //       "<p><span style='font-weight:600'>c</span> it’s late and you’ve got school tomorrow # </p>" +
    //       "<p><span style='font-weight:600'>d</span> go to a friend’s house # </p></div>" +
    //       "<p><span style='font-weight:600'>e</span> use the computer # </p></div>" +
    //       "<p><span style='font-weight:600'>f</span> you need to help with the house work now # </p></div>",
    //     answer: ['3', '1 and 2', '3', '1 and 2', '1 and 2', '3'],
    //     type: 'longAnwser'
    //   },
    // ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P44-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page44/E5/1.png",
    // titleQuestion: [{ color: '#BF215C', num: '1', title: 'Look at the photo. What is the situation?.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P44-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page44/E6/1.png",
    // titleQuestion: [{ color: '#BF215C', num: '1', title: 'Look at the photo. What is the situation?.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json