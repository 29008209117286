
import S2 from '../../components/ExcerciseTypes/Select/Select2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {
  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P8-E1',
    audio: 'img/FriendsPlus/Page8/E1/Audio/Friends Plus for Vietnam G6 SB Track 1.05.mp3',
    video: '',
    component: S2,
    exerciseKey: 'img/FriendsPlus/Page8/E1/Key/Key.jpg',
    titleImage: 'img/FriendsPlus/Page8/E1/title.png',
    titleQuestion: [{ num: '1', title: 'Match the words in the box with 1-16 in the pictures. Then listen and check.' }],
    image: 'img/FriendsPlus/Page8/E1/1.jpg',
    image1: 'img/FriendsPlus/Page8/E1/2.jpg',
    image2: '',
    width: 220,

    questions: [
      {
        no: "1",
        text: "poster",
        // image: "img/FriendsPlus/Page6/E1/1.png",
      },
      {
        no: "2",
        text: "speaker",
        // image: "img/FriendsPlus/Page6/E1/2.png",
      },
      {
        no: "3",
        text: "laptop",
        // image: "img/FriendsPlus/Page6/E1/3.png",
      },
      {
        no: "4",
        text: "table",
        // image: "img/FriendsPlus/Page6/E1/4.png",
      },
      {
        no: "5",
        text: "drawer",
        // image: "img/FriendsPlus/Page6/E1/5.png",
      },
      {
        no: "6",
        text: "mobile phone",
        // image: "img/FriendsPlus/Page6/E1/6.png",
      },
      {
        no: "7",
        text: "clock",
        // image: "img/FriendsPlus/Page6/E1/7.png",
      },
      {
        no: "8",
        text: "board",
        // image: "img/FriendsPlus/Page6/E1/8.png",
      },
      {
        no: "9",
        text: "dictionaries",
        // image: "img/FriendsPlus/Page6/E1/9.png",
      },
      {
        no: "10",
        text: "shelf",
        // image: "img/FriendsPlus/Page6/E1/10.png",
      },
      {
        no: "11",
        text: "pen",
        // image: "img/FriendsPlus/Page6/E1/11.png",
      },
      {
        no: "12",
        text: "notebook",
        // image: "img/FriendsPlus/Page6/E1/12.png",
      },
      {
        no: "13",
        text: "desk",
        // image: "img/FriendsPlus/Page6/E1/13.png",
      },
      {
        no: "14",
        text: "coat",
        // image: "img/FriendsPlus/Page6/E1/14.png",
      },
      {
        no: "15",
        text: "chair",
        // image: "img/FriendsPlus/Page6/E1/15.png",
      },
      {
        no: "16",
        text: "bag",
        // image: "img/FriendsPlus/Page6/E1/15.png",
      },
    ]
  },
  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P8-E2',
    audio: 'img/FriendsPlus/Page8/E2/Audio/Friends Plus for Vietnam G6 SB Track 1.06.mp3',
    video: '',
    component: T6,
    imgSize: '100%',
    exerciseKey: 'img/FriendsPlus/Page8/E2/Key/Key.jpg',
    titleImage: 'img/FriendsPlus/Page8/E2/title.jpg',
    titleQuestion: [{ num: '2', title: ' Study the prepositions. Then complete the sentences with the prepositions. Listen and check.' }],
    questionImage: [],
    questions: [
      {
        title: '<p><b>1</b> The dictionaries are # the shelf. </p><p><b>2</b> The teacher’s mobile is  # the drawer.</p><p><b>3</b> The laptop is # the speakers.</p><p><b>4</b> The students’ chairs are # their desks.</p><p><b>5</b> The poster is # the teacher’s table.</p><p><b>6</b> Harry’s coat is # his chair.</p><p> <b>7</b> Eva’s bag is # her desk.</p><p> <b>8</b> The clock is # the board.</p><p> <b>9</b> The board is # the students’ desks.</p><p> <b>10</b> Harry’s pen is # his notebook.</p>',
        answer: ['on', 'in', 'between', 'under', 'next to', 'on', 'under', 'near', 'opposite', 'on']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P8-E3',
    audio: '',
    video: '',
    component: UI,
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page8/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page8/E3/2.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P8-E4',
    audio: '',
    video: '',
    component: UI,
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page8/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page8/E4/2.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;