import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P70-E1',
    audio: 'img/FriendsPlus/Page70/E1/Audio/G6 SB Track 2.11.WAV',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page70/E1/Key/answerKey.jpg',
    titleImage: 'img/FriendsPlus/Page70/E1/title.jpg',
    // titleQuestion: [{ color: '#C83734', num: '1', title: 'Complete the words in the text.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/3.jpg', input: true, answer: 'Protein' },
        { url: 'img/FriendsPlus/Page70/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/7.jpg', input: true, answer: 'Carbohydrates' },
        { url: 'img/FriendsPlus/Page70/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/11.jpg', input: true, answer: 'Vitamins' },
        { url: 'img/FriendsPlus/Page70/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/15.jpg', input: true, answer: 'diseases' },
        { url: 'img/FriendsPlus/Page70/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/19.jpg', input: true, answer: 'Minerals' },
        { url: 'img/FriendsPlus/Page70/E1/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/23.jpg', input: true, answer: 'fat' },
        { url: 'img/FriendsPlus/Page70/E1/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/27.jpg', input: true, answer: 'fat' },
        { url: 'img/FriendsPlus/Page70/E1/28.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/29.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/31.jpg', input: true, answer: 'Water' },
        { url: 'img/FriendsPlus/Page70/E1/32.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page70/E1/33.jpg' },
      ],



    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: {
    unit: 'Unit 5',
    id: 'SB6-U5-P70-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page70/E2/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    isHiddenCheck: true,
    titleQuestion: [{ num: '2', title: `Read the text again. Are the sentences <i>True</i> or <i>False</i>?`, color: '#C83734' }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page70/E2/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/05.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/08.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page70/E2/07.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/14.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page70/E2/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page70/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/18.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/25.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/23.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page70/E2/26.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/30.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/28.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page70/E2/31.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/32.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/35.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page70/E2/34.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/33.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page70/E2/37.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P70-E3',
    audio: '',
    video: '',
    component: T6,
    // inputSize: 100,
    // titleImage: "img/FriendsPlus/Page68/E6/1.png",
    exerciseKey: 'img/FriendsPlus/Page70/E3/Key/answerKey.png',
    titleQuestion: [{ color: '#D72F35', num: '3', title: 'Read the sentences and write the names of the food in the text.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> It’s got animal fat in it. It’s from milk. We put it on bread.<br/># </p>" +
          "<p><span style='font-weight:600'>2.</span> It’s got vegetable fat in it. It’s in a bottle. We put it on salads. It’s very healthy.<br/># </p>" +
          "<p><span style='font-weight:600'>3.</span> It’s got a lot of protein in it. It’s white. We drink it. <br/># </p>" +
          "<p><span style='font-weight:600'>4.</span> These have got a lot of protein. You can cook them or eat them in a salad. They can be different colours. <br/># </p>" +
          "<p><span style='font-weight:600'>5.</span> It’s brown and very sweet. It’s delicious, but it’s got a lot of fat.<br/># </p>",
        answer: ['butter', 'olive oil', 'milk', 'beans', 'chocolate'],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P70-E4',
    audio: '',
    video: '',
    component: T6,
    titleImage: "img/FriendsPlus/Page70/E4/1.png",
    exerciseKey: 'img/FriendsPlus/Page70/E4/Key/answerKey.png',
    inputSize: 500,
    imgSize: '70%',
    titleQuestion: [{ color: '#D72F35', num: '4', title: 'Look at the picture of the dinner plate. What nutrients are there in the five food groups?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> # </p>" +
          "<p><span style='font-weight:600'>2.</span> # </p>" +
          "<p><span style='font-weight:600'>3.</span> # </p>" +
          "<p><span style='font-weight:600'>4.</span> # </p>" +
          "<p><span style='font-weight:600'>5.</span> # </p>",

        answer: ['vitamins, minerals, water', 'carbohydrates', 'protein', 'fat', 'protein, minerals'],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P70-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page70/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json