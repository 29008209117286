import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P43-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page43/E1/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page42/E1/1.png",
    titleQuestion: [{ num: '1', title: 'Complete the examples from the quiz on page 42. Then choose the correct answers in Rules 1–4.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p>An eagle <span style='font-weight:600'>1</span> # see a small animal from a</p>" +
          "<p>great distance.</p>" +
          "<p>Ostriches <span style='font-weight:600'>2</span> # fly</p>" +
          "<p><span style='font-weight:600'>3</span> # a scorpion kill a human? Yes, it</p>" +
          "<p><span style='font-weight:600'>4</span> # ./No,it <span style='font-weight:600'>5</span> #.</p>" +
          "<p><span style='font-weight:600'>6</span> # elephants hear with their ears and</p>" +
          "<p>their feet? Yes, they <span style='font-weight:600'>7</span> # / No, they</p>" +
          "<p><span style='font-weight:600'>8</span> # </p></div>",
        answer: [
          "can",
          "can't",
          "can",
          "can",
          "can't",
          "can",
          "can",
          "can't"
        ],
        // type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P43-E2',
    audio: '',
    video: '',
    component: D1,
    fontSize: 24,
    height: 30,
    exerciseKey: 'img/FriendsPlus/Page43/E2/Key/answerKey.png',
    // typeInput:'center',
    titleImage: 'img/FriendsPlus/Page43/E2/title.png',
    // titleQuestion: [{ num: '2', title: "Complete the dialogue with can and can't"}],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/3.jpg', input: true, answer: 'can' },
        { url: 'img/FriendsPlus/Page43/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/7.jpg', input: true, answer: 'can' },
        { url: 'img/FriendsPlus/Page43/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/11.jpg', input: true, answer: "can't" },
        { url: 'img/FriendsPlus/Page43/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/15.jpg', input: true, answer: 'can' },
        { url: 'img/FriendsPlus/Page43/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page43/E2/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  '1-1': {
    unit: 'Unit 3',
    id: 'SB6-U3-P43-E1.1',
    audio: '',
    video: 'Videos/Animation - Unit 3 grammar 2 trang 43.mp4',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: 'img/FriendsPlus/Page43/E1/Key/Key.png',
    titleQuestion: [{ color: '#14BCD4', num: '1', title: "Choose the correct answers in rules 1–4." }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page43/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page43/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/5.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page43/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/8.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page43/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page43/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/13.jpg', input: 3, isCorrect: false },
        { url: 'img/FriendsPlus/Page43/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page43/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/19.jpg', input: 4, isCorrect: false },
        { url: 'img/FriendsPlus/Page43/E1/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/22.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page43/E1/23.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E1/24.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: {
    unit: 'Unit 3',
    id: 'SB6-U3-P43-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,

    exerciseKey: 'img/FriendsPlus/Page43/E3/Key/answerKey.png',
    // titleQuestion: [{ color: '#14BCD4', num: '3', title: "Look at the examples of sentences with might. Then choose the correct answers in the rule." }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page43/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/2.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/4.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page43/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page43/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/10.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page43/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page43/E3/14.jpg', },
      ],
      [
        { url: 'img/FriendsPlus/Page43/E3/15.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P43-E4',
    audio: 'img/FriendsPlus/Page43/E4/Audio/G6 SB Track 1.36.WAV',
    video: '',
    component: T6,
    exerciseWidth: '50%',
    imgSize: '80%',
    titleImage: "img/FriendsPlus/Page43/E4/1.png",
    exerciseKey: 'img/FriendsPlus/Page43/E4/key/1.png',
    titleQuestion: [{ num: '4', title: 'Complete the quiz questions with the words in the box. Then listen and check.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p>... is a new baby?</p>" +
          "<p><span style='font-family:OneStrokeScriptStd-Bold;color:gray'>How heavy is a new baby?</span></p>" +
          "<p><span style='font-weight:600'>1</span> # can the fastest person run?</p>" +
          "<p><span style='font-weight:600'>2</span> # countries are there in the world?</p>" +
          "<p><span style='font-weight:600'>3</span> # is an adult giraffe?</p>" +
          "<p><span style='font-weight:600'>4</span> # is an adult crocodile?</p>" +
          "<p><span style='font-weight:600'>5</span> # is the North Pole from the South Pole?</p></div>",
        answer: ['How fast', 'How many', 'How tall', 'How long', 'How far'],
        // type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P43-E5',
    audio: '',
    video: '',
    component: T6,
    inputSize: 500,
    imgSize: '100%',
    exerciseKey: 'img/FriendsPlus/Page43/E5/key/Key.png',
    titleImage: "img/FriendsPlus/Page43/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> #. </p>" +
          "<p><span style='font-weight:600'>2</span> #. </p>" +
          "<p><span style='font-weight:600'>3</span> #. </p>" +
          "<p><span style='font-weight:600'>4</span> #. </p>" +
          "<p><span style='font-weight:600'>5</span> #. </p>",
        answer: [
          "Around 44 kilometres an hour",
          "195/195 (recognised by the United Nations)",
          "Between 5 and 6 metres",
          "Around 4 metres",
          "20,014 kilometres",
        ],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P43-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page43/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json