import S1 from "../../components/ExcerciseTypes/Select/Select1";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";

const json = {
  1: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P6-E1",
    audio:
      "img/FriendsPlus/Page6/E1/Audio/Friends Plus for Vietnam G6 SB Track 1.02.mp3",
    video: "",
    component: S1,
    exerciseKey: "img/FriendsPlus/Page6/E1/Key/answerKey.png",
    exerciseKeySize: "40%",
    width: 220,
    titleImage: "img/FriendsPlus/Page6/E1/title.png",
    titleQuestion: [
      {
        num: "1",
        track: "1.02",
        title:
          "Match the words in the box with pictures 1-15. Then listen and check.",
      },
    ],
    questions: [
      {
        no: "1",
        text: "chatting online",
        image: "img/FriendsPlus/Page6/E1/1.png",
      },
      {
        no: "2",
        text: "reading",
        image: "img/FriendsPlus/Page6/E1/2.png",
      },
      {
        no: "3",
        text: "meeting friends",
        image: "img/FriendsPlus/Page6/E1/3.png",
      },
      {
        no: "4",
        text: "art",
        image: "img/FriendsPlus/Page6/E1/4.png",
      },
      {
        no: "5",
        text: "photography",
        image: "img/FriendsPlus/Page6/E1/5.png",
      },
      {
        no: "6",
        text: "cycling",
        image: "img/FriendsPlus/Page6/E1/6.png",
      },
      {
        no: "7",
        text: "sport",
        image: "img/FriendsPlus/Page6/E1/7.png",
      },
      {
        no: "8",
        text: "animals",
        image: "img/FriendsPlus/Page6/E1/8.png",
      },
      {
        no: "9",
        text: "music",
        image: "img/FriendsPlus/Page6/E1/9.png",
      },
      {
        no: "10",
        text: "video games",
        image: "img/FriendsPlus/Page6/E1/10.png",
      },
      {
        no: "11",
        text: "watching TV",
        image: "img/FriendsPlus/Page6/E1/11.png",
      },
      {
        no: "12",
        text: "films",
        image: "img/FriendsPlus/Page6/E1/12.png",
      },
      {
        no: "13",
        text: "skateboarding",
        image: "img/FriendsPlus/Page6/E1/13.png",
      },
      {
        no: "14",
        text: "cooking",
        image: "img/FriendsPlus/Page6/E1/14.png",
      },
      {
        no: "15",
        text: "shopping",
        image: "img/FriendsPlus/Page6/E1/15.png",
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P6-E2",
    audio:
      "img/FriendsPlus/Page6/E2/Audio/Friends Plus for Vietnam G6 SB Track 1.03.mp3",
    video: "",
    height: 25,
    component: D1,
    exerciseKey: "img/FriendsPlus/Page6/E2/Key/answerKey.png",
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page6/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/2.jpg",
          audioUrl:
            "img/FriendsPlus/Page6/E2/Audio/03- Starter Unit - Track 03.mp3",
        },
        { url: "img/FriendsPlus/Page6/E2/3_1.jpg" },
        // { url: 'img/FriendsPlus/Page6/E2/3.jpg' },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/4_1.jpg" },
        // { url: 'img/FriendsPlus/Page6/E2/4.jpg' }
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/5.jpg" },
        { url: "img/FriendsPlus/Page6/E2/6.jpg", input: true, answer: "music" },
        { url: "img/FriendsPlus/Page6/E2/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/9.jpg",
          input: true,
          answer: "chatting",
        },
        { url: "img/FriendsPlus/Page6/E2/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/12.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/13.jpg",
          input: true,
          answer: "video games",
        },
        { url: "img/FriendsPlus/Page6/E2/14.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/16.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/17.jpg",
          input: true,
          answer: "skateboarding",
        },
        { url: "img/FriendsPlus/Page6/E2/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/19.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/20.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/21.jpg",
          input: true,
          answer: "reading",
        },
        { url: "img/FriendsPlus/Page6/E2/22.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/23.jpg",
          input: true,
          answer: "photography",
        },
        { url: "img/FriendsPlus/Page6/E2/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/25.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/26.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/27.jpg",
          input: true,
          answer: "cooking",
        },
        { url: "img/FriendsPlus/Page6/E2/28.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/29.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P6-E3",
    audio:
      "img/FriendsPlus/Page6/E3/Audio/Friends Plus for Vietnam G6 SB Track 1.04.mp3",
    video: "",
    component: D1,
    height: 25,
    fontSize: 22,
    exerciseKey: "img/FriendsPlus/Page6/E3/Key/key.png",
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page6/E3/1.jpg" },
        {
          url: "img/FriendsPlus/Page6/E3/2.jpg",
          audioUrl:
            "img/FriendsPlus/Page6/E2/Audio/03- Starter Unit - Track 03.mp3",
        },
        { url: "img/FriendsPlus/Page6/E3/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E3/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E3/5.jpg" },
        { url: "img/FriendsPlus/Page6/E3/6.jpg", input: true, answer: "music" },
        { url: "img/FriendsPlus/Page6/E3/7.jpg" },
        {
          url: "img/FriendsPlus/Page6/E3/8.jpg",
          input: true,
          answer: "cycling",
        },
        { url: "img/FriendsPlus/Page6/E3/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E3/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E3/11.jpg" },
        {
          url: "img/FriendsPlus/Page6/E3/12.jpg",
          input: true,
          answer: "films and watching TV",
        },
        { url: "img/FriendsPlus/Page6/E3/13.jpg" },
        {
          url: "img/FriendsPlus/Page6/E3/14.jpg",
          input: true,
          answer: "reading",
        },
        { url: "img/FriendsPlus/Page6/E3/15.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E3/16.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E3/17.jpg" },
        {
          url: "img/FriendsPlus/Page6/E3/18.jpg",
          input: true,
          answer: "shopping and meeting friends",
        },
        { url: "img/FriendsPlus/Page6/E3/19.jpg" },
        {
          url: "img/FriendsPlus/Page6/E3/20.jpg",
          input: true,
          answer: "chatting online",
        },
        { url: "img/FriendsPlus/Page6/E3/21.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E3/22.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E3/23.jpg" },
        {
          url: "img/FriendsPlus/Page6/E3/24.jpg",
          input: true,
          answer: "skateboarding",
        },
        { url: "img/FriendsPlus/Page6/E3/25.jpg" },
        { url: "img/FriendsPlus/Page6/E3/26.jpg", input: true, answer: "art" },
        { url: "img/FriendsPlus/Page6/E3/27.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page6/E3/28.jpg" },
        {
          url: "img/FriendsPlus/Page6/E3/29.jpg",
          input: true,
          answer: "animals",
        },
        { url: "img/FriendsPlus/Page6/E3/30.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page6/E3/31.jpg" },
        { url: "img/FriendsPlus/Page6/E3/32.jpg", input: true, answer: "dogs" },
        { url: "img/FriendsPlus/Page6/E3/33.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E3/34.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P6-E4",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page6/E4/1.png",
    titledisplay: "right",
    widthinput: 560,
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Starter Unit",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page6/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        // title:
        //   "<p> # </p>" +
        //   "<p> # </p>" +
        //   "<p> # </p>" +
        //   "<p> # </p>" +
        //   "<p> # </p>" +
        //   "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};

export default json;
