
import S8 from '../../components/ExcerciseTypes/Select/Select8';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';


const json = {

  1: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P96-E1',
    audio: 'img/FriendsPlus/Page96/E1/Audio/Friends Plus for Vietnam G6 SB Track 2.31 Trang 96.WAV',
    video: '',
    component: S8,
    isShowCheck: false,
    exerciseKey: 'img/FriendsPlus/Page96/E1/Key/Key.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Listen to four street interviews in a radio programme. What do the people talk about? Choose four topics from the box and match them to the people.', color: '#F88D3B' }],
    image: 'img/FriendsPlus/Page96/E1/1.jpg',
    widthImg: '70%',
    width: 200,
    questions: [
      {
        no: "1",
        text: "bus",
      },
      {
        no: "2",
        text: "plane",
      },
      {
        no: "3",
        text: "ferry",
      },
      {
        no: "4",
        text: "tram",
      },
      {
        no: "5",
        text: "subway train",
      },
      {
        no: "6",
        text: "trolleybus",
      },
      {
        no: "7",
        text: "cable car",
      },
      {
        no: "8",
        text: "ship",
      },
      {
        no: "9",
        text: "train",
      },
      {
        no: "10",
        text: "boat",
      },

    ]
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P96-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page96/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Which means of transport have you got in your country?', color: '#076BB3' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",

        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}

export default json;