import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';

const json = {
  1: { // Exercise num
    unit : 'Option Song 3',
    id : 'SB6-OPS3-P119-E1',
    audio: 'img/FriendsPlus/Page119/E1/Audio/G6 SB Track 3.22.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page119/E1/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Complete the Key Phrases with words in the description.', color: '#0caa50' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E1/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/4.jpg', input: true, answer: 'gorilla' },
        { url: 'img/FriendsPlus/Page119/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/6.jpg', input: true, answer: 'eagle' },
        { url: 'img/FriendsPlus/Page119/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/8.jpg', input: true, answer: 'whale' },
        { url: 'img/FriendsPlus/Page119/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/10.jpg', input: true, answer: 'lion' },
        { url: 'img/FriendsPlus/Page119/E1/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/14.jpg', input: true, answer: 'tortoise' },
        { url: 'img/FriendsPlus/Page119/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/16.jpg', input: true, answer: 'parrot' },
        { url: 'img/FriendsPlus/Page119/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/18.jpg', input: true, answer: 'mouse' },
        { url: 'img/FriendsPlus/Page119/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/20.jpg', input: true, answer: 'starfish' },
        { url: 'img/FriendsPlus/Page119/E1/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E1/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/24.jpg', input: true, answer: 'hedgehog' },
        { url: 'img/FriendsPlus/Page119/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/26.jpg', input: true, answer: 'scorpion' },
        { url: 'img/FriendsPlus/Page119/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/28.jpg', input: true, answer: 'dolphin' },
        { url: 'img/FriendsPlus/Page119/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page119/E1/30.jpg', input: true, answer: 'tiger' },
        { url: 'img/FriendsPlus/Page119/E1/31.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit : 'Option Song 3',
    id : 'SB6-OPS3-P119-E2',
    audio: 'img/FriendsPlus/Page119/E1/Audio/G6 SB Track 3.22.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page119/E2/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Complete the song with the words in the box. Then listen again and check.', color: '#da2f37' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page119/E2/3.jpg', input: true, answer: 'grow' },
        { url: 'img/FriendsPlus/Page119/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page119/E2/7.jpg', input: true, answer: 'kill' },
        { url: 'img/FriendsPlus/Page119/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page119/E2/11.jpg', input: true, answer: 'see' },
        { url: 'img/FriendsPlus/Page119/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page119/E2/15.jpg', input: true, answer: 'eat' },
        { url: 'img/FriendsPlus/Page119/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page119/E2/19.jpg', input: true, answer: 'jump' },
        { url: 'img/FriendsPlus/Page119/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page119/E2/23.jpg', input: true, answer: 'lives' },
        { url: 'img/FriendsPlus/Page119/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page119/E2/27.jpg', input: true, answer: 'swim' },
        { url: 'img/FriendsPlus/Page119/E2/28.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/29.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page119/E2/31.jpg', input: true, answer: 'survive' },
        { url: 'img/FriendsPlus/Page119/E2/32.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page119/E2/33.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit : 'Option Song 3',
    id : 'SB6-OPS3-P119-E3',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T6,
    titleImage: '',
    //   imgSize: '60%',
    inputSize: 300,
    exerciseKey: 'img/FriendsPlus/Page119/E3/Key/answerKey.jpg',
    titleQuestion: [{ color: '#BF2A32', num: '3', title: 'Answer the questions with animals from the song.' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          '<p>1 Which animal is the biggest mammal? #  </p>' +
          '<p>2 Which animal is the tallest bird? # </p>' +
          '<p>3 Which animal has got a shell? # </p>' +
          '<p>4 Which animal can see very far? # </p>' +
          '<p>5 Which animal can survive with very little water? # </p>' +
          '<p>6 Which two animals eat frogs?  # </p>',
        answer: [
          "Whale",
          "Ostrich",
          "Tortoise",
          'Eagle',
          'Camel',
          'Spider and snake',
        ],
        // type: 'longAnwser'

      },
    ]
  },
  4: { // Exercise num
    unit : 'Option Song 3',
    id : 'SB6-OPS3-P119-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page119/E4/1.png",
    imgSize: '70%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json;