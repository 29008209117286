import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P53-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseWidth: '55%',
    inputSize: 650,
    exerciseKey: 'img/FriendsPlus/Page53/E1/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page45/E1/1.png",
    titleQuestion: [{ color: '#00AFBE', num: '1', title: 'Look at the sentences. What are the <i>he</i> / <i>she</i> and <i>they</i> forms of each question and answer?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1. </span>Are you learning English? Yes, I am.</p>" +
          "<p> #. </p>" +
          "<p> #. </p>" +
          "<p> #. </p>" +
          "<p><span style='font-weight:600'>2. </span>What are you doing now? I’m watching TV.</p>" +
          "<p> #. </p>" +
          "<p> #. </p>" +
          "<p> #. </p>" +
          "<p><span style='font-weight:600'>3. </span>Are you learning the same language?</p>" +
          "<p>   No, we aren’t.</p>" +
          "<p> #. </p>" +
          "<p> #. </p>" +
          "<p> #. </p>",
        answer: [
          // 1
          'Is he learning English? Yes, he is',
          'Is she learning English? Yes, she is',
          'Are they learning English? Yes, they are',
          // 2
          "What is he doing now? He's watching TV",
          "What is she doing now? She's watching TV",
          "What are they doing now? They're watching TV",
          // 3
          "Is he learning the same language? No, he isn't",
          "Is she learning the same language? No, she isn't",
          "Are they learning the same language? No, they aren't"
        ],
        // type: 'longAnwser'

      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P53-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page53/E2/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P53-E3',
    audio: '',
    video: 'Videos/Animation - Unit 4 grammar 2 trang 53.mp4',
    component: D1,
    fontSize: 22,
    height: 26,
    exerciseKey: 'img/FriendsPlus/Page53/E3/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '3', title: 'Complete the table with sentences 3–6. Which time expressions do we use with a) the present simple, and b) the present continuous?', color: '#17bbd8' }],
    questionImage: [ // Row

      [//1
        // Column
        { url: 'img/FriendsPlus/page53/E3/1.jpg' },
      ],
      [//2
        // Column
        { url: 'img/FriendsPlus/page53/E3/2.jpg' },
        { url: 'img/FriendsPlus/page53/E3/3.jpg', input: true, answer: "sometimes, usually, always" },
        { url: 'img/FriendsPlus/page53/E3/4.jpg' },
      ],
      [//3
        // Column
        { url: 'img/FriendsPlus/page53/E3/5.jpg' },

      ],
      [//4
        // Column
        { url: 'img/FriendsPlus/page53/E3/6.jpg' },
        { url: 'img/FriendsPlus/page53/E3/7.jpg', input: true, answer: "today, at the moment, now" },
        { url: 'img/FriendsPlus/page53/E3/8.jpg' },

      ],
      [//5
        // Column
        { url: 'img/FriendsPlus/page53/E3/9.jpg' },

      ],


    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },



  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P53-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page53/E4/Key/answerKey.png',
    //titleImage: 'http://',
    // titleQuestion: [{ num: '3', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    // typeInput : 'center',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page53/E4/3.jpg', input: true, answer: 'practises' },
        { url: 'img/FriendsPlus/Page53/E4/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page53/E4/7.jpg', input: true, answer: "'s relaxing" },
        { url: 'img/FriendsPlus/Page53/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page53/E4/11.jpg', input: true, answer: 'plays' },
        { url: 'img/FriendsPlus/Page53/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page53/E4/15.jpg', input: true, answer: "'s playing" },
        { url: 'img/FriendsPlus/Page53/E4/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page53/E4/19.jpg', input: true, answer: "'s chatting" },
        { url: 'img/FriendsPlus/Page53/E4/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/22.jpg' },
        { url: 'img/FriendsPlus/Page53/E4/23.jpg', input: true, answer: 'speaks' },
        { url: 'img/FriendsPlus/Page53/E4/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page53/E4/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P53-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page53/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json