import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
    5: { // Exercise num
        unit : 'Progress Review 4',
        id : 'SB6-PV4-P107-E5',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page107/E5/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '5', title: 'Complete the questions using the words.', color: '#57489E' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/1.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/2.jpg'},
                { url: 'img/FriendsPlus/Page107/E5/3.jpg', input: true, answer: 'did you eat'},
                { url: 'img/FriendsPlus/Page107/E5/4.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/5.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/6.jpg'},
                { url: 'img/FriendsPlus/Page107/E5/7.jpg', input: true, answer: 'Did you revise'},
                { url: 'img/FriendsPlus/Page107/E5/8.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/9.jpg'},
                { url: 'img/FriendsPlus/Page107/E5/10.jpg', input: true, answer: 'Did Anna play'},
                { url: 'img/FriendsPlus/Page107/E5/11.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/12.jpg'},
                { url: 'img/FriendsPlus/Page107/E5/13.jpg', input: true, answer: 'did your friends watch'},
                { url: 'img/FriendsPlus/Page107/E5/14.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/15.jpg'},
                { url: 'img/FriendsPlus/Page107/E5/16.jpg', input: true, answer: 'did your sister go'},
                { url: 'img/FriendsPlus/Page107/E5/17.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/18.jpg'},
                { url: 'img/FriendsPlus/Page107/E5/19.jpg', input: true, answer: 'Did you listen'},
                { url: 'img/FriendsPlus/Page107/E5/20.jpg'},
            ],                    
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E5/21.jpg'},
            ],                       
   
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    7: { // Exercise num
        unit : 'Progress Review 4',
        id : 'SB6-PV4-P107-E7',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page107/E7/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '7', title: 'Complete the questions and answers with the words.', color: '#57489E' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/1.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/2.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/3.jpg', input: true, answer: 'life'},
                { url: 'img/FriendsPlus/Page107/E7/4.jpg'},
            ],  
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/5.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/6.jpg', input: true, answer: 'tall'},
                { url: 'img/FriendsPlus/Page107/E7/7.jpg'},
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/8.jpg'},
            ], 
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/9.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/10.jpg', input: true, answer: 'eyes'},
                { url: 'img/FriendsPlus/Page107/E7/11.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/12.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/13.jpg', input: true, answer: 'exactly'},
                { url: 'img/FriendsPlus/Page107/E7/14.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/15.jpg', input: true, answer: 'grow'},
                { url: 'img/FriendsPlus/Page107/E7/16.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/17.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/18.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/19.jpg', input: true, answer: 'grew'},
                { url: 'img/FriendsPlus/Page107/E7/20.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/21.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/22.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/23.jpg', input: true, answer: 'become'},
                { url: 'img/FriendsPlus/Page107/E7/24.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/25.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/26.jpg', input: true, answer: 'singing'},
                { url: 'img/FriendsPlus/Page107/E7/27.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/28.jpg'},
            ],                        
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/29.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/30.jpg', input: true, answer: 'big'},
                { url: 'img/FriendsPlus/Page107/E7/31.jpg'},

            ],   
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/32.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/33.jpg', input: true, answer: 'well'},
                { url: 'img/FriendsPlus/Page107/E7/34.jpg'},

            ],                     
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/35.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/36.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/37.jpg', input: true, answer: 'key'},
                { url: 'img/FriendsPlus/Page107/E7/38.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/39.jpg'},
            ],            
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/40.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/41.jpg', input: true, answer: 'won'},
                { url: 'img/FriendsPlus/Page107/E7/42.jpg'},
            ],   
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/43.jpg'},
            ],         
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/44.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/45.jpg', input: true, answer: 'anything'},
                { url: 'img/FriendsPlus/Page107/E7/46.jpg'},
            ],  
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/47.jpg'},
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/48.jpg'},
                { url: 'img/FriendsPlus/Page107/E7/49.jpg', input: true, answer: 'Apparently'},
                { url: 'img/FriendsPlus/Page107/E7/50.jpg'},
            ],   
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E7/51.jpg'},
            ],    
               
   
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
	
    6: { // Exercise num
        unit : 'Progress Review 4',
        id : 'SB6-PV4-P107-E6',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page107/E6/Key/1.png',
        titleImage: '',
        titleQuestion: [{ num: '6', title: 'Read the dialogue and choose the best answer. Write a letter (A–H) for each answer. There are two extra answers.', color: '#4f3e9d' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/2.jpg' },
                { url: 'img/FriendsPlus/Page107/E6/3.jpg', input: true, answer: "C" },
                { url: 'img/FriendsPlus/Page107/E6/4.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/5.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/6.jpg' },
                { url: 'img/FriendsPlus/Page107/E6/7.jpg', input: true, answer: 'G' },
                { url: 'img/FriendsPlus/Page107/E6/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/10.jpg' },
                { url: 'img/FriendsPlus/Page107/E6/11.jpg', input: true, answer: 'A' },
                { url: 'img/FriendsPlus/Page107/E6/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/14.jpg' },
                { url: 'img/FriendsPlus/Page107/E6/15.jpg', input: true, answer: 'D' },
                { url: 'img/FriendsPlus/Page107/E6/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/17.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/18.jpg' },
                { url: 'img/FriendsPlus/Page107/E6/19.jpg', input: true, answer: "H" },
                { url: 'img/FriendsPlus/Page107/E6/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/21.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/22.jpg' },
                { url: 'img/FriendsPlus/Page107/E6/23.jpg', input: true, answer: 'F' },
                { url: 'img/FriendsPlus/Page107/E6/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page107/E6/25.jpg' },
            ],
        ]
    }
}
export default json