import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    
    unit : 'Option 3',
    id : 'SB6-OPE3-P112-E1',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page112/E1/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page112/E1/1.png",
    // imgSize: '70%',
    titleQuestion: [{ color: '#E26D28', num: '1', title: 'Look at the photo. Where is Holly?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:600'>Where is Holly? #. </p>" ,
        answer: ["She's at an aquarium"],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    
    unit : 'Option 3',
    id : 'SB6-OPE3-P112-E2',
    exerciseKey: 'img/FriendsPlus/Page112/E2/Key/answerKey.png',
    audio: 'img/FriendsPlus/Page112/E2/Audio/G6 SB Track 3.08.mp3',
    video: '',
    component: T6,
    inputSize: 500,
    titleImage: "img/FriendsPlus/Page112/E2/1.png",
    imgSize: '90%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:600'>&emsp;&emsp;Which animal does Holly like best?  </p>" +
          "<p>&emsp; #.</p>",
        answer: ['She likes the sharks best'],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    
    unit : 'Option 3',
    id : 'SB6-OPE3-P112-E3',
    audio: 'img/FriendsPlus/Page112/E2/Audio/G6 SB Track 3.08.mp3',
    video: '',
    component: T6,
    inputSize: 650,
    fontSize: 30,
    titleImage: "img/FriendsPlus/Page112/E3/1.jpg",
    exerciseKey: 'img/FriendsPlus/Page112/E3/Key/answerKey.png',
    imgSize: '90%',
    titleQuestion: [{ color: '#3F3478', num: '3', title: "Match the key phrases with the responses in blue in the dialogue. Then listen again and practise the dialogue." }],
    questionImage: [],
    questions: [
        {
            title:
                "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1.</span> Do you want to meet later? #.</p>" +
                "<p><span style='font-weight:600'>2.</span> Where are you now? #.</p>" +
                "<p><span style='font-weight:600'>3.</span> Give me a call. #.</p>" +
                "<p><span style='font-weight:600'>4.</span> It’s (Dylan). #.</p>" +
                "<p><span style='font-weight:600'>5.</span> Is it good there? #!</p>" +
                "<p><span style='font-weight:600'>6.</span> How are you? #.</p>",
            answer: [
                "Yeah. That sounds good",
                "I'm at the aquarium with Aisha",
                "Sure",
                "Hi, there",
                "Yeah, it's great",
                "Fine, thanks",                
            ],
            type: 'longAnwser'
        },
    ]
},
  4: { // Exercise num
    
    unit : 'Option 3',
    id : 'SB6-OPE3-P112-E4',
    audio: 'img/FriendsPlus/Page112/E4/Audio/G6 SB Track 3.09.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page112/E4/Key/1.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Listen to the dialogue and write the correct answers.', color: '#f67f24' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page112/E4/3.jpg', input: true, answer: "It's" },
        { url: 'img/FriendsPlus/Page112/E4/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page112/E4/7.jpg', input: true, answer: 'Where are you now' },
        { url: 'img/FriendsPlus/Page112/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page112/E4/11.jpg', input: true, answer: 'Is it good there' },
        { url: 'img/FriendsPlus/Page112/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page112/E4/15.jpg', input: true, answer: 'Do you want to meet later' },
        { url: 'img/FriendsPlus/Page112/E4/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page112/E4/19.jpg', input: true, answer: 'Give me a call' },
        { url: 'img/FriendsPlus/Page112/E4/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page112/E4/21.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    
    unit : 'Option 3',
    id : 'SB6-OPE3-P112-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page112/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json