
import S3 from '../../components/ExcerciseTypes/Select/Select3';
import S5 from '../../components/ExcerciseTypes/Select/Select5';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P18-E1',
    audio: 'img/FriendsPlus/Page18/E1/Audio/Friends Plus for Vietnam G6 SB Track 1.15.mp3',
    video: '',
    component: S5,
    width: 155,
    exerciseKey: 'img/FriendsPlus/Page18/E1/Key/Key.jpg',
    titleImage: 'img/FriendsPlus/Page18/E1/title.png',
    titleQuestion: [{ num: '1', track: '1.15', title: 'Match adjectives 1-6 with their opposites in the box. Then listen and check.', color: '#F88D3B' }],
    questions: [
      {
        no: "1",
        text: "dirty",
        textLeft: 'clean',
        image: "img/FriendsPlus/Page18/E1/1.png",
      },
      {
        no: "2",
        text: "quiet",
        textLeft: 'noisy',
        image: "img/FriendsPlus/Page18/E1/2.png",
      },
      {
        no: "3",
        text: "pretty",
        textLeft: 'ugly',
        image: "img/FriendsPlus/Page18/E1/3.png",
      },
      {
        no: "4",
        text: "safe",
        textLeft: 'dangerous',
        image: "img/FriendsPlus/Page18/E1/4.png",
      },
      {
        no: "5",
        text: "unfriendly",
        textLeft: 'friendly',
        image: "img/FriendsPlus/Page18/E1/5.png",
      },
      {
        no: "6",
        text: "old",
        textLeft: 'modern',
        image: "img/FriendsPlus/Page18/E1/6.png",
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P18-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "",
    titleQuestion: [{ num: '2', color: '#F88D3B', title: 'Write sentences for the adjectives in exercise 1. Then compare with a partner.' }],
    questionImage: [],
    questions: [
      {
        title: "<p style='font-family:OneStrokeScriptStd-Bold;color:gray'> This window is clean.</p>" +
          "<p style='font-family:OneStrokeScriptStd-Bold;color:gray'> Cars are noisy.</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P18-E3',
    audio: 'img/FriendsPlus/Page18/E3/Audio/Friends Plus for Vietnam G6 SB Track 1.16.mp3',
    video: '',
    component: S3,
    exerciseKey: 'img/FriendsPlus/Page18/E3/Key/Key.jpg',
    titleImage: 'img/FriendsPlus/Page18/E3/title.png',
    titleQuestion: [{ num: '3', title: 'Listen to four street interviews in a radio programme. What do the people talk about? Choose four topics from the box and match them to the people.', color: '#F88D3B' }],
    image: 'img/FriendsPlus/Page18/E3/1.png',
    width: 230,
    questions: [
      {
        no: "1",
        text: "the park",
        textLeft: 'Emma:',
      },
      {
        no: "2",
        text: "buses and bikes",
        textLeft: 'Lukas:',
      },
      {
        no: "3",
        text: "New York",
        textLeft: 'Dwayne:',
      },
      {
        no: "4",
        text: "restaurants",
        textLeft: 'Chloe and Harriet:',
      },
      {
        no: "5",
        text: "cafés",
      },
      {
        no: "6",
        text: "schools",
      },
      {
        no: "7",
        text: "trains",
      },
    ]
  },
  // 4: { // Exercise num
  //   unit: 'Unit 1',
  //   id: 'SB6-U1-P18-E4',
  //   audio: 'img/FriendsPlus/Page18/E4/Audio/28- Unit 1 - Track 11.mp3',
  //   video: '',
  //   component: MC2,
  //   exerciseKey: 'img/FriendsPlus/Page18/E4/Key/answerKey.png',
  //   titleQuestion: [{ num: '4', title: 'Listen again and write True or False.', color: '#F88D3B' }],
  //   //titleImage: 'http://',
  //   questionImage: [ // Row
  //     [
  //       // Column
  //       { url: 'http://' },
  //       { url: 'http://', input: true },
  //       { url: 'http://' },
  //     ],
  //   ],
  //   questions: [
  //     {
  //       title: 'Emma thinks that the shopping center is cleaner than park.',
  //       answers: [
  //         { image: "", isCorrect: true, right: "", text: "True" },
  //         { image: "", isCorrect: false, right: "", text: "False" },
  //       ],
  //       no: 1,
  //       question: '',
  //       type: '',
  //     },
  //     {
  //       title: 'Lukas thinks that bus is slower than his bike.',
  //       answers: [
  //         { image: "", isCorrect: true, right: "", text: "True" },
  //         { image: "", isCorrect: false, right: "", text: "False" },
  //       ],
  //       no: 2,
  //       question: '',
  //       type: '',
  //     },
  //     {
  //       title: 'Lukas thinks that buses are more dangerous than bikes.',
  //       answers: [
  //         { image: "", isCorrect: false, right: "", text: "True" },
  //         { image: "", isCorrect: true, right: "", text: "False" },
  //       ],
  //       no: 3,
  //       question: '',
  //       type: '',
  //     },
  //     {
  //       title: 'Dwayne thinks that Oxford is nicer and older than his city.',
  //       answers: [
  //         { image: "", isCorrect: true, right: "", text: "True" },
  //         { image: "", isCorrect: false, right: "", text: "False" },
  //       ],
  //       no: 4,
  //       question: '',
  //       type: '',
  //     },
  //     {
  //       title: `Harriet thinks that Gino's pizzas are bigger and better.`,
  //       answers: [
  //         { image: "", isCorrect: false, right: "", text: "True" },
  //         { image: "", isCorrect: true, right: "", text: "False" },
  //       ],
  //       no: 5,
  //       question: '',
  //       type: '',
  //     },
  //     {
  //       title: `Chloe thinks that Gino's is friendlier.`,
  //       answers: [
  //         { image: "", isCorrect: true, right: "", text: "True" },
  //         { image: "", isCorrect: false, right: "", text: "False" },
  //       ],
  //       no: 6,
  //       question: '',
  //       type: '',
  //     },
  //   ]
  // },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P18-E4',
    audio: 'img/FriendsPlus/Page18/E3/Audio/Friends Plus for Vietnam G6 SB Track 1.16.mp3',
    video: '',
    component: T6,
    exerciseWidth: '60%',
    inputSize: 100,
    exerciseKey: 'img/FriendsPlus/Page18/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Listen again and write <i>True</i> or <i>False</i>.', color: '#F88D3B' }],
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title:
          `<div style='margin-left:35px'><p><span style='font-weight:600'>1 </span>Emma thinks that the shopping centre is cleaner than the park.<br/>#</p> 
          <p><span style='font-weight:600'>2 </span>Lukas thinks that the bus is slower than his bike.<br/>#</p> 
          <p><span style='font-weight:600'>3 </span>Lukas thinks that buses are more dangerous than bikes.<br/>#</p> 
          <p><span style='font-weight:600'>4 </span>Dwayne thinks that Oxford is nicer and older than his city.<br/>#</p> 
          <p><span style='font-weight:600'>5 </span>Harriet thinks that Gino’s pizzas are bigger and better.<br/>#</p> 
          <p><span style='font-weight:600'>6 </span>Chloe thinks that Gino’s is friendlier.<br/>#</p></div>`,
        answer: [
          'True',
          'True',
          'False',
          'True',
          'False',
          'True'
        ],
        type: 'longAnwser'
      }]
  },
  5: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P18-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page18/E5/1.png",
    // titleQuestion: [{ num: '5', color: '#F88D3B', title: 'Think of three places that you like in your town or city and three places that you don’t like. Write sentences and say why you like or dislike them' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P18-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page18/E6/3.png",
    titleQuestion: '',
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;