import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import U1 from "../../components/ExcerciseTypes/UnderLine/U1";
import UI from "../../components/ExcerciseTypes/UseIt";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P11-E1",
    audio: "",
    video: "",
    component: UI,
    exerciseKey: "",
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page11/E1/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page11/E1/2.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P11-E2",
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: "",
    component: U1,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page11/E2/Key/1.png",
    titleQuestion: [{ num: "2", title: "Choose the correct words." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1 # /#  cousins are in Australia now.</p><p>2 Is # / # your pen under the teacher’s chair?</p><p>3 # / # present is nice.</p><p>4 # / # is Maria’s dad, Bill.</p><p>5 # / # books on my desk yours?</p>",
        answerString: [
          "That",
          "These",
          "this",
          "those",
          "This",
          "These",
          "These",
          "That",
          "Is this",
          "Are those",
        ],
        answer: ["These", "this", "This", "That", "Are those"],
      },
    ],
  },

  3: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P11-E3",
    // audio: 'img/FriendsPlus/Page6/E2/Audio/03- Starter Unit - Track 03.mp3',
    video: "Videos/Animation - Starter grammar 2 trang 11.mp4",
    exerciseKey: "img/FriendsPlus/Page11/E3/Key/answerKey.png",
    component: D1,
    fontSize: 25,
    titleQuestion: [
      {
        num: "3",
        title:
          "Look at the text and excercise 2 on page 10 again. Complete the table.",
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page11/E3/1.jpg" }],
      [
        // Column
        { url: "img/FriendsPlus/Page11/E3/2.jpg" },
        { url: "img/FriendsPlus/Page11/E3/3.jpg", input: true, answer: "ve" },
        { url: "img/FriendsPlus/Page11/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E3/5.jpg" }],
      [
        // Column

        { url: "img/FriendsPlus/Page11/E3/6.jpg" },
        {
          url: "img/FriendsPlus/Page11/E3/7.jpg",
          input: true,
          answer: "haven't",
        },
        { url: "img/FriendsPlus/Page11/E3/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E3/9.jpg" }],
      [
        // Column
        { url: "img/FriendsPlus/Page11/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page11/E3/11.jpg",
          input: true,
          answer: "hasn't",
        },
        { url: "img/FriendsPlus/Page11/E3/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E3/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E3/14.jpg" },
        { url: "img/FriendsPlus/Page11/E3/15.jpg", input: true, answer: "Has" },
        { url: "img/FriendsPlus/Page11/E3/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E3/17.jpg" }],
      [
        // Column

        { url: "img/FriendsPlus/Page11/E3/18.jpg" },
        {
          url: "img/FriendsPlus/Page11/E3/19_1.jpg",
          input: true,
          answer: "haven't",
        },
        { url: "img/FriendsPlus/Page11/E3/19_2.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E3/20.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P11-E4",
    audio:
      "img/FriendsPlus/Page11/E4/Audio/Friends Plus for Vietnam G6 SB Track 1.09.mp3",
    video: "",
    component: T6,
    inputSize: 120,
    exerciseWidth: "50%",
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page11/E4/Key/Key.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the dialogue with the correct forms of <i>have got</i>. Then listen and check.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>Ben</span> It’s Father’s Day tomorrow. <sup>1</sup> # you got</p>" +
          "<p style='margin-left:60px'> a present for Dad?</p>" +
          "<p><span style='font-weight:600'>Maria</span> Yes, I <sup>2</sup> #. I’ve got this book about</p>" +
          "<p style='margin-left:75px'> cooking.</p>" +
          "<p><span style='font-weight:600'>Ben</span> Oh, that’s nice. I <sup>3</sup> # got a present." +
          "<p><span style='font-weight:600'>Maria</span> No? That’s OK – we <sup>4</sup> # got an hour in</p>" +
          "<p style='margin-left:75px'> town. <sup>5</sup> # Dad got a good pen?</p>" +
          "<p><span style='font-weight:600'>Ben</span> Yes, he <sup>6</sup> # .</p>" +
          "<p><span style='font-weight:600'>Maria</span> OK, I <sup>7</sup> # got an idea. Look - these</p>" +
          "<p style='margin-left:75px'> mobile phone covers aren’t very expensive.</p>" +
          "<p><span style='font-weight:600'>Ben</span> Mmm. Dad hasn’t got a mobile cover." +
          "<p><span style='font-weight:600'>Maria</span> They <sup>8</sup> # got really nice ones in this</p>" +
          "<p style='margin-left:75px'>shop.</p>" +
          "<p><span style='font-weight:600'>Ben</span> Thanks, Maria. That’s a really good idea.</div>",
        answer: ["Have", "have", "haven't", "'ve", "Has", "has", "'ve", "'ve"],
        // type: 'longAnwser'
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P11-E5",
    audio: "",
    video: "",
    component: UI,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page11/E5/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page11/E5/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page11/E5/3.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page11/E5/4.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
