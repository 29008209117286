import UI from "../../components/ExcerciseTypes/UseIt";
import U1 from "../../components/ExcerciseTypes/UnderLine/U1";
import DD1 from "../../components/ExcerciseTypes/DragAndDrop/DD1";

import T1 from "../../components/ExcerciseTypes/TypeIn/T1";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P22-E1",
    audio: "",
    video: "",
    color: "#BF1737",
    component: DD1,
    sortable: true,
    exerciseKey: "img/FriendsPlus/Page22/E1/Key/1.png",
    titleImage: "/img/FriendsPlus/Page22/E1/title.jpg",
    titleQuestion: [
      {
        num: "1",
        title:
          "Check the meanings of the words in the box and match them with symbols 1–6. Read the text and check your answers.",
      },
    ],
    questionImage: [],
    questions: [
      {
        text: "river",
        correctIndex: 5,
        image: "/img/FriendsPlus/Page22/E1/1.png",
      },
      {
        text: "road",
        correctIndex: 2,
        image: "/img/FriendsPlus/Page22/E1/2.png",
      },
      {
        text: "hill",
        correctIndex: 3,
        image: "/img/FriendsPlus/Page22/E1/3.png",
      },
      {
        text: "railway",
        correctIndex: 4,
        image: "/img/FriendsPlus/Page22/E1/4.png",
      },
      {
        text: "forest",
        correctIndex: 0,
        image: "/img/FriendsPlus/Page22/E1/5.png",
      },
      {
        text: "path",
        correctIndex: 1,
        image: "/img/FriendsPlus/Page22/E1/6.png",
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P22-E2",
    audio:
      "img/FriendsPlus/Page22/E2/Audio/Friends Plus for Vietnam G6 SB Track 1.19.mp3",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page22/E2/Key/Key.png",
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page22/E2/1.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P22-E3",
    audio: "",
    video: "",
    component: U1,
    exerciseKey: "img/FriendsPlus/Page22/E3/Key/1.png",
    titleImage: "img/FriendsPlus/Page22/E3/1.jpg",
    titleImagePosition: "bottom",
    titleQuestion: [
      {
        num: "3",
        title: "Look at map A and choose the correct words.",
        color: "#DA2B37",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p>The scale is (1) # /# .There are a lot</p><p>of (2)  # /#  on this map, and there’s one</p><p>(3)  # /# . It is (4) # /# metres high. There’s</p><p>also a big (5) # / # and a (6) # / #</p><p>on the map</p>",
        answerString: [
          "1:100,000",
          "1:50,000",
          "paths",
          "railways",
          "hill",
          "forest",
          "246",
          "156",
          "town",
          "road",
          "train station",
          "city",
        ],
        answer: ["1:50,000", "paths", "hill", "156", "road", "train station"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P22-E4",
    audio: "",
    video: "",
    component: T1,
    imgSize: "60%",
    exerciseKey: "img/FriendsPlus/Page22/E4/Key/1.png",
    titleImage: "img/FriendsPlus/Page22/E4/1.jpg",
    titleQuestion: [
      {
        num: "4",
        title:
          "Look at part of the guide map of Bà Nà Hills Mountain Resort and answer the questions.",
        color: "#DA2B37",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. How many cable car stations are there in the resort? #. </p><p>2. Can you find a restaurant in the Fantasy Park? #. </p><p>3. Which cable car line doesn’t cross the Mơ River? #. </p>",
        answer: [
          "There are 4 cable car stations",
          "Yes, we can",
          "The cable car from Debay station to Morin station",
        ],
        type: "longAnwser",
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P22-E5",
    audio: "",
    video: "",
    component: UI,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page22/E5/1.jpg" },
        { url: "img/FriendsPlus/Page22/E5/2.jpg" },
        { url: "img/FriendsPlus/Page22/E5/3.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page22/E5/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page22/E5/5.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
