import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import S9 from '../../components/ExcerciseTypes/Select/Select9';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';


const json = {
  1: { // Exercise num
    
    unit : 'Option 8',
    id : 'SB6-OPE8-P117-E1',
    audio: '',
    video: '',
    component: S9,
    width: 190,
    exerciseKey: 'img/FriendsPlus/Page117/E1/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page117/E1/title.jpg',
    // titleQuestion: [{ num: '1', title: 'Match the words in the box with pictures 1–16. Then listen and check. Which sports do you like?', color: '#115D98'}],
    questions: [
      {
        no: "1",
        text: "temperature",
        image: "img/FriendsPlus/Page117/E1/01.jpg",
      },
      {
        no: "2",
        text: "rain",
        image: "img/FriendsPlus/Page117/E1/02.jpg",
      },
      {
        no: "3",
        text: "sun",
        image: "img/FriendsPlus/Page117/E1/03.jpg",
      },
      {
        no: "4",
        text: "cloud",
        image: "img/FriendsPlus/Page117/E1/04.jpg",
      },
      {
        no: "5",
        text: "wind",
        image: "img/FriendsPlus/Page117/E1/05.jpg",
      },
    ]
  },
  2: { // Exercise num
    
    unit : 'Option 8',
    id : 'SB6-OPE8-P117-E2',
    audio: 'img/FriendsPlus/Page117/E2/Audio/G6 SB Track 3.19.mp3',
    video: '',
    fontSize: 18,
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page117/E2/Key/1.png',
    titleImage: '',
    // titleQuestion: [{ num: '2', title: 'Listen to the dialogue and write the correct answers.', color: '#f67f24' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page117/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page117/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page117/E2/3.jpg', input: true, answer: 'cloudy' },
        { url: 'img/FriendsPlus/Page117/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page117/E2/5.jpg', input: true, answer: 'cloudy with some sun' },
        { url: 'img/FriendsPlus/Page117/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page117/E2/7.jpg', input: true, answer: 'windy/very windy' },
        { url: 'img/FriendsPlus/Page117/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page117/E2/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page117/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page117/E2/11.jpg', input: true, answer: '21 degrees' },
        { url: 'img/FriendsPlus/Page117/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page117/E2/13.jpg', input: true, answer: '27 degrees' },
        { url: 'img/FriendsPlus/Page117/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page117/E2/15.jpg', input: true, answer: '20 degrees' },
        { url: 'img/FriendsPlus/Page117/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page117/E2/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    
    unit : 'Option 8',
    id : 'SB6-OPE8-P117-E3',
    audio: 'img/FriendsPlus/Page117/E2/Audio/G6 SB Track 3.19.mp3',
    video: '',
    component: T6,
    inputSize: 650,
    exerciseKey: 'img/FriendsPlus/Page117/E3/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page79/E2/1.png",
    imgSize: '60%',
    // inputSize:400,
    titleQuestion: [{ num: '3', title: 'Listen again and answer the questions.', color: '#E26D28' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> What’s the weather like today? # </p>" +
          "<p><span style='font-weight:600'>2</span> What day of the week is the weather forecast for? # </p>" +
          "<p><span style='font-weight:600'>3</span> What will the weather be like near Aberdeen? # </p>" +
          "<p><span style='font-weight:600'>4</span> What month is it? # </p>" +
          "<p><span style='font-weight:600'>5</span> Why is it cooler on the south coast? #. </p>",
        answer: [
          'Sunny',
          'Saturday',
          'Rainy and not very hot',
          'August',
          "Because it's very windy",
        ],
        type: 'longAnwserInLine'
      },
    ]
  },
  4: { // Exercise num
    
    unit : 'Option 8',
    id : 'SB6-OPE8-P117-E4',
    audio: 'img/FriendsPlus/Page117/E4/Audio/G6 SB Track 3.20.mp3',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page117/E4/1.png",
    imgSize: '80%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    
    unit : 'Option 8',
    id : 'SB6-OPE8-P117-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page117/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json