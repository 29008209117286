import React from 'react'
import { Container, Row } from 'reactstrap';
import TitleQuestion from '../../TitleQuestion';
import { Player, BigPlayButton, ControlBar, ClosedCaptionButton, ReplayControl, ForwardControl, CurrentTimeDisplay } from 'video-react';
import 'video-react/dist/video-react.css';
import FooterModal from '../../FooterModal';

const VideoType = (props) => {

  if (!props) return null;
  return (
    <Container className='fluid'>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 200 }}>
        <TitleQuestion titleQuestion={props.titleQuestion} />
      </Row>
      <div class="d-flex justify-content-center">
        <div style={{ width: '50vw' }}>
          <Player>
            <BigPlayButton position="center" />
            <source
              src={props.video}
              type="video/mp4"
              default

            />

            <track
              kind="captions"
              src={props.videoSub}
              srcLang="en"
              label="English"
            />
            <ControlBar autoHide={false}>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={10} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <ClosedCaptionButton order={7} />
            </ControlBar>
          </Player>
        </div>
      </div>

      <FooterModal isDisabledRetry isDisabledSubmit audioUrl={props.audio} hideBtnFooter={true} />
    </Container>
  )
}
export default VideoType