import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MC1 from '../../components/ExcerciseTypes/MultipleChoice/MC1';

const json = {
  5: { // Exercise num
    unit : 'Progress Review 1',
    id : 'SB6-PV-P35-E5',
    audio: '',
    video: '',
    component: MC1,
    exerciseKey: 'img/FriendsPlus/Page35/E5/Key/answerKey.png',
    titleQuestion: [{ num: '5', title: 'Choose the correct words and write them on the lines.', color: '#3B3074' }],
    titleImage: 'img/FriendsPlus/Page35/E5/titleImage.jpg',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "to" },
          { image: "", isCorrect: true, right: "", text: "than" },
          { image: "", isCorrect: false, right: "", text: "of" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "better" },
          { image: "", isCorrect: false, right: "", text: "well" },
          { image: "", isCorrect: false, right: "", text: "like" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "not friendly" },
          { image: "", isCorrect: true, right: "", text: "friendlier" },
          { image: "", isCorrect: false, right: "", text: "unfriendly" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "many" },
          { image: "", isCorrect: false, right: "", text: "a lot" },
          { image: "", isCorrect: true, right: "", text: "more" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "bigger" },
          { image: "", isCorrect: false, right: "", text: "big" },
          { image: "", isCorrect: false, right: "", text: "more big" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "cleaning" },
          { image: "", isCorrect: false, right: "", text: "more clean" },
          { image: "", isCorrect: true, right: "", text: "cleaner" },
        ],
        no: 6,
        question: '',
        type: '',
      },

    ],
  },
  7: { // Exercise num
    unit : 'Progress Review 1',
    id : 'SB6-PV-P35-E7',
    audio: '',
    video: '',
    component: T1,
    // titleImage: "img/FriendsPlus/Page32/E3/1.png",
    exerciseKey: 'img/FriendsPlus/Page35/E7/Key/1.png',
    titleQuestion: [{ color: '#3B3074', num: '7', title: 'Order the words to make sentences.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p><span style='font-weight:600'>1</span> is / Norwich / interesting / city / a / very #. </p>" +
          "<p><span style='font-weight:600'>2</span> east / of / it’s / the / England / in #. </p>" +
          "<p><span style='font-weight:600'>3</span> population / got / of / a / 200,000 / it’s / about #. </p>" +
          "<p><span style='font-weight:600'>4</span> favourite / castle / place / my / the / is #. </p>" +
          "<p><span style='font-weight:600'>5</span> fantastic / are / there / shops / some  #.</p>" +
          "<p><span style='font-weight:600'>6</span> market / good / a / too / there’s #. </p>" +
          "<p><span style='font-weight:600'>7</span> only / it’s / thirty-five / from / kilometres / sea / the #. </p>" +
          "<p><span style='font-weight:600'>8</span> exciting / it’s / place / an  #. </p></div>",


        answer: [
          "Norwich is a very interesting city",
          "It's in the east of England",
          "It's got a population of about 200,000",
          "My favourite place is the castle",
          'There are some fantastic shops',
          "There's a good market too",
          "It's only thirty-five kilometres from the sea",
          "It's an exciting place",
        ],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit : 'Progress Review 1',
    id : 'SB6-PV-P35-E6',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page35/E6/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ color: '#4F3A96', num: '6', title: 'Put the dialogue in the correct order. Number the sentences.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E6/3.jpg', input: true, answer: '5' },
        { url: 'img/FriendsPlus/Page35/E6/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/6.jpg' },
        { url: 'img/FriendsPlus/Page35/E6/7.jpg', input: true, answer: '4' },
        { url: 'img/FriendsPlus/Page35/E6/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/10.jpg' },
        { url: 'img/FriendsPlus/Page35/E6/11.jpg', input: true, answer: '1' },
        { url: 'img/FriendsPlus/Page35/E6/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/14.jpg' },
        { url: 'img/FriendsPlus/Page35/E6/15.jpg', input: true, answer: '6' },
        { url: 'img/FriendsPlus/Page35/E6/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/18.jpg' },
        { url: 'img/FriendsPlus/Page35/E6/19.jpg', input: true, answer: '3' },
        { url: 'img/FriendsPlus/Page35/E6/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/22.jpg' },
        { url: 'img/FriendsPlus/Page35/E6/23.jpg', input: true, answer: '2' },
        { url: 'img/FriendsPlus/Page35/E6/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page35/E6/24.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}
export default json