import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {
  // 1: { // Exercise num
  //   audio: '',
  //   video: '',
  //   component: T6,
  //   titleImage: "img/FriendsPlus/Page40/E1/1.png",
  //   titleQuestion: [{ color: '#C02A32', num: '1', title: 'Check the meanings of the words in blue in sentences 1–3. Then read the text. Which sentences does the writer agree with?' }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title:
  //         "<p><span style='font-weight:600'>1</span> It’s important to <span style='color:#0099D4'>protect</span> all animals because they are all interesting. #</p>" +
  //         "<p><span style='font-weight:600'>2</span> It’s best to protect the beautiful <span style='color:#0099D4'>species</span>, not the ugly ones. #</p>" +
  //         "<p><span style='font-weight:600'>3</span> A lot of species <span style='color:#0099D4'>become extinct</span>every year. Animals in</p>"+
  //         "<p><span style='color:#0099D4'>danger</span> need our <span style='color:#0099D4'>help</span> #</p>",
  //       answer: [],
  //       // type: 'longAnwser'
  //     },
  //   ]
  // },
  1: {
    unit: 'Unit 3',
    id: 'SB6-U3-P40-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 1,
    exerciseKey: 'img/FriendsPlus/Page40/E1/Key/1.png',
    // exerciseKey: 'img/FriendsPlus/Page29/E1/Key/answerKey.png',
    // titleQuestion: [{ color: '#14BCD4', num: '1', title: "Choose the correct answers in rules 1–4." }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page40/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/7.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page40/E1/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/11.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page40/E1/13.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P40-E2',
    audio: 'img/FriendsPlus/Page40/E2/Audio/G6 SB Track 1.33.mp3',
    video: '',
    component: T6,
    titleImage: '',
    inputSize: 100,
    exerciseKey: 'img/FriendsPlus/Page40/E2/Key/1.png',
    titleQuestion: [{ color: '#C02A32', num: '2', title: 'Read and listen to the text and write <i>True</i> or <i>False</i>.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:30px'><p><span style='font-weight:600'>1</span> The blobfish is quite rare. #  </p>" +
          "<p><span style='font-weight:600'>2</span> The blobfish sometimes lives in rivers. # </p>" +
          "<p><span style='font-weight:600'>3</span> It’s good that there is information about the blobfish on magazines and the Internet. # </p>" +
          "<p><span style='font-weight:600'>4</span> There’s a lot of information about pandas, but not whales. # </p>",
        answer: [
          "True",
          "False",
          'True',
          'False'
        ],
        // type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P40-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page40/E3/title.jpg",
    imgSize: '100%',
    // titleQuestion: [{ num: '3', title: 'Read the study strategy. Then write five sentences with your ideas. Use the adjectives and the topics in the boxes. Compare with a partner.' }],
    questionImage: [],
    questions: [
      {
        // title:
        //   "<p>#</p>" +
        //   "<p>#</p>" +
        //   "<p>#</p>" +
        //   "<p>#</p>" +
        //   "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P40-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page40/E4/1.png",
    // titleQuestion: [{ num: '3', title: 'Read the study strategy. Then write five sentences with your ideas. Use the adjectives and the topics in the boxes. Compare with a partner.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json