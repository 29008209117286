import WordsBox from "../../components/ExcerciseTypes/Design/WordBox";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "SB6-U3-P47-E1",
    audio: "",
    video: "",
    component: T2,
    imgSize: "70%",
    titleImage: "img/FriendsPlus/Page47/E1/title.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "SB6-U3-P47-E2",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page47/E2/title.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "SB6-U3-P47-E3",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page47/E3/title.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 3",
    id: "SB6-U3-P47-E44",
    audio: "",
    video: "",
    component: WordsBox,
    fontSize: 30,
    paddingTop: 8,
    paddingLeft: 7,
    exerciseKey: "img/FriendsPlus/Page47/E4/Key/answerKey.png",
    titleImage: "",
    // titleQuestion: [{ color: 'black', num: '4', title: 'Read the clues and complete the crossword.' }],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/0.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/2.jpg" },
        { url: "img/FriendsPlus/Page47/E4/3.jpg", input: true, answer: "c" },
        { url: "img/FriendsPlus/Page47/E4/4.jpg", input: true, answer: "l" },
        { url: "img/FriendsPlus/Page47/E4/5.jpg", input: true, answer: "i" },
        { url: "img/FriendsPlus/Page47/E4/6.jpg", input: true, answer: "m" },
        { url: "img/FriendsPlus/Page47/E4/7.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page47/E4/8.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/9.jpg" },
        { url: "img/FriendsPlus/Page47/E4/10.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page47/E4/11.jpg" },
        { url: "img/FriendsPlus/Page47/E4/12.jpg" },
        { url: "img/FriendsPlus/Page47/E4/13.jpg", input: true, answer: "j" },
        { url: "img/FriendsPlus/Page47/E4/14.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/15.jpg" },
        { url: "img/FriendsPlus/Page47/E4/16.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page47/E4/17.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page47/E4/18.jpg", input: true, answer: "n" },
        { url: "img/FriendsPlus/Page47/E4/19.jpg" },
        { url: "img/FriendsPlus/Page47/E4/20.jpg", input: true, answer: "h" },
        { url: "img/FriendsPlus/Page47/E4/21.jpg" },
        { url: "img/FriendsPlus/Page47/E4/22.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page47/E4/23.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/24.jpg" },
        { url: "img/FriendsPlus/Page47/E4/25.jpg", input: true, answer: "t" },
        { url: "img/FriendsPlus/Page47/E4/26.jpg" },
        { url: "img/FriendsPlus/Page47/E4/27.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page47/E4/28.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page47/E4/29.jpg", input: true, answer: "t" },
        { url: "img/FriendsPlus/Page47/E4/30.jpg" },
        { url: "img/FriendsPlus/Page47/E4/31.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page47/E4/32.jpg", input: true, answer: "w" },
        { url: "img/FriendsPlus/Page47/E4/33.jpg", input: true, answer: "i" },
        { url: "img/FriendsPlus/Page47/E4/34.jpg", input: true, answer: "m" },
        { url: "img/FriendsPlus/Page47/E4/35.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/36.jpg" },
        { url: "img/FriendsPlus/Page47/E4/37.jpg", input: true, answer: "h" },
        { url: "img/FriendsPlus/Page47/E4/38.jpg" },
        { url: "img/FriendsPlus/Page47/E4/39.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page47/E4/40.jpg" },
        { url: "img/FriendsPlus/Page47/E4/41.jpg", input: true, answer: "p" },
        { url: "img/FriendsPlus/Page47/E4/42.jpg" },
        { url: "img/FriendsPlus/Page47/E4/43.jpg", input: true, answer: "p" },
        { url: "img/FriendsPlus/Page47/E4/44.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/45.jpg" },
        { url: "img/FriendsPlus/Page47/E4/46.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page47/E4/47.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page47/E4/48.jpg", input: true, answer: "r" },
        { url: "img/FriendsPlus/Page47/E4/49.jpg", input: true, answer: "v" },
        { url: "img/FriendsPlus/Page47/E4/50.jpg", input: true, answer: "i" },
        { url: "img/FriendsPlus/Page47/E4/51.jpg", input: true, answer: "v" },
        { url: "img/FriendsPlus/Page47/E4/52.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page47/E4/53.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/54.jpg" },
        { url: "img/FriendsPlus/Page47/E4/55.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page47/E4/56.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/57.jpg" },
        { url: "img/FriendsPlus/Page47/E4/58.jpg", input: true, answer: "k" },
        { url: "img/FriendsPlus/Page47/E4/59.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page47/E4/60.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 3",
    id: "SB6-U3-P47-E5",
    audio: "",
    video: "",
    component: T6,
    inputSize: 650,
    titleImage: "img/FriendsPlus/Page47/E5/1.png",
    exerciseKey: "img/FriendsPlus/Page47/E5/Key/Key.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> #.</p>" +
          "<p>&ensp;&#160;#.</p>" +
          "<p>&ensp;&#160;#.</p>" +
          "<p>&ensp;&#160;#.</p>" +
          "<p><span style='font-weight:600'>2</span> #.</p>" +
          "<p>&ensp;&#160;#.</p>" +
          "<p>&ensp;&#160;#.</p>" +
          "<p>&ensp;&#160;#.</p>" +
          "<p><span style='font-weight:600'>3</span> #.</p>" +
          "<p>&ensp;&#160;#.</p>" +
          "<p>&ensp;&#160;#.</p>" +
          "<p>&ensp;&#160;#.</p>",
        answer: [
          "I've got eight legs",
          "I'm quite big",
          "I can swim",
          "Octopus",
          "I'm a large bird from Africa",
          "I can run fast",
          "I can't fly",
          "Ostrich",
          "I'm a very dangerous animal",
          "I've got a very big mouth",
          "I'm long and green",
          "Crocodile",
        ],
        type: "longAnwser",
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit 3",
    id: "SB6-U3-P47-E6",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page47/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
