import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
// import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P66-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page66/E1/Key/1.png',
    titleImage: '',
    // titleQuestion: [{ color: '#4F3A96', num: '1', title: 'Complete the words in the text.' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/1.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/3.jpg', input: true, answer: 'active' },
        { url: 'img/FriendsPlus/Page66/E1/4.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/5.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/7.jpg', input: true, answer: 'hungry' },
        { url: 'img/FriendsPlus/Page66/E1/8.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/9.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/11.jpg', input: true, answer: 'healthy' },
        { url: 'img/FriendsPlus/Page66/E1/12.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/13.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/15.jpg', input: true, answer: 'fit' },
        { url: 'img/FriendsPlus/Page66/E1/16.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/17.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/19.jpg', input: true, answer: 'tired' },
        { url: 'img/FriendsPlus/Page66/E1/20.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page66/E1/21.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: {
    unit: 'Unit 5',
    id: 'SB6-U5-P66-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page66/E3/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 6,
    titleQuestion: [{ num: '3', title: "Work in pairs. Look at the <i>Health quiz</i> and choose the best answer a, b or c.", color: '#D97C40' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page66/E3/02.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/03.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/04.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page66/E3/05.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/06.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page66/E3/08.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/09.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/10.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page66/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page66/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page66/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/17.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page66/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/20.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page66/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/22.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page66/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/24.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page66/E3/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/26.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/27.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page66/E3/28.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/29.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page66/E3/30.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/31.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page66/E3/32.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/33.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/34.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/35.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page66/E3/36.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/37.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/38.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page66/E3/39.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/40.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/41.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page66/E3/42.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/43.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page66/E3/44.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/45.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/46.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page66/E3/47.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/48.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/49.jpg' },
        { url: 'img/FriendsPlus/Page66/E3/50.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page66/E3/51.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page66/E3/52.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P66-E2',
    audio: 'img/FriendsPlus/Page66/E2/Audio/G6 SB Track 2.06.wav',
    video: '',
    component: T6,
    titleImage: "img/FriendsPlus/Page66/E2/title.jpg",
    exerciseKey: 'img/FriendsPlus/Page66/E2/Key/answerKey.png',
    imgSize: '100%',
    // titleQuestion: [{ color: '#E26D28', track: '2.06', num: '2', title: "What are the opposites of the adjectives in sentences 1–4 in exercise 1? Use the words in the box. Listen and check." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:600;font-family:OneStrokeScriptStd-Bold'>ill – well</p>" +
          "<p><span style='font-weight:600'>1.</span> active - #</p>" +
          "<p><span style='font-weight:600'>2.</span> hungry - #</p>" +
          "<p><span style='font-weight:600'>3.</span> healthy - #</p>" +
          "<p><span style='font-weight:600'>4.</span> fit - #</p>",

        answer: [
          "lazy",
          "full",
          "unhealthy",
          "unfit",
        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P66-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page66/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P66-E5',
    audio: 'img/FriendsPlus/Page66/E5/Audio/G6 SB Track 2.07.mp3',
    video: '',
    component: T6,
    titleImage: "img/FriendsPlus/Page66/E5/title.jpg",
    exerciseKey: 'img/FriendsPlus/Page66/E5/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page59/E5/1.png",
    imgSize: '100%',
    inputSize: 80,
    textCenter: true,
    stylesTextInput: {
      textTransform: 'lowercase',
    },
    // titleQuestion: [{ color: '#E26D28', num: '5', title: "Listen to three interviews. Which questions a–h in exercise 4 does each person answer?" }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> Charlie - <span style='font-family:OneStrokeScriptStd-Bold;color:gray'>a</span># # #</p>" +
          "<p><span style='font-weight:600'>2.</span> Conor - #</p>" +
          "<p><span style='font-weight:600'>3.</span> Fran - # # #</p>",

        answer: [
          "b/h/c",
          "b/h/c",
          "b/h/c",
          "b",
          "f/g/d",
          "f/g/d",
          "f/g/d",
        ],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P66-E6',
    audio: 'img/FriendsPlus/Page66/E5/Audio/G6 SB Track 2.07.mp3',
    video: '',
    component: T1,
    // titleImage: "img/FriendsPlus/Page59/E5/1.png",
    imgSize: '70%',
    exerciseKey: 'img/FriendsPlus/Page66/E6/Key/answerKey.png',
    titleQuestion: [{ color: '#E26D28', track: '2.07', num: '6', title: "Listen again and answer the questions." }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1.</span> How many hours does Charlie normally sleep? #.</p>" +
          "<p><span style='font-weight:600'>2.</span> Is Charlie a couch potato? Why / Why not? #.</p>" +
          "<p><span style='font-weight:600'>3.</span> Why is Conor really tired? #.</p>" +
          "<p><span style='font-weight:600'>4.</span> What sports does Fran do? #.</p>" +
          "<p><span style='font-weight:600'>5.</span> What healthy and unhealthy food does she eat? #.</p></div>",
        answer: [
          "Eight or nine hours",
          "No, he isn't. He likes going out and doing sport",
          "He's studying for exams",
          "She runs and swims",
          "Healthy foods: pasta, chicken; Unhealthy foods: desserts and chocolate",
        ],
        type: 'longAnwser'
      },
    ]
  },
  7: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P66-E7',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page66/E7/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json