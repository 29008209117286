import T3 from '../../components/ExcerciseTypes/TypeIn/T3';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';




const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P32-E1',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T3,
    titleImage: 'img/FriendsPlus/Page32/E1/1.jpg',
    exerciseKey: 'img/FriendsPlus/Page32/E1/Key/answerKey.png',
    titleQuestion: [{ color: '#C02A32', num: '1', title: 'Check the meanings of the words in the box. Read the text and match the words to numbers 1–7 on the map.' }],
    questionImage: [],
    questions: [
      {
        title:
          '<p>1. # </p>' +
          '<p>2. # </p>' +
          '<p>3. # </p>' +
          '<p>4. # </p>' +
          '<p>5. # </p>' +
          '<p>6. # </p>' +
          '<p>7. # </p>',
        answer: [
          "Prime Meridian",
          "Greenwich Mean Time",
          'north',
          "west",
          'east',
          'south',
          'longitude',
        ],
        // type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P32-E2',
    audio: 'img/FriendsPlus/Page32/E2/Audio/G6 SB Track 1.26.mp3',
    video: '',
    component: T6,
    titleImage: '',
    stylesTextInput: {
      textTransform: 'uppercase',
    },
    exerciseKey: 'img/FriendsPlus/Page32/E2/Key/Key.png',
    titleQuestion: [{ color: '#E26D28', num: '2', title: ' Read and listen to the text and write <i>True</i> or <i>False</i>. Correct the false sentences.' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          '<p>1 Lines of longitude go from east to west. #  </p>' +
          '<p>2 The Prime Meridian is the name of a time zone. # </p>' +
          '<p>3 Greenwich is a part of London. # </p>' +
          '<p>4 The time in places west of the Prime Meridian is ahead of GMT. # </p>',

        answer: [
          "False",
          "False",
          "True",
          'False',
        ],
        // type: 'longAnwser'

      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P32-E3',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page32/E3/Key/1.png',
    titleImage: "img/FriendsPlus/Page32/E3/1.png",
    titleQuestion: [{ color: '#C02A32', num: '3', title: 'Look at the clocks and answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> In which countries are cities 1–6?  #. </p>" +
          "<p><span style='font-weight:600'>2</span> Is New York ahead of or behind GMT?  #. </p>" +
          "<p><span style='font-weight:600'>3</span> How many hours ahead of GMT is Hà Nội?  #. </p>" +
          "<p><span style='font-weight:600'>4</span> How many hours behind GMT is Brasilia?  #. </p>" +
          "<p><span style='font-weight:600'>5</span> If it’s 12 p.m. in London, what time is it in Brisbane?  #. </p>" +
          "<p><span style='font-weight:600'>6</span> You are in New York at 9 p.m. Is it a good idea to call your friend in London? Why / Why not? #. </p>",
        answer: [
          'England, the USA, Spain, Brazil, Australia, Việt Nam',
          "It's behind",
          "It's seven hours ahead",
          "It's three hours behind",
          "It's 10 p.m",
          "No, because it's 2 a.m"
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P32-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page32/E4/1.png",
    // titleQuestion: [{ num: '1', title: 'Check the meanings of the words in the box. Then describe the photo using the words.', color: '#009241' }],
    questionImage: [],
    questions: [
      {
        title:
          "",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}


export default json;