import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: {
    unit : 'Unit 7',
    id : 'SB6-U7-P88-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page88/E1/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 1,
    // titleQuestion: [{ num: '1', title: `Listen again and look at sentences 1–4 in the text. Choose the correct answers for Alana and then for Tom.`, color: '#D97C40' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page88/E1/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page88/E1/05.jpg' },
        { url: 'img/FriendsPlus/Page88/E1/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page88/E1/07.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page88/E1/08.jpg' },
        { url: 'img/FriendsPlus/Page88/E1/09.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page88/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page88/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page88/E1/12.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page88/E1/13.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit : 'Unit 7',
    id : 'SB6-U7-P88-E2',
    audio: 'img/FriendsPlus/Page88/E2/Audio/G6 SB Track 2.26.wav',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page88/E2/Key/answerKey.png',
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page80/E1/1.png",
    imgSize: '60%',
    inputSize: 100,
    // textCenter: true,
    titleQuestion: [{ num: '2', title: 'Read and listen to the text and write True or False.', color: '#C63D45' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> Munish didn’t take photos when Suman was sad # </p>" +
          "<p><span style='font-weight:600'>2</span>  Munish took a photo of his daughter at a different time every day. # </p>" +
          "<p><span style='font-weight:600'>3</span> Suman is quite different in the photos from one day to the next. # </p>" +
          "<p><span style='font-weight:600'>4</span> Suman was an adult when she received the present. # </p>",


        answer: [
          'False',
          'True',
          'False',
          'True',
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit : 'Unit 7',
    id : 'SB6-U7-P88-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page88/E3/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit : 'Unit 7',
    id : 'SB6-U7-P88-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page88/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json