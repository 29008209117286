import React from 'react'
import ReactHtmlParser from 'react-html-parser';

const TitleQuestion = ({ titleQuestion }) => {
  const renderStar = React.useCallback(() => {
    const star = titleQuestion[0].star;
    return Array(star).fill('').map((item, index) => <i key={index} class="far fa-star" style={{ color: 'white', padding: 5, fontSize: 20 }} />)
  }, [titleQuestion])
  //
  const renderTrack = React.useCallback(() => {
    const track = titleQuestion[0].track;
    if (!track) return null;
    return (
      <div style={{ position: 'relative', textAlign: 'center', color: '#241B1A', bottom: '3px' }}>
        <img src='img/track_icon.png' alt='...' style={{ width: '70px' }} />
        <span style={{ fontSize: 16, position: 'absolute', right: '10px', top: '10px' }}>{track}</span>
      </div>

    )
  }, [titleQuestion])
  //
  if (!titleQuestion) return null;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
      <span
        style={{
          fontSize: 35,
          marginBottom: 10,
          fontWeight: '600',
          color: titleQuestion[0].color ?? '#33B1D6',
        }}>
        {titleQuestion[0].num}
      </span>

      <div style={{
        fontSize: 25,
        minWidth: 200,
        marginLeft: 10,
        marginTop: 8,
        display: 'flex',
        color: '#302E2F',
        fontWeight: '600',
        flexDirection: 'row',
        // fontFamily: 'Rubik-Medium',
      }}>
        {titleQuestion?.[0].star && (
          <div style={{
            height: 30,
            width: 110,
            marginTop: 4,
            alignItems: 'center',
            justifyContent: 'start',
            borderRadius: 10, display: 'flex',
            backgroundColor: titleQuestion[0].color ?? '#33B1D6',
          }}>
            {renderStar()}
          </div>
        )}
        {renderTrack()}
        <div className='ml-2'>
          {/* {titleQuestion[0].title} */}
          {ReactHtmlParser(titleQuestion[0].title)}
        </div>
      </div>
    </div>
  )
}

export default TitleQuestion;