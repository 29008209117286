import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {

  5: { // Exercise num
    unit : 'Unit 2',
    id : 'SB6-U2-P25-E1',
    // audio: 'img/FriendsPlus/Page25/E5/Audio/G6 SB Track 1.20.mp3',
    video: '',
    component: D1,
    fontSize: 23,
    height: 40,
    exerciseKey: 'img/FriendsPlus/Page25/E5/Key/1.png',
    // titleQuestion: [{ num: '1', title: 'Complete the table with the verbs in blue in the Days and Nights Quiz. Then listen and check.', color: '#005293' }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page25/E5/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/3.jpg', input: true, answer: 'normally' },
        { url: 'img/FriendsPlus/Page25/E5/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E5/5.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/6.jpg', input: true, answer: 'sometimes' },
        { url: 'img/FriendsPlus/Page25/E5/7.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/8.jpg', input: true, answer: 'often' },
        { url: 'img/FriendsPlus/Page25/E5/9.jpg' },
        { url: 'img/FriendsPlus/Page25/E5/10.jpg', input: true, answer: 'always' },
        { url: 'img/FriendsPlus/Page25/E5/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E5/12.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  '5-1': { // Exercise num
    unit : 'Unit 2',
    id : 'SB6-U2-P25-E5.1',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: DesignUnderLine,
    // titleImage: 'img/FriendsPlus/Page25/E5.1/04.jpg',
    exerciseKey: 'img/FriendsPlus/Page25/E5.1/Key/1.png',
    totalInput: 2,
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page25/E5.1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E5.1/2.jpg' },
        { url: 'img/FriendsPlus/Page25/E5.1/3.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page25/E5.1/4.jpg' },
        { url: 'img/FriendsPlus/Page25/E5.1/5.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page25/E5.1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E5.1/7.jpg' },
        { url: 'img/FriendsPlus/Page25/E5.1/8.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page25/E5.1/9.jpg' },
        { url: 'img/FriendsPlus/Page25/E5.1/10.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page25/E5.1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page25/E5.1/12.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  6: { // Exercise num
    unit : 'Unit 2',
    id : 'SB6-U2-P25-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page25/E6/1.jpg",
    titleQuestion: '',
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;