import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P30-E1',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page30/E1/1.png",
    // titleQuestion: [{ num: '2', title: 'Choose the correct verbs.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P30-E2',
    audio: 'img/FriendsPlus/Page30/E2/Audio/G6 SB Track 1.25.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P30E2_3.mp4',
    videoSub: 'Videos/P30E2_3.vtt',
    component: D1,
    // fontSize: 18,
    exerciseKey: 'img/FriendsPlus/Page30/E2/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page30/E2/title.png',
    titleQuestion: [{ color: '#BF215C', num: '2', title: 'Watch or listen and complete the dialogue. What do Dasky and Gareth decide to do?' }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/3.jpg', input: true, answer: "cooking class" },
        { url: 'img/FriendsPlus/Page30/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page30/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/6.jpg', input: true, answer: "concert" },
        { url: 'img/FriendsPlus/Page30/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page30/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page30/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/10.jpg', input: true, answer: "disco" },
        { url: 'img/FriendsPlus/Page30/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page30/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/13.jpg', input: true, answer: "dance competition" },
        { url: 'img/FriendsPlus/Page30/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page30/E2/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page30/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/17.jpg', input: true, answer: "disco" },
        { url: 'img/FriendsPlus/Page30/E2/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page30/E2/19.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P30-E3',
    audio: 'img/FriendsPlus/Page30/E2/Audio/G6 SB Track 1.25.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P30E2_3.mp4',
    videoSub: 'Videos/P30E2_3.vtt',
    component: T2,
    // titleImage: "img/FriendsPlus/Page30/E1/1.png",
    titleQuestion: [{ num: '3', title: 'Watch or listen again. Then practise the dialogue.', color: '#BE215B' }],
    questionImage: [],
    questions: [
      {
        title: '',

        answer: [],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P30-E4',
    audio: '',
    video: '',
    component: T1,
    titleImage: "img/FriendsPlus/Page30/E4/1.png",
    imgSize: '50%',
    titleQuestion: [{ color: '#BF215C', num: '4', title: 'Study the key phrases.Then complete the mini-dialogue. Use some of the ideas from the Family Fun Day programme in exercise 1.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>A</span> It’s Family Fun Day at the school on Saturday.</p>" +
          "<p>What do you # ?</p>" +
          "<p><span style='font-weight:600'>B</span> I like the # of the # .</p>" +
          "<p><span style='font-weight:600'>A</span> Right, and what about the # ?</p>" +
          "<p><span style='font-weight:600'>B</span> OK. Let’s go to the # and the #</p>",
        answer: [],
        type: ''
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P30-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page30/E5/1.png",
    // titleQuestion: [{ num: '2', title: 'Choose the correct verbs.' }],
    questionImage: [],
    questions: [
      {
        title:
          "",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json;
